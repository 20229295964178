import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { setReloadTrue } from "../../redux/actions/index"
import { setGlobalLoading } from "../../redux/actions"
import PropTypes from "prop-types"
import { Modal, ModalHeader } from "reactstrap"
import { Button, Form, FormGroup, Label, Input } from "reactstrap"
import ButtonIcon from "../common/ButtonIcon"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { toast } from "react-toastify"
import { POST_ACTIVITY } from "../../helpers/axios"
import { useHistory } from "react-router-dom"

const AddLink = ({
  setReloadTrue,
  isOpen,
  handleCloseModal,
  refetch,
  user,
  serviceId,
  deviceId,
  setGlobalLoading,
  reload = false,
}) => {
  const [form, setForm] = useState({
    title: "",
    link_address: "",
  })
  const history = useHistory()

  useEffect(() => {
    return () => setGlobalLoading("addLink", false)
  }, [setGlobalLoading])

  const handleSaveMsg = () => {
    if (serviceId) {
      const activityModel = {
        ticket_id: serviceId,
        device_id: null,
        note: {
          description: "",
          attachment: null,
          link: form.link_address
            ? [
                {
                  title: form.title,
                  link_address: form.link_address,
                },
              ]
            : null,
        },
        link: null,
        attachment: null,
        tmbPerson: user.tmbPerson
          ? user.tmbPerson.replace("/api/tmb_people/", "")
          : null,
        clientPerson: user.clientPerson
          ? user.clientPerson.replace("/api/client_people/", "")
          : null,
      }
      setGlobalLoading("addLink", true)
      POST_ACTIVITY(activityModel)
        .then(() => {
          if (refetch) refetch()
          setForm({ title: "", link_address: "" })
          handleCloseModal()
          toast.success("Dodano link")
        })
        .catch((err) => toast.error("Wystąpił błąd"))
        .finally(() => setGlobalLoading("addLink", false))
    }
  }

  const handleSaveLink = () => {
    if (form.link_address) {
      const activityModel = {
        ticket_id: serviceId ? serviceId : null,
        device_id: deviceId ? deviceId.replace("/api/devices/", "") : null,
        note: null,
        link: {
          title: form.title,
          link_address: form.link_address,
        },
        attachment: null,
        tmbPerson: user.tmbPerson
          ? user.tmbPerson.replace("/api/tmb_people/", "")
          : null,
        clientPerson: user.clientPerson
          ? user.clientPerson.replace("/api/client_people/", "")
          : null,
      }
      setGlobalLoading("addLink", true)
      POST_ACTIVITY(activityModel)
        .then(() => {
          handleSaveMsg()
          if (deviceId) {
            if (refetch) refetch()
            if (reload) history.go(0)
            handleCloseModal()
            setReloadTrue()
          }
        })
        .catch((err) => toast.error("Wystąpił błąd"))
        .finally(() => setGlobalLoading("addLink", false))
    } else toast.error("Pole Link nie może być puste.")
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        modalClassName="modal-fixed-right modal-theme"
        className="modal-dialog-vertical"
        contentClassName="min-vh-100 w-500 border-0"
        toggle={handleCloseModal}
      >
        <div className="position-absolute t-0 r-0  z-index-1">
          <Button
            size="sm"
            className="close close-circle d-flex flex-center transition-base mt-3 mr-3"
            onClick={() => handleCloseModal()}
          >
            <FontAwesomeIcon
              icon="times"
              transform="shrink-6 right-0.3 down-0.3"
            />
          </Button>
        </div>
        <ModalHeader tag="div" className="modal-header-settings">
          <div className="py-1 flex-grow-1">
            <h5>Dodaj link</h5>
          </div>
        </ModalHeader>
        <Form className="p-4 overflow-auto">
          <FormGroup>
            <Label for="exampleName">Tytuł</Label>
            <Input
              type="text"
              name="title"
              id="title"
              value={form.title}
              onChange={(e) => setForm({ ...form, title: e.target.value })}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleName">Link</Label>
            <Input
              type="text"
              name="link"
              id="link"
              value={form.link_address}
              onChange={(e) =>
                setForm({ ...form, link_address: e.target.value })
              }
            />
          </FormGroup>
          <ButtonIcon
            icon="link"
            type="button"
            size="sm"
            color="secondary"
            outline
            onClick={() => handleSaveLink()}
          >
            Dodaj
          </ButtonIcon>
        </Form>
      </Modal>
    </>
  )
}

AddLink.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setReloadTrue: () => dispatch(setReloadTrue()),
    setGlobalLoading: (component, value) =>
      dispatch(setGlobalLoading({ [component]: value })),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddLink)

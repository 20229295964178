import React from "react"
import { Label as ReactstrapLabel } from "reactstrap"

const Label = ({ children, for: forProp, ...rest }) => {
  return (
    <ReactstrapLabel
      for={forProp}
      style={{ position: "static", margin: 0 }}
      {...rest}
    >
      {children}
    </ReactstrapLabel>
  )
}

export default Label

import React, { useEffect, useState } from "react"
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"
import ProductSelectorValidatorSettlement from "../common/ProductSelectorValidatorSettlement"
import { GET, POST } from "helpers/axios"
import TrashButton from "components/common/TrashButton"
import { formatCurrency } from "helpers/utils"
import { toast } from "react-toastify"
import { StyledGreenLink } from "../Links/StyledLinks"
import { StyledAlertInfo } from "../agreement/StyledAlertInfo"

const VisitWrapper = styled.div`
  margin-bottom: 15px;
`

const VisitHeading = styled.h5`
  display: flex;
  gap: 5px;
`

const OfferProductRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`

const ModelCheckboxRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const AddSettlement = ({ isOpen, handleCloseModal, deviceId, serviceId }) => {
  const [productTypes, setProductTypes] = useState([])
  const [familyTypes, setFamilyTypes] = useState([])
  const [manufacturers, setManufacturers] = useState([])
  const [serviceVisits, setServiceVisits] = useState([])
  const [settlementType, setSettlementType] = useState("fv") // fv || rw
  const [isAgreementLoading, setIsAgreementLoading] = useState(false)
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [description, setDescription] = useState("")
  const [activeAgreementType, setActiveAgreementType] = useState(null)
  const [activeAgreement, setActiveAgreement] = useState(null)
  useEffect(() => {
    if (!deviceId) return

    setIsAgreementLoading(true)
    GET(`devices/${deviceId}/active-agreement`)
      .then(({ data }) => {
        setActiveAgreementType(data?.type)
        setActiveAgreement(data)
        setSettlementType(!data || data?.type == 3 ? "fv" : "rw")
      })
      .catch((err) => {
        toast.error("Nie udało się pobrać danych umowy")
      })
      .finally(() => setIsAgreementLoading(false))
  }, [deviceId])

  useEffect(() => {
    GET(`product_types`).then(({ data }) => {
      const filteredData = data["hydra:member"].filter((item) => item.name)

      setProductTypes(filteredData)
    })
  }, [])

  useEffect(() => {
    GET(`family_types`).then(({ data }) => {
      const filteredData = data["hydra:member"].filter((item) => item.name)

      setFamilyTypes(filteredData)
    })
  }, [])

  useEffect(() => {
    GET(`manufacturers`).then(({ data }) => {
      const filteredData = data["hydra:member"].filter((item) => item.name)

      setManufacturers(filteredData)
    })
  }, [])

  useEffect(() => {
    GET(
      `service_visits?ticket=${serviceId}&status[]=client_accept&status[]=client_rejection`
    ).then(({ data }) => {
      setServiceVisits(data["hydra:member"])
    })
  }, [])

  const [filters, setFilters] = useState({
    manufacturer: "",
    productType: "",
    familyTypes: "",
    search: "",
  })

  const [selected, setSelected] = useState([])
  const [selectedVisitId, setSelectedVisitId] = useState("")
  const [selectedVisits, setSelectedVisits] = useState([])
  const [additionalActivities, setAdditionalActivities] = useState([])
  const [totalPrice, setTotalPrice] = useState(0)

  useEffect(() => {
    const allProducts = [
      ...selectedVisits.map((visit) => visit.activities).flat(),
      ...additionalActivities,
    ]

    const newTotalPrice = allProducts.reduce((acc, curr) => {
      return curr.checked ? acc + curr.price * curr.quantity : acc
    }, 0)

    setTotalPrice(newTotalPrice)
  }, [selectedVisits, additionalActivities])

  const handleLoadVisit = (visitId) => {
    if (!!selectedVisits.find((visit) => visit.id == visitId)) return

    const visit = serviceVisits.find((visit) => visit.id == visitId)
    const formattedVisit = {
      id: visit.id,
      name: visit.name,
      organization: visit.ticket.organization.name,
      activities: [
        ...visit.warehouseProductCopy.map((activity) => ({
          ...activity,
          checked: true,
        })),
      ],
    }

    setSelectedVisits((prev) => [...prev, formattedVisit])
  }

  const handleToggleActivity = (visitId, activityId) => {
    const newSelectedVisits = selectedVisits.map((visit) => {
      if (visit.id == visitId) {
        const newActivities = visit.activities.map((activity) => {
          if (activity.id == activityId) {
            return {
              ...activity,
              checked: !activity.checked,
            }
          }

          return activity
        })

        return {
          ...visit,
          activities: newActivities,
        }
      }

      return visit
    })

    setSelectedVisits(newSelectedVisits)
  }

  const handleRemoveVisit = (visitId) => {
    const newSelectedVisits = selectedVisits.filter(
      (visit) => visit.id != visitId
    )

    setSelectedVisits(newSelectedVisits)
  }

  const handleLoadAdditionalActivities = () => {
    const formattedSelected = selected
      .filter(
        (item) =>
          !additionalActivities.find((activity) => activity.id == item.id)
      )
      .map((item) => ({
        ...item,
        checked: true,
      }))

    setAdditionalActivities((prev) => [...prev, ...formattedSelected])
    setSelected([])
  }

  const handleToggleAdditionalActivity = (activityId) => {
    const newAdditionalActivities = additionalActivities.map((activity) => {
      if (activity.id == activityId) {
        return {
          ...activity,
          checked: !activity.checked,
        }
      }

      return activity
    })

    setAdditionalActivities(newAdditionalActivities)
  }

  const handleSubmit = () => {
    const model = {
      type: settlementType === "fv" ? 1 : 2,
      ticket: `/api/tickets/${serviceId}`,
      additionalWarehouseProducts: additionalActivities.filter(
        (activity) => activity.checked
      ),
      ticketSettlementDetails: selectedVisits.map((visit) => ({
        serviceVisit: `/api/service_visits/${visit.id}`,
        warehouseProducts: visit.activities.filter(
          (activity) => activity.checked
        ),
      })),
      description,
    }

    setIsSubmiting(true)
    POST("ticket_settlements", model)
      .then((res) => {
        handleCloseModal()
        window.location.reload()
      })
      .catch((err) => {
        toast.error("Nie udało się utworzyć rozliczenia")
      })
      .finally(() => {
        setIsSubmiting(false)
      })
  }

  const resetForm = () => {
    setSelectedVisits([])
    setAdditionalActivities([])
    setSelectedVisitId("")
    setTotalPrice(0)
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        modalClassName="modal-fixed-right-wide modal-theme"
        className="modal-dialog-vertical"
        contentClassName="min-vh-100 w-500 border-0"
        toggle={handleCloseModal}
      >
        <div className="position-absolute t-0 r-0  z-index-1">
          <Button
            size="sm"
            className="close close-circle d-flex flex-center transition-base mt-3 mr-3"
            onClick={() => handleCloseModal()}
          >
            <FontAwesomeIcon
              icon="times"
              transform="shrink-6 right-0.3 down-0.3"
            />
          </Button>
        </div>
        <ModalHeader tag="div" className="modal-header-settings">
          <div className="py-1 flex-grow-1">
            <h5>{"Rozliczenie"}</h5>
          </div>
        </ModalHeader>
        <>
          <Form className="p-4 overflow-auto">
            <FormGroup>
              <Label for="clientPerson">Umowa:</Label>
              {activeAgreement ? (
                <p>
                  <StyledGreenLink to={`/agreements/${activeAgreement?.id}`}>
                    {activeAgreement?.name}
                  </StyledGreenLink>
                </p>
              ) : (
                <StyledAlertInfo>Brak aktywnej umowy</StyledAlertInfo>
              )}
            </FormGroup>
            <FormGroup>
              <Label>Typ rozliczenia</Label>
              <Input
                type="select"
                className="mb-2"
                disabled={isAgreementLoading}
                value={settlementType}
                onChange={(e) => {
                  setSelected([])
                  setAdditionalActivities([])
                  setSettlementType(e.target.value)
                }}
              >
                <option value="fv">Faktura VAT</option>
                <option value="rw">Rozchód wewnętrzny</option>
              </Input>
            </FormGroup>

            <FormGroup>
              <Label className="mt-3" for="note">
                Uwagi
              </Label>
              <Input
                type="textarea"
                rows={5}
                name="description"
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <Label for="filters">Wybierz produkt</Label>
              <Input
                type="select"
                className="mb-2"
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    productType: e.target.value,
                  })
                }
              >
                <option value="">Typ</option>
                {productTypes.map((el) => (
                  <option key={el.id} value={el.id}>
                    {el.name}
                  </option>
                ))}
              </Input>
              <Input
                type="select"
                className="mb-2"
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    familyTypes: e.target.value,
                  })
                }
              >
                <option value="">Rodzaj</option>
                {familyTypes.map((el) => (
                  <option key={el.id} value={el.id}>
                    {el.name}
                  </option>
                ))}
              </Input>
              <Input
                type="select"
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    manufacturer: e.target.value,
                  })
                }
              >
                <option value="">Producent</option>
                {manufacturers.map((el) => (
                  <option key={el.id} value={el.id}>
                    {el.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Input
                placeholder="Wyszukaj"
                value={filters.search}
                onChange={(e) =>
                  setFilters({ ...filters, search: e.target.value })
                }
              />
            </FormGroup>
            <ProductSelectorValidatorSettlement
              filters={filters}
              selected={selected}
              setSelected={setSelected}
              deviceId={deviceId}
              settlementType={settlementType}
              activeAgreementType={activeAgreementType}
            />
            <Button
              color="success"
              size="sm"
              onClick={handleLoadAdditionalActivities}
              disabled={selected.length === 0}
            >
              Dodaj produkty
            </Button>
            <FormGroup>
              <Label for="offer">Wybierz wizyty do rozliczenia</Label>
              <Input
                type="select"
                name="offer"
                id="offer"
                value={selectedVisitId}
                onChange={(e) => setSelectedVisitId(e.target.value)}
              >
                <option value="">Wybierz wizytę</option>
                {serviceVisits.map((visit) => (
                  <option value={visit.id} key={visit.id}>
                    {visit.name} ({visit.ticket.organization.name})
                  </option>
                ))}
              </Input>
              <Button
                color="falcon-default"
                size="sm"
                className="mt-3"
                onClick={() => handleLoadVisit(selectedVisitId)}
                disabled={!selectedVisitId}
              >
                Załaduj czynności
              </Button>
            </FormGroup>

            <FormGroup>
              {selectedVisits.map((visit) => (
                <VisitWrapper key={visit.id}>
                  <VisitHeading>
                    {visit.name}
                    <TrashButton onClick={() => handleRemoveVisit(visit.id)} />
                  </VisitHeading>
                  {visit.activities.length > 0
                    ? visit.activities.map((activity) => (
                        <OfferProductRow key={activity.model}>
                          <ModelCheckboxRow>
                            <input
                              className="mr-2"
                              type="checkbox"
                              checked={activity.checked}
                              onChange={() =>
                                handleToggleActivity(visit.id, activity.id)
                              }
                            />
                            <span>
                              {activity.model} - {activity.quantity}szt (
                              {activity.price} zł)
                            </span>
                          </ModelCheckboxRow>
                          {/* <span>{activity?.isFree ? formatCurrency(0) : formatCurrency(activity.price)}</span> */}
                        </OfferProductRow>
                      ))
                    : "Brak czynności"}
                </VisitWrapper>
              ))}
            </FormGroup>

            <FormGroup>
              <h5 className="mt-2">Dodatkowe produkty:</h5>

              {additionalActivities.length > 0
                ? additionalActivities.map((activity) => (
                    <OfferProductRow key={activity.model}>
                      <ModelCheckboxRow>
                        <input
                          className="mr-2"
                          type="checkbox"
                          checked={activity.checked}
                          onChange={() =>
                            handleToggleAdditionalActivity(activity.id)
                          }
                        />
                        <span>
                          {activity.model} - {activity.quantity}szt (
                          {activity.price} zł)
                        </span>
                      </ModelCheckboxRow>
                      {/* <span>{activity?.isFree ? formatCurrency(0) : formatCurrency(activity.price)}</span> */}
                    </OfferProductRow>
                  ))
                : "Brak czynności"}
            </FormGroup>
            <p>
              <b>Suma: </b>
              {formatCurrency(totalPrice)}
            </p>
            <Button
              className="mt-3"
              color="success"
              onClick={handleSubmit}
              disabled={isSubmiting}
            >
              Rozlicz
            </Button>
            <Button className="ml-3 mt-3" color="warning" onClick={resetForm}>
              Wyczyść
            </Button>
          </Form>
        </>
      </Modal>
    </>
  )
}

export default AddSettlement

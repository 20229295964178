import React from "react"

const InlineGroup = ({ children, ...rest }) => {
  return (
    <div
      {...rest}
      style={{ display: "flex", gap: "5px", marginBottom: "15px" }}
    >
      {children}
    </div>
  )
}

export default InlineGroup

import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { setReloadTrue } from "../../redux/actions/index"
import { setGlobalLoading } from "../../redux/actions"
import { Modal, ModalHeader } from "reactstrap"
import Loader from "../common/Loader"
import { Button, Form, FormGroup, Label, Input } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { toast } from "react-toastify"
import { useQuery } from "@apollo/client"
import { LOAD_SERVICE_VISIT } from "../../GraphQL/Queries"
import { reduceId } from "../../helpers/utils"
import {
  GET_PERIODIC_VISIT_USERS,
  GET_SERVICE_VISIT_USERS,
  ACCEPT_REJECT_SERVICE_VISIT,
  ACCEPT_REJECT_OFFER,
} from "../../helpers/axios"

const AcceptRejectVisitModal = ({
  isOpen,
  isOffer = false,
  handleCloseModal,
  setReloadTrue,
  user,
  setGlobalLoading,
  accept = true,
  visitId,
  ticketId,
  offerId,
}) => {
  const [loading, setLoading] = useState(true)

  const LoadedServiceVisit = useQuery(LOAD_SERVICE_VISIT, {
    variables: { id: isOpen || !isOffer ? visitId : null },
  })

  const [userList, setUserList] = useState([])
  const [selected, setSelected] = useState("")
  const [deviceId, setDeviceId] = useState("")
  useEffect(() => {
    setGlobalLoading(
      "AcceptRejectServiceModal",
      loading || LoadedServiceVisit.loading
    )
    return () => setGlobalLoading("AcceptRejectServiceModal", false)
  }, [loading, LoadedServiceVisit.loading, setGlobalLoading])

  useEffect(() => {
    if (!ticketId && LoadedServiceVisit.data) {
      if (LoadedServiceVisit.data.serviceVisit?.device?.id) {
        setDeviceId(LoadedServiceVisit.data.serviceVisit?.device?.id)
      }
    }
  }, [LoadedServiceVisit.data, ticketId])

  useEffect(() => {
    if ((loading && LoadedServiceVisit.data) || isOffer) {
      if (ticketId.length) {
        GET_SERVICE_VISIT_USERS(reduceId(ticketId))
          .then((res) => {
            setUserList(res.data)
          })
          .finally(() => {
            setLoading(false)
          })
      } else if (deviceId.length) {
        GET_PERIODIC_VISIT_USERS(reduceId(deviceId))
          .then((res) => {
            setUserList(res.data)
          })
          .finally(() => {
            setLoading(false)
          })
      }
    }
  }, [loading, deviceId, ticketId, LoadedServiceVisit.data, isOffer])

  const handleAcceptRejectVisit = () => {
    const acceptance_type = accept ? "client_accept" : "client_rejection"
    if (!isOffer) {
      ACCEPT_REJECT_SERVICE_VISIT(
        reduceId(visitId),
        reduceId(user.userId),
        selected,
        acceptance_type
      )
        .then((res) => {
          if (res.data?.status === "success") toast.success(res.data.message)
          if (!res.data?.status === "error") toast.error(res.data.message)
          handleCloseModal()
        })
        .catch((err) => {
          toast.error("Wystąpił błąd")
        })
    } else {
      //another webhook
    }
  }
  const handleAcceptOffer = () => {
    ACCEPT_REJECT_OFFER(
      reduceId(offerId),
      reduceId(user.userId),
      selected,
      true
    )
      .then((res) => {
        if (res?.data?.status === "success") toast.success(res.data.message)
        if (res?.data?.status === "error") toast.error(res.data.message)
        handleCloseModal()
      })
      .catch((err) => {
        toast.error("Wystąpił błąd")
      })
  }
  const handleRejectOffer = () => {
    ACCEPT_REJECT_OFFER(
      reduceId(offerId),
      reduceId(user.userId),
      selected,
      false
    )
      .then((res) => {
        if (res?.data?.status === "success") toast.success(res.data.message)
        if (res?.data?.status === "error") toast.error(res.data.message)
        handleCloseModal()
      })
      .catch((err) => {
        toast.error("Wystąpił błąd")
      })
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        modalClassName="modal-fixed-right modal-theme"
        className="modal-dialog-vertical"
        contentClassName="min-vh-100 w-500 border-0"
        toggle={handleCloseModal}
      >
        <div className="position-absolute t-0 r-0  z-index-1">
          <Button
            size="sm"
            className="close close-circle d-flex flex-center transition-base mt-3 mr-3"
            onClick={() => handleCloseModal()}
          >
            <FontAwesomeIcon
              icon="times"
              transform="shrink-6 right-0.3 down-0.3"
            />
          </Button>
        </div>
        <ModalHeader tag="div" className="modal-header-settings">
          <div className="py-1 flex-grow-1">
            <h5>
              {!isOffer
                ? accept
                  ? "Akceptacja wizyty serwisowej"
                  : "Odrzucenie wizyty serwisowej"
                : accept
                ? "Akceptacja oferty serwisowej"
                : "Odrzucenie oferty serwisowej"}
            </h5>
          </div>
        </ModalHeader>
        {loading || LoadedServiceVisit.loading ? (
          <Loader />
        ) : (
          <Form className="p-4 overflow-auto">
            <FormGroup>
              <Label for="users">Wybierz użytkownika</Label>
              <Input
                type="select"
                name="users"
                id="users"
                value={selected}
                onChange={(e) => {
                  setSelected(e.target.value)
                }}
              >
                <option value={null} />
                {userList.length &&
                  userList.map((user) => (
                    <option
                      value={`${user.name} ${user.surname}`}
                    >{`${user.name} ${user.surname}`}</option>
                  ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="user">Imię i nazwisko</Label>
              <Input
                type="text"
                name="user"
                id="user"
                value={selected}
                onChange={(e) => {
                  setSelected(e.target.value)
                }}
              />
            </FormGroup>
            <Button
              className="mt-3"
              color="success"
              onClick={() => {
                if (!isOffer) handleAcceptRejectVisit()
                else {
                  accept ? handleAcceptOffer() : handleRejectOffer()
                }
              }}
            >
              Zapisz
            </Button>
          </Form>
        )}
      </Modal>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setReloadTrue: () => dispatch(setReloadTrue()),
    setGlobalLoading: (component, value) =>
      dispatch(setGlobalLoading({ [component]: value })),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AcceptRejectVisitModal)

import React, { useState, useEffect, useCallback } from "react"
import { Switch, Route, useHistory, Redirect } from "react-router-dom"
import { Card, CardBody, Col, Input, Row } from "reactstrap"
import styled from "styled-components"
import { toast } from "react-toastify"
import { settings } from "config"
import moment from "moment"
import { POST } from "helpers/axios"
import { Link } from "react-router-dom"

const allowedStatuses = {
  new: ["to_consideration"],
  consideration: ["to_service_visit_planned", "to_service_visit_ended"],
  service_visit_planned: [],
  service_visit_waiting: [],
  service_visit_during: [],
  service_visit_ended: [
    "to_ended",
    "to_consideration_after_service_visit_ended",
  ],
  ended: [],
}

const filterStatuses = (status, fetchedStatuses) => {
  return fetchedStatuses.filter((el) => {
    const arrayOfAllowed = allowedStatuses[status] || []

    return arrayOfAllowed.includes(el.action_name)
  })
}

const ServiceData = ({ ticket, serviceId, setBtnAnimation, refetch }) => {
  const history = useHistory()
  const [statuses, setStatuses] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  console.log(ticket)

  useEffect(() => {
    fetchStatuses()
  }, [serviceId])

  const fetchStatuses = () =>
    POST("workflow/get-status", {
      ticket_id: serviceId,
    })
      .then(({ data }) => {
        setStatuses(data)
      })
      .catch(() => toast.error("Błąd pobierania danych"))

  const handleChangeStatus = (value) => {
    setIsLoading(true)

    POST("workflow/change-status", {
      ticket_id: serviceId,
      operation: value,
    })
      .then(() => {
        refetch()
        fetchStatuses()
        if (value === "to_service_visit_planned") {
          setBtnAnimation(true)
        }
      })
      .finally(() => setIsLoading(false))
      .catch((err) => {
        refetch()
        fetchStatuses()
        toast.error("Wystąpił błąd podczas zmiany statusu")
      })
  }

  return (
    <DetailsLayout>
      <DetailCardSingle>
        <SingleData>
          <span>
            <b>Nazwa</b>
          </span>
          <span>{ticket?.name}</span>
        </SingleData>
        <SingleData>
          <span>
            <b>Status zlecenia</b>
          </span>
          <Input
            type="select"
            style={{ fontSize: ".8rem", padding: 0 }}
            onChange={(e) => handleChangeStatus(e.target.value)}
            disabled={ticket?.label === "Planowanie serwisu" || isLoading}
          >
            <option value={ticket?.status}>{`${ticket?.label}`}</option>
            {filterStatuses(ticket?.status, statuses).map((el) => (
              <option key={el.action_name} value={el.action_name}>
                {el.button_name}
              </option>
            ))}
          </Input>
        </SingleData>
        <SingleData>
          <span>
            <b>Producent</b>
          </span>
          <span>{ticket?.manufacturer}</span>
        </SingleData>
        <SingleData>
          <span>
            <b>Model</b>
          </span>
          <span>{ticket?.model}</span>
        </SingleData>
        <SingleData>
          <span>
            <b>Numer seryjny</b>
          </span>
          <span
            onClick={() => history.push(`/devices/${ticket?.deviceId}`)}
            className="text-link-blue-600"
          >
            {ticket?.serialNumber}
          </span>
        </SingleData>
        <SingleData>
          <span>
            <b>Data rozpoczęcia zlecenia</b>
          </span>
          <span>
            {ticket?.date
              ? moment(ticket?.date).format(settings.dateFormat)
              : "--"}
          </span>
        </SingleData>
        <SingleData>
          <span>
            <b>Data zakończenia zlecenia</b>
          </span>
          <span>
            {ticket?.endedUp
              ? moment(ticket?.endedUp).format(settings.dateFormat)
              : "--"}
          </span>
        </SingleData>
        <SingleData>
          <span>
            <b>Zgłaszający</b>
          </span>
          <span>{ticket?.author}</span>
        </SingleData>
        <SingleData>
          <span>
            <b>Obsługujący</b>
          </span>
          <span>{ticket?.tmbPerson}</span>
        </SingleData>
        <SingleData>
          <span>
            <b>Osoba klienta</b>
          </span>
          <span>{ticket?.clientPerson}</span>
        </SingleData>
        <SingleData>
          <span>
            <b>Klient</b>
          </span>
          <span
            onClick={() => history.push(`/clients/${ticket?.organizationId}`)}
            className="text-link-blue-600"
          >
            {ticket?.organization}
          </span>
        </SingleData>
        <SingleData>
          <span>
            <b>Lokalizacja</b>
          </span>
          <span
            onClick={() =>
              history.push(
                `/clients/${ticket?.organizationId}/department/${ticket?.departmentId}`
              )
            }
            className="text-link-blue-600-underline-hover"
          >
            {ticket?.department}
          </span>
        </SingleData>
        <SingleData>
          <span>
            <b>Umowa</b>
          </span>
          <span>
            {ticket?.activeAgreement ? (
              <Link
                to={`/agreements/${ticket?.activeAgreement.id}`}
                className="font-weight-semi-bold text-link-blue"
              >
                {ticket?.activeAgreement.name || "--"}
              </Link>
            ) : (
              "--"
            )}
          </span>
        </SingleData>
      </DetailCardSingle>
    </DetailsLayout>
  )
}

export default ServiceData

const DetailsLayout = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr;
  padding: 20px 20px;
`
const DetailCardSingle = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  font-size: 0.8rem;
  padding: 5px 0;
`
const SingleData = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 200px minmax(155px, auto);
  font-size: 0.8rem;

  @media (max-width: 1539px) {
    grid-template-columns: 150px 1fr;
  }
  @media (max-width: 1199px) {
    grid-template-columns: 200px 1fr;
  }
`
const AttachmentsLayout = styled.div`
  padding: 20px 20px;
  display: flex;
  flex-flow: column;
`

const VisitTitle = styled.p`
  font-size: 18px;
  font-weight: 600;
`

const PlannedHeading = styled.p`
  text-align: center;
  margin-bottom: 0;
  font-size: 1.2rem;
  font-weight: 600;
  color: #00864e;
`

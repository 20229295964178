import { FormGroup, Input, Label } from "reactstrap"
import React from "react"
import styled from "styled-components"

const StyledLabel = styled(Label)`
  font-size: 0.875rem;
  font-weight: 500;
  color: #404040;
`

const CheckboxGroup = ({ label, options, onChange }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Label className="m-0 mr-2">{label}</Label>
      {options.map((option) => (
        <FormGroup check inline key={option.value}>
          <Input
            type="checkbox"
            size={"large"}
            onChange={(e) => onChange(e.target.checked, option.value)}
          />
          <StyledLabel check>{option.label}</StyledLabel>
        </FormGroup>
      ))}
    </div>
  )
}

export default CheckboxGroup

import React, { useState, useEffect, useCallback } from "react"
import { GET_VISIT_BTNS, ACCEPT_REJECT_SERVICE_VISIT } from "helpers/axios"
import { reduceId, visitStatusFormatter } from "helpers/utils"
import { connect } from "react-redux"
import { Button, Row } from "reactstrap"
import AcceptRejectVisitModal from "components/actionModals/AcceptRejectVisitModal"
import { toast } from "react-toastify"
import { useQuery } from "@apollo/client"
import { LOAD_SERVICE_VISIT_STATUS } from "GraphQL/Queries"
import {
  formatStatusMessage,
  formatTypeMessage,
} from "components/dataTables/VisitsTable.config"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { getId } from "helpers/utils"
import VisitReceptionModal from "components/actionModals/VisitReceptionModal"
import { GET, PATCH } from "helpers/axios"
import { useHistory } from "react-router-dom"
import { handleShowOfferType } from "components/dataTables/OffersTableNew.config"
import { useAuthContext } from "context/AuthContext"
import OfferReceptionModal from "components/actionModals/OfferReceptionModal"

const MessageOffer = ({
  name,
  offerId,
  status,
  label,
  offerType,
  emailSent,
}) => {
  const [showReceptionModal, setShowReceptionModal] = useState(false)
  const [departmentId, setDepartmentId] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const { user } = useAuthContext()

  useEffect(() => {
    setIsLoading(true)
    GET(`service_offers/${offerId}`)
      .then((res) => {
        setDepartmentId(res.data.ticket?.device?.Department?.id)
      })
      .finally(() => setIsLoading(false))
  }, [])

  const handleEnd = () => {
    PATCH(`/api/service_offers/${offerId}/end-by-tmb-person`, {
      operation: "to_ended",
    })
      .then(() => history.go(0))
      .catch((err) => toast.error("Wystąpił błąd"))
  }

  return (
    <>
      {showReceptionModal && (
        <OfferReceptionModal
          isOpen={showReceptionModal}
          handleCloseModal={() => setShowReceptionModal(false)}
          departmentId={departmentId}
          offerId={offerId}
          offerStatus={status}
        />
      )}
      <p className="m-0">
        <b>Oferta:</b> {name}
      </p>
      <p>
        <b>Status oferty:</b> {label} <br />
        <b>Typ oferty:</b> {handleShowOfferType(offerType, !emailSent)}
      </p>
      <Button
        className="mt-1 mr-2 btn btn-falcon-default btn-sm"
        onClick={() => history.push(`/offers/${offerId}`)}
      >
        Pokaż szczegóły
      </Button>
      {(status === "pending" ||
        (user.roles.includes("ROLE_TMB") && status === "overdue")) && (
        <Button
          color="success"
          size="sm"
          onClick={() => setShowReceptionModal(true)}
          disabled={isLoading}
        >
          Odbiór oferty
        </Button>
      )}
      {status === "overdue" && user.roles.includes("ROLE_TMB") && (
        <Button
          color="success"
          className="ml-2"
          size="sm"
          onClick={handleEnd}
          disabled={isLoading}
        >
          Zakończone
        </Button>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps, null)(MessageOffer)

import React from "react"
import styled from "styled-components"
import {
  faCheck,
  faDownload,
  faStarOfLife,
} from "@fortawesome/free-solid-svg-icons"
import { Spinner } from "reactstrap"
import {
  CheckIcon,
  DownloadIcon,
  StarOfLifeIcon,
  XMarkIcon,
} from "../icons/icons"

const StyledBadge = styled.div`
  font-size: 10px;
  font-weight: bold;
  border-radius: 24px;
  padding: 4px 8px;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  white-space: nowrap;
  text-align: center;
  max-width: 120px;
`

const Badge = ({ children, bgColor, color, isLoading }) => {
  return isLoading ? (
    <Spinner size="sm" color="primary" type="grow" />
  ) : (
    <StyledBadge bgColor={bgColor} color={color}>
      {children}
    </StyledBadge>
  )
}

export const getStatusColor = (status, returnDefault = false) => {
  if (returnDefault) {
    return {
      color: "#000000",
      bgColor: "#c3c3c3",
    }
  }

  switch (status) {
    case 0:
      return {
        color: "#F68F57",
        bgColor: "#FEF0E8",
      }
    case 1:
      return {
        color: "#00D27B",
        bgColor: "#E0FAEF",
        icon: CheckIcon,
      }
    case "unpaid":
      return {
        color: "#E63756",
        bgColor: "#F9D1D8",
        icon: XMarkIcon,
      }
    case "paid":
      return {
        color: "#00D27B",
        bgColor: "#E0FAEF",
        icon: CheckIcon,
      }
    case "downloaded":
      return {
        color: "#748194",
        bgColor: "#EEF0F2",
        icon: DownloadIcon,
      }
    case "new":
      return {
        color: "#2F80ED",
        bgColor: "#E6EFFC",
        icon: StarOfLifeIcon,
      }
    case "planned":
      return {
        color: "#000000",
        bgColor: "#76A7DD",
      }
    case "service_visit_during":
      return {
        color: "#000000",
        bgColor: "#FC9901",
      }
    case "done":
      return {
        color: "#000000",
        bgColor: "#8ADACE",
      }
    case "client_accept":
      return {
        color: "#000000",
        bgColor: "#93C37D",
      }
    case "client_rejection":
      return {
        color: "#000000",
        bgColor: "#FB0001",
      }
    case "refusal":
      return {
        color: "#ffffff",
        bgColor: "#000000",
      }
    case "settled":
      return {
        color: "#ffffff",
        bgColor: "#0D5D14",
      }
    default:
      return {
        color: "#000000",
        bgColor: "#c3c3c3",
      }
  }
}

export default Badge

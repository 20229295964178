import React from "react"
import { DoubleData } from "./SettlementDetails"

const Name = ({ name }) => {
  return (
    <DoubleData>
      <span className="mr-4">
        <b>Numer rozliczenia:</b>
      </span>
      <span>{name}</span>
    </DoubleData>
  )
}

export default Name

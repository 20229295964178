import React, { useState } from "react"
import Select from "../Select/Select"
import { monthOptions } from "../../../dataTables/SettlementsTable/config/selectOptions.config"
import DateRange from "../DateRange/DateRange"
import styled from "styled-components"

const StyledDateRangeContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
`

const formatDateString = (date) => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, "0")
  const day = String(date.getDate()).padStart(2, "0")
  return `${year}-${month}-${day}`
}

export const getDefaultDateRange = () => {
  const today = new Date()
  const start = new Date(today.getFullYear(), today.getMonth(), 2)
    .toISOString()
    .split("T")[0]
  const end = today.toISOString().split("T")[0]
  return [start, end]
}

const DateRangePicker = ({ isDisabled, placeholder, onChange }) => {
  const [range, setRange] = useState(getDefaultDateRange())
  const [month, setMonth] = useState(new Date().getMonth() + 1)

  const handleMonthChange = (e) => {
    const selectedMonth = parseInt(e.target.value, 10)
    const year = new Date().getFullYear()
    const start = new Date(year, selectedMonth - 1, 1)
    const end = new Date(year, selectedMonth, 0)

    setRange([formatDateString(start), formatDateString(end)])
    onChange([formatDateString(start), formatDateString(end)])
    setMonth(selectedMonth)
  }

  return (
    <StyledDateRangeContainer>
      <Select
        onChange={(e) => {
          handleMonthChange(e)
        }}
        isDisabled={isDisabled}
        placeholder={placeholder}
        label=""
        currentValue={month}
        options={monthOptions}
      />
      <DateRange
        start={range[0]}
        end={range[1]}
        setRange={setRange}
        setMonth={setMonth}
        isDisabled={isDisabled}
        placeholder={placeholder}
        onChange={onChange}
      />
    </StyledDateRangeContainer>
  )
}

export default DateRangePicker

import React from "react"
import ButtonIcon from "components/common/ButtonIcon"
import styled, { keyframes } from "styled-components"
import { DropdownItem, DropdownMenu, Dropdown } from "reactstrap"
import { toast } from "react-toastify"
import { GET, PATCH } from "helpers/axios"
import { useAuthContext } from "context/AuthContext"
import TrashButton from "components/common/TrashButton"
import { Link } from "react-router-dom"
import Loader from "components/common/Loader"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "eventsource/example/eventsource-polyfill"

const ButtonWrapper = styled.div`
  position: relative;

  &::after {
    content: "${(props) => props.count.toString()}";
    position: absolute;
    bottom: -5px;
    right: 0px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    color: black;
    background-color: #ddd;
    font-size: 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
`

const DropdownMenuWrapper = styled(DropdownMenu)`
  max-height: 50vh;
  width: 450px;
  max-width: 80vw;
  overflow-y: scroll !important;
  /* overflow-x: hidden !important; */
`

const NewNotificationMessage = styled.button`
  position: fixed;
  width: 250px;
  margin-left: 100px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.75rem;
  background-color: lightblue;
  border-radius: 10px;
  color: white;
  padding: 10px 15px;
  border: none;
`

const DropdownItemWrapper = styled(DropdownItem)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  font-weight: ${(props) => (props.readed ? "normal" : "bold")} !important;
`

const LoaderWrapper = styled.div`
  max-width: 400px;
`

const NotificationsManager = () => {
  const [notifications, setNotifications] = React.useState([])
  const [dropdownOpen, setDropdownOpen] = React.useState(false)
  const toggle = () => {
    setIsNewNotification(false)
    setDropdownOpen((prevState) => !prevState)
  }
  const [notificationsFetched, setNotificationsFetched] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [hasMore, setHasMore] = React.useState(true)
  const [currentPage, setCurrentPage] = React.useState(1)
  const [isNewNotification, setIsNewNotification] = React.useState(false)
  const [showNewNotificationMessage, setShowNewNotificationMessage] =
    React.useState(false)
  const { user } = useAuthContext()

  const fetchData = async (page) => {
    setIsLoading(true)

    await GET(`user_notifications?pagination=true&page=${page}`)
      .then(({ data }) => {
        setNotifications((prev) => [...prev, ...data["hydra:member"]])
        setCurrentPage(page + 1)
        if (data["hydra:member"].length === 0) {
          return setHasMore(false)
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading(false)
        setNotificationsFetched(true)
      })
  }

  const observerTarget = React.useRef(null)
  const topRef = React.useRef(null)

  React.useEffect(() => {
    if (!notificationsFetched || !hasMore || isLoading) return

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !isLoading) {
          fetchData(currentPage)
        }
      },
      { threshold: 1 }
    )

    if (observerTarget.current) {
      observer.observe(observerTarget.current)
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current)
      }
    }
  }, [observerTarget, notificationsFetched, hasMore, isLoading])

  React.useEffect(() => {
    fetchData(currentPage)
  }, [])

  React.useEffect(() => {
    if (!notificationsFetched) {
      return
    }

    const sse = new window.EventSourcePolyfill(
      `${process.env.REACT_APP_NOTIFICATIONS_SOURCE}_${user.id}`,
      {
        headers: {
          authorization: `Bearer ${process.env.REACT_APP_NOTIFICATIONS_JWT}`,
        },
      }
    )

    const getRealtimeData = (data) => {
      const formattedData = {
        ...data,
        notification: {
          description: data.description,
          link: data.link,
        },
      }

      toast.success(formattedData.notification.description, {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
      })

      setNotifications((prev) => [formattedData, ...prev])
      setShowNewNotificationMessage(true)
      setIsNewNotification(true)
    }

    sse.onmessage = (e) => getRealtimeData(JSON.parse(e.data))

    sse.onerror = (e) => {
      console.log(e)
      sse.close()
    }

    return () => {
      sse.close()
    }
  }, [notificationsFetched])

  const handleReadNotification = (id) => {
    setDropdownOpen(false)
    setIsNewNotification(false)

    PATCH(`/api/user_notifications/${id}`, {
      readed: true,
      unreaded: false,
    })

    setNotifications((prev) =>
      prev.map((notification) =>
        notification.id === id
          ? {
              ...notification,
              readed: true,
              unreaded: false,
            }
          : notification
      )
    )
  }

  const handleDeleteNotification = (id) => {
    setIsNewNotification(false)

    PATCH(`/api/user_notifications/${id}`, {
      closed: true,
    })

    setNotifications((prev) =>
      prev.filter((notification) => notification.id !== id)
    )
  }

  const handleScrollToTop = (e) => {
    e.stopPropagation()
    if (!topRef.current) return
    topRef.current.scrollIntoView({ behavior: "smooth" })
    setShowNewNotificationMessage(false)
  }

  return (
    <Dropdown
      nav
      inNavbar
      isOpen={dropdownOpen}
      toggle={toggle}
      onClick={toggle}
    >
      <ButtonWrapper count={notifications.length}>
        <ButtonIcon
          icon="bell"
          color="falcon-default"
          size="md"
          animation={isNewNotification}
          animationInfinite
        />
      </ButtonWrapper>
      <DropdownMenuWrapper right className="dropdown-menu-card">
        <div ref={topRef} />
        <div className="bg-white rounded-soft py-2">
          {showNewNotificationMessage && (
            <NewNotificationMessage onClick={handleScrollToTop}>
              Nowa wiadomość <FontAwesomeIcon icon="arrow-up" />
            </NewNotificationMessage>
          )}
          {notifications.map((notification) => (
            <DropdownItemWrapper
              key={notification.notification.id}
              className="text-center"
              readed={notification.readed}
            >
              <a
                href={notification.notification.link}
                onClick={() => handleReadNotification(notification.id)}
              >
                {notification.notification.description}
              </a>
              <TrashButton
                onClick={() => handleDeleteNotification(notification.id)}
              />
            </DropdownItemWrapper>
          ))}
          <div ref={observerTarget} />
          {isLoading && (
            <LoaderWrapper>
              <Loader size="sm" />
            </LoaderWrapper>
          )}
        </div>
      </DropdownMenuWrapper>
    </Dropdown>
  )
}

export default NotificationsManager

import { useCallback, useEffect, useState } from "react"
import { GET } from "helpers/axios"

const useGetCounterpartyList = () => {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const url = "organizations-with-departments"

  const fetchData = useCallback(async () => {
    setLoading(true)
    try {
      const response = await GET(url)
      setData(response.data)
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }, [url])

  useEffect(() => {
    fetchData()
  }, [url])

  const counterpartyList = data
    ? data["hydra:member"]?.map((counterparty) => ({
        value: counterparty["id"],
        label: counterparty.shortName,
      }))
    : []

  return { counterpartyList, error, loading, refetch: fetchData }
}

export default useGetCounterpartyList

import React from "react"
import { Redirect } from "react-router-dom"
import { useAuthContext } from "context/AuthContext"
import Loader from "components/common/Loader"
import TmbDashboardLayout from "layouts/TmbDashboardLayout"
import ClientDashboardLayout from "layouts/ClientDashboardLayout"

const Dashboard = () => {
  const { isLoading, user } = useAuthContext()

  const renderView = () => {
    if (!user) return null

    if (user.roles.includes("ROLE_TMB") || user.roles.includes("ROLE_CLIENT")) {
      return <TmbDashboardLayout />
    }
    // if (user.roles.includes("ROLE_CLIENT")) {
    //   return <ClientDashboardLayout />
    // }
    return "No group info"
  }

  if (isLoading) {
    return (
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ height: "100vh", width: "100vw" }}
      >
        <Loader />
        <h5 className="text-center">Ładowanie aplikacji...</h5>
      </div>
    )
  }

  return renderView()
}

export default Dashboard

import axios from "axios"
import { API, PRIVATE_UPLOADS, SERVER } from "./endpoints"
import { AUTH_TOKEN_NAME } from "../context/auth-context.const"

export const TABLE_GET = async (
  location,
  page,
  searchValue,
  filters,
  sort,
  reduceFor
) => {
  const token = localStorage.getItem(AUTH_TOKEN_NAME)
  const postAxiosConfig = {
    headers: {
      authorization: `Bearer ${token}`,
    },
    params: {
      page,
      ...filters,
      ...reduceFor,
    },
  }
  if (searchValue) postAxiosConfig.params[`search_advance[]`] = searchValue
  if (sort?.field) postAxiosConfig.params[`order[${sort.field}]`] = sort.order
  return axios.get(`${API}/${location}`, token ? postAxiosConfig : null)
}

export const GET = async (location) => {
  const token = localStorage.getItem(AUTH_TOKEN_NAME)
  const postAxiosConfig = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }

  return axios.get(`${API}/${location}`, token ? postAxiosConfig : null)
  // .catch(err => {
  //   if (err.response.status === 404) {
  //     window.location.href = "/errors/404"
  //   }
  // })
}

export const GET_BLOB = async (location) => {
  const token = localStorage.getItem(AUTH_TOKEN_NAME)
  const postAxiosConfig = {
    headers: {
      authorization: `Bearer ${token}`,
    },
    responseType: "blob",
  }

  return axios.get(`${API}/${location}`, token ? postAxiosConfig : null)
  // .catch(err => {
  //   if (err.response.status === 404) {
  //     window.location.href = "/errors/404"
  //   }
  // })
}

export const GET_CUSTOM_ROUTE = async (location) => {
  const token = localStorage.getItem(AUTH_TOKEN_NAME)
  const postAxiosConfig = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }
  return axios.get(`${SERVER}/${location}`, token ? postAxiosConfig : null)
}

export const POST = async (location, data) => {
  const token = localStorage.getItem(AUTH_TOKEN_NAME)
  const postAxiosConfig = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }
  return axios.post(`${API}/${location}`, data, token ? postAxiosConfig : null)
}

export const PATCH = async (id, data) => {
  const token = localStorage.getItem(AUTH_TOKEN_NAME)
  const patchAxiosConfig = {
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": "application/merge-patch+json",
    },
  }
  return axios.patch(`${SERVER}${id}`, data, token ? patchAxiosConfig : null)
}

export const DELETE = async (id) => {
  const token = localStorage.getItem(AUTH_TOKEN_NAME)
  const postAxiosConfig = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }
  return axios.delete(`${SERVER}${id}`, token ? postAxiosConfig : null)
}

export const POST_ACTIVITY = async (data) => {
  const token = localStorage.getItem(AUTH_TOKEN_NAME)
  const postAxiosConfig = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }
  return axios.post(
    `${API}/createActivityLog/`,
    data,
    token ? postAxiosConfig : null
  )
}

export const POST_SINGLE_ATTACHMENT = async (file, isPublic) => {
  const formData = new FormData()
  formData.append("file1", file)
  const body = JSON.stringify({ public: isPublic })
  formData.append("body", body)
  const token = localStorage.getItem(AUTH_TOKEN_NAME)
  const postFileAxiosConfig = {
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  }
  return axios.post(
    `${API}/file/upload/attachment`,
    formData,
    token ? postFileAxiosConfig : null
  )
}

export const POST_SINGLE_IMAGE = async (file, isPublic) => {
  const formData = new FormData()
  formData.append("file1", file)
  const body = JSON.stringify({ public: isPublic })
  formData.append("body", body)
  const token = localStorage.getItem(AUTH_TOKEN_NAME)
  const postFileAxiosConfig = {
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  }
  return axios.post(
    `${API}/file/upload/image`,
    formData,
    token ? postFileAxiosConfig : null
  )
}

export const POST_CSV = async (fileList) => {
  console.log(fileList)
  const formData = new FormData()
  fileList.map((el) => formData.append(`csv_files[]`, el))
  const token = localStorage.getItem(AUTH_TOKEN_NAME)
  const postFileAxiosConfig = {
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  }
  return axios.post(
    `${API}/import-csv`,
    formData,
    token ? postFileAxiosConfig : null
  )
}

export const GET_FILE = async (fileName) => {
  const token = localStorage.getItem(AUTH_TOKEN_NAME)
  const getFileAxiosConfig = {
    responseType: "blob",
    headers: {
      authorization: `Bearer ${token}`,
    },
  }
  axios
    .get(`${PRIVATE_UPLOADS}/${fileName}`, getFileAxiosConfig)
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", fileName)
      document.body.appendChild(link)
      link.click()
    })
}

export const AGREEMENTS_SETTLE_WEBHOOK = async () => {
  const token = localStorage.getItem(AUTH_TOKEN_NAME)
  const postFileAxiosConfig = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }
  return axios.get(
    `${API}/clientagreements/settlements`,
    token ? postFileAxiosConfig : null
  )
}

export const AGREEMENT_SETTLE_WEBHOOK = async (agreementId) => {
  const token = localStorage.getItem(AUTH_TOKEN_NAME)
  const postFileAxiosConfig = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }
  return axios.get(
    `${API}/clientagreements/settlement/${agreementId}`,
    token ? postFileAxiosConfig : null
  )
}

export const GET_AGREEMENT_DATA_WEBHOOK = async (agreementId) => {
  const token = localStorage.getItem(AUTH_TOKEN_NAME)
  const postFileAxiosConfig = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }
  return axios.get(
    `${API}/clientagreements/settlementhistory/${agreementId}`,
    token ? postFileAxiosConfig : null
  )
}

export const CHECK_AGREEMENT_STATUS = async () => {
  const token = localStorage.getItem(AUTH_TOKEN_NAME)
  const postFileAxiosConfig = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }
  return axios.get(
    `${API}/clientagreements/status`,
    token ? postFileAxiosConfig : null
  )
}

export const GET_VISIT_BTNS = async (visitId) => {
  const token = localStorage.getItem(AUTH_TOKEN_NAME)
  const postFileAxiosConfig = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }
  return axios.post(
    `${API}/workflow/get-visit-status`,
    {
      service_visit_id: visitId,
    },
    token ? postFileAxiosConfig : null
  )
}

export const GET_SERVICE_VISIT_USERS = async (ticketId) => {
  const token = localStorage.getItem(AUTH_TOKEN_NAME)
  const postFileAxiosConfig = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }
  return axios.get(
    `${API}/mobile/personsbyorganizationticket/${ticketId}`,
    token ? postFileAxiosConfig : null
  )
}

export const GET_PERIODIC_VISIT_USERS = async (deviceId) => {
  const token = localStorage.getItem(AUTH_TOKEN_NAME)
  const postFileAxiosConfig = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }
  return axios.get(
    `${API}/mobile/personsbyorganizationdevice/${deviceId}`,
    token ? postFileAxiosConfig : null
  )
}

export const ACCEPT_REJECT_SERVICE_VISIT = async (
  visitId,
  userId,
  acceptance_person,
  acceptance_type
) => {
  const token = localStorage.getItem(AUTH_TOKEN_NAME)
  const postFileAxiosConfig = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }
  return axios.post(
    `${API}/service-visit/acceptance`,
    {
      service_visit_id: visitId,
      acceptance_user_id: userId,
      acceptance_person,
      acceptance_type,
    },
    token ? postFileAxiosConfig : null
  )
}

export const ACCEPT_REJECT_OFFER = async (
  offerId,
  acceptance_user_id,
  acceptance_person,
  isAccept = true
) => {
  const token = localStorage.getItem(AUTH_TOKEN_NAME)
  const postFileAxiosConfig = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }
  return isAccept
    ? axios.post(
        `${API}/agreement/${offerId}/accept`,
        {
          acceptance_user_id,
          acceptance_person,
        },
        token ? postFileAxiosConfig : null
      )
    : axios.post(
        `${API}/agreement/${offerId}/reject`,
        {
          acceptance_user_id,
          acceptance_person,
        },
        token ? postFileAxiosConfig : null
      )
}

export const ACCEPT_REJECT = async (offerId, btn_action_name) => {
  const token = localStorage.getItem(AUTH_TOKEN_NAME)
  const postFileAxiosConfig = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }
  return axios.post(
    `${API}/workflow/change-agreement-status`,
    { agreement_id: offerId, operation: btn_action_name },
    token ? postFileAxiosConfig : null
  )
}

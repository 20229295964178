import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import { CardBody } from "reactstrap"
import Pagination from "components/common/Pagination"
import Loader from "components/common/Loader"
import styled from "styled-components"

export const Overflow = styled.div`
  overflow-x: scroll;
`

const Table = ({
  data,
  remote,
  columns,
  onTableChange,
  currentPage,
  totalPages,
  incrementPage,
  decrementPage,
  setPage,
  isLoading,
  sortingField,
  order,
  setSortSettings,
  rowStyle,
  showPagination = true,
}) => {
  return (
    <CardBody className="p-0">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {data && data.length > 0 ? (
            <Overflow>
              <BootstrapTable
                remote={
                  remote || {
                    sort: true,
                  }
                }
                bootstrap4
                keyField="id"
                data={data}
                columns={columns}
                bordered={false}
                classes="table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
                rowClasses={`btn-reveal-trigger border-top border-200`}
                rowStyle={rowStyle}
                headerClasses="bg-200 text-900 border-y border-200"
                onTableChange={(changeType, event) => {
                  if (changeType !== "sort") return
                  const { sortField, sortOrder } = event

                  setSortSettings(`${sortField}:${sortOrder}`)
                }}
                sort={{
                  dataField: sortingField,
                  order,
                }}
              />
            </Overflow>
          ) : (
            <h3 className="p-4">Brak wyników</h3>
          )}
          {showPagination && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              incrementPage={incrementPage}
              decrementPage={decrementPage}
              setPage={setPage}
            />
          )}
        </>
      )}
    </CardBody>
  )
}

export default Table

import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { setReloadTrue } from "../../redux/actions/index"
import { setGlobalLoading } from "../../redux/actions"
import PropTypes from "prop-types"
import { Modal, ModalHeader } from "reactstrap"
import { Button, Form, FormGroup, Label, Input } from "reactstrap"
import ButtonIcon from "../common/ButtonIcon"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { toast } from "react-toastify"
import { POST_ACTIVITY, POST_SINGLE_ATTACHMENT } from "../../helpers/axios"
import { validateFileFormat } from "../../helpers/utils"
import { useHistory } from "react-router-dom"

const AddAttachment = ({
  setReloadTrue,
  isOpen,
  handleCloseModal,
  refetch,
  user,
  serviceId,
  deviceId,
  setGlobalLoading,
  reload = false,
}) => {
  const [sendingFile, setSendingFile] = useState(false)
  const [form, setForm] = useState({
    title: "",
    file: "",
  })
  const history = useHistory()

  useEffect(
    () =>
      setForm({
        title: "",
        file: "",
      }),
    [isOpen]
  )

  useEffect(() => {
    if (sendingFile) setGlobalLoading("AddAttachment", true)
    else setGlobalLoading("AddAttachment", false)
    return () => {
      setGlobalLoading("AddAttachment", false)
    }
  }, [sendingFile, setGlobalLoading])

  const handleSaveMsg = () => {
    if (serviceId) {
      const activityModel = {
        ticket_id: serviceId,
        device_id: null,
        note: {
          description: "",
          attachment: form.file
            ? [
                {
                  title: form.title,
                  file: form.file,
                },
              ]
            : null,
          link: null,
        },
        link: null,
        attachment: null,
        tmbPerson: user.tmbPerson
          ? user.tmbPerson.replace("/api/tmb_people/", "")
          : null,
        clientPerson: user.clientPerson
          ? user.clientPerson.replace("/api/client_people/", "")
          : null,
      }
      setGlobalLoading("AddAttachment", true)
      POST_ACTIVITY(activityModel)
        .then(() => {
          setForm({ title: "", file: "" })
          if (refetch) refetch()
          handleCloseModal()
          setReloadTrue()
        })
        .catch((err) => toast.error("Wystąpił błąd"))
        .finally(() => {
          setGlobalLoading("AddAttachment", false)
        })
    }
  }

  const handleSaveAttachment = () => {
    if (form.file) {
      if (!sendingFile) {
        const activityModel = {
          ticket_id: serviceId ? serviceId : null,
          device_id: deviceId ? deviceId.replace("/api/devices/", "") : null,
          note: null,
          link: null,
          attachment: {
            title: form.title || form.file,
            file: form.file,
          },
          tmbPerson: user.tmbPerson
            ? user.tmbPerson.replace("/api/tmb_people/", "")
            : null,
          clientPerson: user.clientPerson
            ? user.clientPerson.replace("/api/client_people/", "")
            : null,
        }
        setGlobalLoading("AddAttachment", true)
        POST_ACTIVITY(activityModel)
          .then(() => {
            handleSaveMsg()
            if (deviceId) {
              if (refetch) refetch()
              handleCloseModal()
              setReloadTrue()
              toast.success("Dodano załącznik")
              if (reload) history.go(0)
            }
          })
          .catch((err) => toast.error("Wystąpił błąd"))
          .finally(() => {
            setGlobalLoading("AddAttachment", false)
          })
      } else toast.error("Pliki nie zostały jeszcze wysłane")
    } else toast.error("Nie dodano załącznika")
  }

  const handleSelectFile = (event) => {
    if (!event.target.files[0]) return

    if (event.target.files[0].size > 20971520) {
      event.target.value = null
      return toast.error("Rozmiar pliku jest za duży")
    }

    if (!validateFileFormat(event.target.files[0])) {
      event.target.value = null
      return toast.error("Nieobsługiwany format pliku")
    }

    setSendingFile(true)
    POST_SINGLE_ATTACHMENT(event.target.files[0], "false")
      .then((res) => {
        setForm({ ...form, file: res.data[0] })
      })
      .catch((err) => {
        toast.error("Błąd przesyłania pliku")
      })
      .finally(() => {
        setSendingFile(false)
      })
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        modalClassName="modal-fixed-right modal-theme"
        className="modal-dialog-vertical"
        contentClassName="min-vh-100 w-500 border-0"
        toggle={handleCloseModal}
      >
        <div className="position-absolute t-0 r-0  z-index-1">
          <Button
            size="sm"
            className="close close-circle d-flex flex-center transition-base mt-3 mr-3"
            onClick={() => handleCloseModal()}
          >
            <FontAwesomeIcon
              icon="times"
              transform="shrink-6 right-0.3 down-0.3"
            />
          </Button>
        </div>
        <ModalHeader tag="div" className="modal-header-settings">
          <div className="py-1 flex-grow-1">
            <h5>Dodaj załącznik</h5>
          </div>
        </ModalHeader>
        <Form className="p-4 overflow-auto">
          <FormGroup>
            <Label for="title">Tytuł</Label>
            <Input
              type="text"
              name="title"
              id="title"
              value={form.title}
              onChange={(e) => setForm({ ...form, title: e.target.value })}
            />
          </FormGroup>
          <FormGroup className="mb-5">
            <Input
              type="file"
              name="file"
              id="file"
              onChange={(e) => handleSelectFile(e)}
            />
          </FormGroup>
          <ButtonIcon
            icon="paperclip"
            type="button"
            size="sm"
            color="secondary"
            outline
            disabled={sendingFile}
            onClick={() => handleSaveAttachment()}
          >
            Załącz
          </ButtonIcon>
        </Form>
      </Modal>
    </>
  )
}

AddAttachment.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setReloadTrue: () => dispatch(setReloadTrue()),
    setGlobalLoading: (component, value) =>
      dispatch(setGlobalLoading({ [component]: value })),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddAttachment)

import React from "react"
import { Input } from "reactstrap"

const Checkbox = (props) => {
  return (
    <Input
      type="checkbox"
      {...props}
      style={{ position: "static", margin: 0 }}
    />
  )
}

export default Checkbox

import React from "react"
import { FormGroup, Input, Label } from "reactstrap"
import styled from "styled-components"

const StyledDateRangeContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`

const DateRange = ({
  start,
  end,
  onChange,
  isDisabled,
  placeholder,
  setRange,
  setMonth,
}) => {
  const handleStartChange = (e) => {
    setRange([e.target.value, end])
    onChange([e.target.value, end])
    setMonth(new Date(e.target.value).getMonth() + 1)
  }

  const handleEndChange = (e) => {
    setRange([start, e.target.value])
    onChange([start, e.target.value])
  }

  const lang = "pl"

  return (
    <StyledDateRangeContainer>
      <Label className="m-0 mr-2">{"od"}</Label>
      <FormGroup className="m-0 mr-2">
        <Input
          bsSize="sm"
          type="date"
          disabled={isDisabled}
          placeholder={placeholder}
          onChange={handleStartChange}
          value={start}
          lang={lang}
        />
      </FormGroup>
      <Label className="m-0 mr-2">{"do"}</Label>
      <FormGroup className="m-0 mr-2">
        <Input
          bsSize="sm"
          type="date"
          disabled={isDisabled}
          placeholder={placeholder}
          onChange={handleEndChange}
          value={end}
          lang={lang}
        />
      </FormGroup>
    </StyledDateRangeContainer>
  )
}

export default DateRange

import React from "react"
import { toast } from "react-toastify"
import { GET } from "helpers/axios"

const initialState = {
  servicesToPlan: [],
  serviceVisits: [],
  periodicVisits: [],
  servicemans: [],
  regions: [],
}

const ServicePlannerContext = React.createContext(initialState)

const ServicePlannerContextProvider = ({ children }) => {
  const [servicesToPlan, setServicesToPlan] = React.useState([])
  const [serviceVisits, setServiceVisits] = React.useState([])
  const [periodicVisits, setPeriodicVisits] = React.useState([])
  const [servicemans, setServicemans] = React.useState([])
  const [regions, setRegions] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false)

  const [regionFilter, setRegionFilter] = React.useState(null)
  const [servicemansFilter, setServicemansFilter] = React.useState("")

  const [showAllVisits, setShowAllVisits] = React.useState(false)
  const [synchronized, setSynchronized] = React.useState("success")

  const [datesSet, setDatesSet] = React.useState(null)

  const getData = () => {
    setIsLoading(true)

    return Promise.all([
      GET("service_visits?status=planning&pagination=false"),
      GET("calendar_service_visits"),
      GET("periodic_service_visits?pagination=false"),
      GET(
        "tmb_people?roles[]=ROLE_TMB_SERVICEMAN&roles[]=ROLE_TMB_SALESMAN&pagination=false"
      ),
      GET("regions?pagination=false"),
    ])
      .then((responses) => {
        const [
          servicesToPlan,
          serviceVisits,
          periodicVisits,
          servicemans,
          regions,
        ] = responses
        setServicesToPlan(servicesToPlan.data["hydra:member"])
        setServiceVisits(serviceVisits.data["hydra:member"])
        setPeriodicVisits(periodicVisits.data["hydra:member"])
        setServicemans(servicemans.data["hydra:member"])
        setRegions(regions.data["hydra:member"])
        setIsLoading(false)
      })
      .catch((err) => toast.error("Błąd pobierania danych"))
      .finally(() => setIsLoading(false))
  }

  React.useEffect(() => {
    getData()
  }, [])

  const refetch = () => {
    setIsLoading(true)

    GET("calendar_service_visits")
      .then(({ data }) => {
        setServiceVisits(data["hydra:member"])
      })
      .catch(() => toast.error("Błąd pobierania danych"))
      .finally(() => setIsLoading(false))
  }

  const refetchWithVisits = () => {
    setIsLoading(true)

    return Promise.all([
      GET("service_visits?status=planning&pagination=false"),
      GET("calendar_service_visits"),
      GET("periodic_service_visits?pagination=false"),
    ])
      .then((responses) => {
        const [servicesToPlan, serviceVisits, periodicVisits] = responses
        setServicesToPlan(servicesToPlan.data["hydra:member"])
        setServiceVisits(serviceVisits.data["hydra:member"])
        setPeriodicVisits(periodicVisits.data["hydra:member"])
        setIsLoading(false)
      })
      .catch((err) => toast.error("Błąd pobierania danych"))
      .finally(() => setIsLoading(false))
  }

  const filteredVisitsToPlan = React.useMemo(
    () =>
      servicesToPlan
        .filter((visit) => {
          if (!regionFilter) return true

          return regionFilter.includes(
            visit?.ticket?.device?.Department?.postCode
          )
        })
        .filter((visit) => {
          if (servicemansFilter) {
            return visit?.tmbPerson?.id == servicemansFilter
          }

          return true
        }),
    [servicesToPlan, regionFilter, servicemansFilter]
  )

  const filteredVisits = React.useMemo(
    () =>
      serviceVisits
        .filter((visit) => {
          if (!regionFilter) return true

          return regionFilter.includes(
            visit?.ticket?.device?.Department?.postCode
          )
        })
        .filter((visit) => {
          if (servicemansFilter) {
            return visit?.tmbPerson?.id == servicemansFilter
          }

          return true
        }),
    [serviceVisits, regionFilter, servicemansFilter]
  )

  const filteredPeriodicVisits = React.useMemo(
    () =>
      periodicVisits.filter((device) => {
        if (!regionFilter) return true

        return regionFilter.includes(device?.Department?.postCode)
      }),
    [periodicVisits, regionFilter]
  )

  return (
    <ServicePlannerContext.Provider
      value={{
        servicesToPlan,
        serviceVisits,
        periodicVisits,
        servicemans,
        regions,
        isLoading,
        showAllVisits,
        setShowAllVisits,
        regionFilter,
        setRegionFilter,
        setServiceVisits,
        servicemansFilter,
        setServicemansFilter,
        filteredVisitsToPlan,
        filteredVisits,
        filteredPeriodicVisits,
        synchronized,
        setSynchronized,
        refetch,
        refetchWithVisits,
        datesSet,
        setDatesSet,
      }}
    >
      {children}
    </ServicePlannerContext.Provider>
  )
}

export default ServicePlannerContextProvider

export const useServicePlannerContext = () =>
  React.useContext(ServicePlannerContext)

import React from "react"
import Logo from "components/navbar/Logo"
import LoginFormNew from "../LoginFormNew"

import AuthCardLayout from "layouts/AuthCardLayout"

const Login = () => (
  <AuthCardLayout>
    <Logo width={140} />
    <LoginFormNew layout="card" hasLabel />
  </AuthCardLayout>
)

export default Login

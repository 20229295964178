import React, { useState } from "react"
import { connect } from "react-redux"
import { signIn } from "redux/actions"
import PropTypes from "prop-types"
import { Button, Form, Row, Col, FormGroup, Input, Label } from "reactstrap"
import { useHistory } from "react-router-dom"
import { useAuthContext } from "context/AuthContext"
import { Redirect } from "react-router-dom"

const LoginForm = ({ hasLabel, signIn }) => {
  const { signIn: signInNew, isAuth } = useAuthContext()
  const history = useHistory()

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [isDisabled, setIsDisabled] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsDisabled(true)

    const err = await signInNew(email, password)

    if (!err) return
    setIsDisabled(false)
    setEmail("")
    setPassword("")
  }

  if (isAuth) {
    return <Redirect to="/" />
  }

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        {hasLabel && <Label>Adres email</Label>}
        <Input
          placeholder={!hasLabel ? "Email address" : ""}
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </FormGroup>
      <FormGroup>
        {hasLabel && <Label>Hasło</Label>}
        <Input
          placeholder={!hasLabel ? "Password" : ""}
          value={password}
          onChange={({ target }) => setPassword(target.value)}
          type="password"
        />
      </FormGroup>
      <FormGroup>
        <Button
          color="danger"
          block
          className="mt-3"
          disabled={isDisabled || !email || !password}
        >
          Zaloguj
        </Button>
      </FormGroup>
    </Form>
  )
}

LoginForm.propTypes = {
  setRedirect: PropTypes.func,
  layout: PropTypes.string,
  hasLabel: PropTypes.bool,
}

LoginForm.defaultProps = {
  layout: "basic",
  hasLabel: false,
}

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (user) => dispatch(signIn(user)),
  }
}

export default connect(null, mapDispatchToProps)(LoginForm)

import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { setGlobalLoading } from "../../redux/actions"
import PropTypes from "prop-types"
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { toast } from "react-toastify"
import { PATCH, POST } from "../../helpers/axios"
import { setReloadTrue } from "../../redux/actions/index"
import Loader from "../common/Loader"
import { GET } from "helpers/axios"

const allowedStatuses = {
  consideration: ["to_service_visit_planned", "to_service_visit_ended"],
  service_visit_planned: [],
  service_visit_waiting: [],
  service_visit_during: [],
  service_visit_ended: [
    "to_ended",
    "to_consideration_after_service_visit_ended",
  ],
  ended: [],
}

const filterStatuses = (status, fetchedStatuses) => {
  return fetchedStatuses.filter((el) => {
    const arrayOfAllowed = allowedStatuses[status] || []

    return arrayOfAllowed.includes(el.action_name)
  })
}

const EditTicketModal = ({
  isOpen,
  handleCloseModal,
  serviceId,
  setReloadTrue,
  setGlobalLoading,
  ticket,
  refetch,
}) => {
  const [statuses, setStatuses] = useState([])
  const [tmbPersons, setTmbPersons] = useState([])
  const [clientPersons, setClientPersons] = useState([])
  const [form, setForm] = useState({
    status: "",
    tmbPerson: "",
    clientPerson: "",
  })
  const [currentStatus, setCurrentStatus] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!ticket) return

    setCurrentStatus(ticket.status)
    setForm({
      tmbPerson: ticket.tmbPersonId,
      clientPerson: ticket.clientPersonId,
    })
  }, [ticket])

  const fetchStatuses = () => {
    setIsLoading(true)

    POST("workflow/get-status", {
      ticket_id: serviceId,
    })
      .then(({ data }) => {
        setStatuses(data)
      })
      .catch(() => toast.error("Błąd pobierania danych"))
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    fetchStatuses()
  }, [serviceId, isOpen])

  useEffect(() => {
    if (!ticket) return

    setIsLoading(true)

    GET(
      `tmb_people?roles[]=ROLE_TMB_SALESMAN&roles[]=ROLE_TMB_SERVICEMAN&pagination=false&activeUserID=${ticket.tmbPersonId}`
    )
      .then((res) => {
        const data = res.data["hydra:member"].filter((item) => !!item.user)

        const formattedData = data.map((item) => ({
          id: item.id,
          label: `${item.user?.name} ${item.user?.surname}`,
        }))

        setTmbPersons(formattedData)
      })
      .catch((err) => toast.error("Błąd pobierania danych"))
      .finally(() => setIsLoading(false))
  }, [ticket, ticket?.departmentId])

  useEffect(() => {
    if (!ticket) return

    setIsLoading(true)

    GET(
      `client_people?Department=${ticket?.departmentId}&pagination=false&activeUserID=${ticket.clientPersonId}`
    )
      .then((res) => {
        const data = res.data["hydra:member"].filter((item) => !!item.user)

        const formattedData = data.map((item) => ({
          id: item.id,
          label: `${item.user?.name} ${item.user?.surname}`,
        }))

        setClientPersons(formattedData)
      })
      .catch((err) => toast.error("Błąd pobierania danych"))
      .finally(() => setIsLoading(false))
  }, [ticket, ticket?.departmentId])

  const handleSaveTicket = () => {
    const model = {
      tmbPerson: form.tmbPerson ? `/api/tmb_people/${form.tmbPerson}` : null,
      clientPerson: form.clientPerson
        ? `/api/client_people/${form.clientPerson}`
        : null,
    }

    setIsLoading(true)
    PATCH(`/api/tickets/${serviceId}`, model)
      .then(() => {
        handleCloseModal()
        setReloadTrue()
        setGlobalLoading("EditTicketModal", false)
        refetch()
      })
      .catch(() => {
        setGlobalLoading("EditTicketModal", false)
        toast.error("Wystąpił błąd")
      })
      .finally(() => setIsLoading(false))
  }

  const handleChangeStatus = (value) => {
    setIsLoading(true)
    setCurrentStatus(value)

    POST("workflow/change-status", {
      ticket_id: serviceId,
      operation: value,
    })
      .then(() => {
        refetch()
        fetchStatuses()
      })
      .finally(() => setIsLoading(false))
      .catch((err) => {
        refetch()
        fetchStatuses()
        toast.error("Wystąpił błąd podczas zmiany statusu")
      })
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        modalClassName="modal-fixed-right modal-theme"
        className="modal-dialog-vertical"
        contentClassName="min-vh-100 w-500 border-0"
        toggle={handleCloseModal}
      >
        <div className="position-absolute t-0 r-0  z-index-1">
          <Button
            size="sm"
            className="close close-circle d-flex flex-center transition-base mt-3 mr-3"
            onClick={() => handleCloseModal()}
          >
            <FontAwesomeIcon
              icon="times"
              transform="shrink-6 right-0.3 down-0.3"
            />
          </Button>
        </div>
        <ModalHeader tag="div" className="modal-header-settings">
          <div className="py-1 flex-grow-1">
            <h5>Edycja zlecenia</h5>
          </div>
        </ModalHeader>
        {false ? (
          <Loader />
        ) : (
          <Form className="p-4 overflow-auto">
            {currentStatus !== "new" && statuses.length > 0 && (
              <FormGroup>
                <Label for="select">Status</Label>
                <Input
                  bsSize="sm"
                  type="select"
                  name="status"
                  id="status"
                  value={currentStatus}
                  onChange={(e) => handleChangeStatus(e.target.value)}
                  disabled={ticket?.label === "Planowanie serwisu" || isLoading}
                >
                  <option>{ticket?.label}</option>
                  {filterStatuses(ticket?.status, statuses).map((el, index) => (
                    <option
                      key={index + "topic"}
                      value={el.action_name}
                      onChange={(e) =>
                        setForm({ ...form, status: e.target.value })
                      }
                    >
                      {el.button_name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            )}

            <FormGroup>
              <Label for="tmbPerson">Pracownik TMB</Label>
              <Input
                bsSize="sm"
                type="select"
                name="tmbPerson"
                id="tmbPerson"
                value={form.tmbPerson}
                onChange={(e) =>
                  setForm({ ...form, tmbPerson: e.target.value })
                }
                disabled={isLoading}
              >
                <option value="">Brak</option>
                {tmbPersons?.map(({ id, label }) => (
                  <option key={id} value={id}>
                    {label}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="tmbPerson">Osoba klienta</Label>
              <Input
                bsSize="sm"
                type="select"
                name="tmbPerson"
                id="tmbPerson"
                value={form.clientPerson}
                onChange={(e) =>
                  setForm({ ...form, clientPerson: e.target.value })
                }
                disabled={isLoading}
              >
                <option value="">Brak</option>
                {clientPersons?.map(({ id, label }) => (
                  <option key={id} value={id}>
                    {label}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <Button
              className="mt-3"
              color="success"
              onClick={() => handleSaveTicket()}
              disabled={isLoading}
            >
              Zapisz
            </Button>
            <Button
              className="mt-3 ml-3"
              color="danger"
              onClick={() => handleCloseModal()}
              disabled={isLoading}
            >
              Anuluj
            </Button>
          </Form>
        )}
      </Modal>
    </>
  )
}

EditTicketModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setReloadTrue: () => dispatch(setReloadTrue()),
    setGlobalLoading: (component, value) =>
      dispatch(setGlobalLoading({ [component]: value })),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTicketModal)

import React, { useState, useEffect, useCallback } from "react"
import { Switch, Route, useHistory, Redirect } from "react-router-dom"
import { connect } from "react-redux"
import { Card, CardBody, Col, Input, Row } from "reactstrap"
import FalconCardHeader from "components/common/FalconCardHeader"
import ButtonIcon from "components/common/ButtonIcon"
import Breadcrumb from "components/common/Breadcrumb"
import AddAttachment from "components/actionModals/AddAttachment"
import AddLink from "components/actionModals/AddLink"
import EditTicket from "components/actionModals/EditTicketModal"
import AddOffer from "components/actionModals/AddOffer"
import styled from "styled-components"
import TicketChat from "components/common/TicketChat"
import { useQuery } from "@apollo/client"
import { toast } from "react-toastify"
import { LOAD_TICKET, LOAD_TICKET_VISITS } from "GraphQL/Queries"
import { settings } from "config"
import moment from "moment"
import RemoveModal from "components/common/RemoveModal"
import { DELETE, GET, GET_FILE, POST } from "helpers/axios"
import TrashButton from "components/common/TrashButton"
import { reduceId, getId } from "helpers/utils"
import PlanServiceModal from "components/actionModals/PlanServiceModal"
import AddSettlement from "components/actionModals/AddSettlement"
import ServiceDetailsModal from "components/dataModals/ServiceDetailsModal"
import { setGlobalLoading } from "redux/actions/index"
import ServiceNavigation from "./ServiceNavigation"
import SettlementsTable from "components/dataTables/SettlementsTable/SettlementsTable"
import Loader from "components/common/Loader"
import PropTypes from "prop-types"

const ServiceModals = ({
  setShowSettlementModal,
  setShowPlanServiceModal,
  setShowEditTicketModal,
  setShowAddOfferModal,
  setShowAttachmentModal,
  setShowAddLinkModal,
  showSettlementModal,
  showPlanServiceModal,
  showEditTicketModal,
  showAddOfferModal,
  showAttachmentModal,
  showAddLinkModal,
  showPlanDeliveryServiceModal,
  setShowPlanDeliveryServiceModal,
  serviceId,
  ticket,
  refetch,
}) => {
  return (
    <>
      {/* {serviceDetailsModalParams.isOpen && (
        <ServiceDetailsModal
          isOpen={serviceDetailsModalParams.isOpen}
          serviceData={serviceDetailsModalParams.serviceData}
          handleCloseModal={() => {
            setServiceDetailsModalParams({ isOpen: false });
          }}
        />
      )} */}
      {showSettlementModal && (
        <AddSettlement
          isOpen={showSettlementModal}
          title="Dodaj rozliczenie"
          handleCloseModal={() => setShowSettlementModal(false)}
          serviceId={serviceId}
          deviceId={ticket?.deviceId}
        />
      )}
      {showPlanServiceModal && (
        <PlanServiceModal
          isOpen={showPlanServiceModal}
          handleCloseModal={() => setShowPlanServiceModal(false)}
          ticketId={`/api/tickets/${serviceId}`}
          ticketName={`#${serviceId}`}
          deviceId={ticket?.deviceId}
          refetch={refetch}
          description={ticket?.description}
        />
      )}
      {showPlanDeliveryServiceModal && (
        <PlanServiceModal
          isOpen={showPlanDeliveryServiceModal}
          handleCloseModal={() => setShowPlanDeliveryServiceModal(false)}
          ticketId={`/api/tickets/${serviceId}`}
          ticketName={`#${serviceId}`}
          deviceId={ticket?.deviceId}
          refetch={refetch}
          isDelivery
          description={ticket?.description}
        />
      )}
      {/* {showRemoveAttachment.show && (
        <RemoveModal
          title="Usuń załącznik"
          content="Czy na pewno chcesz usunąć załącznik?"
          isOpen={showRemoveAttachment.show}
          cancel={() => setRemoveAttachment({ show: false, id: null })}
          confirm={() => handleRemoveActivity(showRemoveAttachment.id)}
        />
      )} */}
      {/* {showRemoveLink.show && (
        <RemoveModal
          title="Usuń link"
          content="Czy na pewno chcesz usunąć link?"
          isOpen={showRemoveLink.show}
          cancel={() => setRemoveLink({ show: false, id: null })}
          confirm={() => handleRemoveActivity(showRemoveLink.id)}
        />
      )} */}
      {showAttachmentModal && (
        <AddAttachment
          isOpen={showAttachmentModal}
          handleCloseModal={() => setShowAttachmentModal(false)}
          serviceId={serviceId}
          refetch={refetch}
        />
      )}
      {showAddLinkModal && (
        <AddLink
          isOpen={showAddLinkModal}
          handleCloseModal={() => setShowAddLinkModal(false)}
          serviceId={serviceId}
          refetch={refetch}
        />
      )}
      {showAddOfferModal && (
        <AddOffer
          isOpen={showAddOfferModal}
          title="Utwórz ofertę"
          handleCloseModal={() => setShowAddOfferModal(false)}
          serviceId={serviceId}
          deviceId={ticket?.deviceId}
        />
      )}
      {showEditTicketModal && (
        <EditTicket
          isOpen={showEditTicketModal}
          serviceId={serviceId}
          handleCloseModal={() => setShowEditTicketModal(false)}
          ticket={ticket}
          refetch={refetch}
        />
      )}
    </>
  )
}

export default ServiceModals

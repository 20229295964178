export const tmbRoutes = (roles) => [
  {
    name: "Klienci",
    to: "/clients",
    exact: true,
    icon: "building",
  },
  {
    name: "Urządzenia",
    to: "/devices",
    exact: true,
    icon: "print",
  },
  {
    name: "Umowy",
    to: "/agreements",
    exact: true,
    icon: "handshake",
  },
  {
    name: "Serwis",
    to: "/services",
    exact: true,
    icon: "tools",
  },
  {
    name: "Planer serwisu",
    to: "/planner",
    exact: true,
    icon: "tools",
  },
  {
    name: "Wizyty serwisowe",
    to: "/visits",
    exact: true,
    icon: "tools",
  },
  {
    name: "Oferty",
    to: "/offers",
    exact: true,
    icon: "copy",
  },
  {
    name: "CRM",
    to: "/crm",
    exact: true,
    icon: "funnel-dollar",
    disabled: true,
  },
  {
    name: "Produkty",
    to: "/products",
    exact: true,
    icon: "boxes",
  },
  {
    name: "Rozliczenia",
    to: "/settlements",
    exact: true,
    icon: "file-signature",
  },
  {
    name: "Import CSV",
    to: "/csv",
    exact: true,
    icon: "file-import",
  },
  {
    name: "Administracja",
    icon: "lock-open",
    hidden: !roles.includes("ROLE_TMB_ADMIN"),
    children: [
      { to: "/users/client-persons", name: "Użytkownicy" },
      { to: "/couriers", name: "Kurierzy" },
      { to: "/manufacturers", name: "Producenci" },
      { to: "/ticket-topics", name: "Tytuły serwisowe" },
      { to: "/offer-activities", name: "Czynności serwisowe" },
      { to: "/regions", name: "Regiony" },
      { to: "/warehouse", name: "Magazyny" },
    ],
  },
]

export const clientRoutes = (clientId) => [
  {
    name: "Urządzenia",
    to: "/devices",
    exact: true,
    icon: "print",
  },
  {
    name: "Lokalizacje",
    to: `/clients/${clientId}/departments`,
    exact: true,
    icon: "building",
  },
  {
    name: "Osoby",
    to: `/clients/${clientId}/persons`,
    exact: true,
    icon: "id-card",
  },
  {
    name: "Umowy",
    to: "/agreements",
    exact: true,
    icon: "handshake",
  },
  {
    name: "Serwis",
    to: "/services",
    exact: true,
    icon: "tools",
  },
  {
    name: "Wizyty serwisowe",
    to: "/visits",
    exact: true,
    icon: "tools",
  },
  {
    name: "Oferty",
    to: "/offers",
    exact: true,
    icon: "copy",
  },
  {
    name: "Rozliczenia",
    to: "/settlements",
    exact: true,
    icon: "file-signature",
  },
]

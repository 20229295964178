import React, { useState } from "react"
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
} from "reactstrap"
import defaultProfile from "assets/img/default_user.png"
import Avatar from "components/common/Avatar"
import { publicUploads } from "helpers/endpoints"
import { useHistory } from "react-router-dom"
import { useAuthContext } from "context/AuthContext"

const ProfileDropdown = () => {
  const history = useHistory()
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggle = () => setDropdownOpen((prevState) => !prevState)
  const { user, signOut } = useAuthContext()

  return (
    <Dropdown
      nav
      inNavbar
      isOpen={dropdownOpen}
      toggle={toggle}
      onClick={() => {
        let windowWidth = window.innerWidth
        windowWidth > 992 && setDropdownOpen(!dropdownOpen)
      }}
    >
      <DropdownToggle nav className="pr-0">
        <Avatar
          src={user.avatar ? `${publicUploads}/${user.avatar}` : defaultProfile}
        />
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card">
        <div className="bg-white rounded-soft py-2">
          <DropdownItem disabled className="text-center">
            {user.name} {user.surname}
          </DropdownItem>
          <DropdownItem disabled className="text-center">
            {user.email}
          </DropdownItem>
          <hr className="m-2 p-0" />
          <DropdownItem onClick={() => history.push(`/profile`)}>
            Profil
          </DropdownItem>
          <DropdownItem onClick={signOut}>Wyloguj</DropdownItem>
        </div>
      </DropdownMenu>
    </Dropdown>
  )
}

export default ProfileDropdown

import { Navbar } from "../../../views/tmbViews/Offers/OffersNavigation"
import { Nav, NavItem, NavLink } from "reactstrap"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import { useAuthContext } from "../../../context/AuthContext"

const SettlementsNav = ({ onChange, tabs }) => {
  const [activeTab, setActiveTab] = useState(1)
  const { user } = useAuthContext()

  const handleTabChange = (tab) => {
    setActiveTab(tab)
    onChange(tab)
  }

  const renderNavLink = (tabIndex, label) => (
    <NavItem key={tabIndex}>
      <NavLink
        type={Link}
        className={
          activeTab === tabIndex ? "active text-link-blue" : "text-link-blue"
        }
        onClick={() => handleTabChange(tabIndex)}
      >
        {label}
      </NavLink>
    </NavItem>
  )

  return (
    <Navbar className="text-link-blue">
      <Nav>
        {tabs.map((tab, index) =>
          tab.roles.length === 0 ||
          tab.roles.some((role) => user.roles.includes(role))
            ? renderNavLink(index + 1, tab.label)
            : null
        )}
      </Nav>
    </Navbar>
  )
}

export default SettlementsNav

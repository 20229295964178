import React, { useEffect } from "react"
import { usePaginationFetch } from "hooks/usePaginationFetch"
import Filters from "components/common/Filters/Filters"
import Table from "components/Table"
import {
  columns,
  settleStatusesOptions,
  statusesOptions,
} from "./AgreementsTable.config"
import { useDebounce } from "hooks/useDebounce"
import { useAuthContext } from "context/AuthContext"
import { Input } from "reactstrap"
import styled from "styled-components"

export const StyledFilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 1rem;
`

const AgreementsTable = ({
  organizationId,
  searchValue,
  showFilters,
  deviceId,
  isTestDataLoading,
  isDeleteTestDataLoading,
  settling,
}) => {
  const debouncedSearchValue = useDebounce(searchValue, 1000)
  const [selectedStatus, setSelectedStatus] = React.useState("")
  const [selectedSettleStatus, setSelectedSettleStatus] = React.useState(null)
  const [selectedSettleDayRange, setSelectedSettleDayRange] = React.useState(1)
  const { user } = useAuthContext()

  const rowStyle = (row) => {
    if (selectedSettleStatus === "0" || selectedSettleStatus === "1") {
      if (row.isDeviceHistoryUpdated === true) {
        return { backgroundColor: "#91ff83" }
      }
      if (row.isDeviceHistoryUpdated === false) {
        return { backgroundColor: "#ff9d4a" }
      }
    }

    return {}
  }

  const {
    data,
    isLoading,
    totalPages,
    currentPage,
    incrementPage,
    decrementPage,
    setPage,
  } = usePaginationFetch("agreements", {
    params: {
      search: searchValue,
      status: selectedStatus,
      device: deviceId,
      "device.Department.organization": organizationId,
      settlementStatus:
        selectedSettleStatus === "0" || selectedSettleStatus === "2"
          ? selectedSettleStatus
          : null,
      toBeSettledInDays:
        selectedSettleStatus === "1" ? selectedSettleDayRange : null,
    },
    refetchDeps: [
      debouncedSearchValue,
      selectedStatus,
      selectedSettleStatus,
      selectedSettleDayRange,
      deviceId,
      organizationId,
      isTestDataLoading,
      isDeleteTestDataLoading,
      settling,
    ],
    enable404Redirect: true,
  })

  const handleSettleDayRangeChange = ({ target: { value } }) => {
    if (value > 31) {
      setSelectedSettleDayRange(31)
    } else if (value < 0) {
      setSelectedSettleDayRange(1)
    } else setSelectedSettleDayRange(value)
  }

  const handleStatusCheckboxChange = (isChecked, value) => {
    if (isChecked) {
      setSelectedStatus((prevStatus) => [...prevStatus, value])
    } else {
      setSelectedStatus((prevStatus) =>
        prevStatus.filter((status) => status !== value)
      )
    }
  }
  return (
    <>
      <Filters showFilters={showFilters}>
        <StyledFilterContainer>
          <Filters.CheckboxGroup
            label={"Status:"}
            options={statusesOptions}
            onChange={handleStatusCheckboxChange}
          />
          <Filters.Select
            label="Status rozliczenia:"
            options={settleStatusesOptions}
            onChange={(e) => setSelectedSettleStatus(e.target.value)}
            currentValue={selectedSettleStatus}
            isDisabled={isLoading}
          />
          {selectedSettleStatus === "1" && (
            <Input
              type="number"
              value={selectedSettleDayRange}
              style={{ maxWidth: "60px" }}
              onChange={(e) => setSelectedSettleDayRange(e.target.value)}
              onBlur={handleSettleDayRangeChange}
            />
          )}
        </StyledFilterContainer>
      </Filters>
      <Table
        columns={columns(!user.roles.includes("ROLE_TMB"))}
        data={data}
        currentPage={currentPage}
        totalPages={totalPages}
        incrementPage={incrementPage}
        decrementPage={decrementPage}
        setPage={setPage}
        isLoading={isLoading}
        rowStyle={rowStyle}
      />
    </>
  )
}

export default AgreementsTable

import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { Col, Row } from "reactstrap"
import Error404 from "components/errors/Error404"
import Error500 from "components/errors/Error500"
import Section from "components/common/Section"

const ErrorLayout = () => (
  <Section className="py-0">
    <Row className="flex-center min-vh-100 py-6">
      <Col sm={11} md={9} lg={7} xl={6} className="col-xxl-5">
        <Switch>
          <Route path={`/errors/404`} component={Error404} />
          <Route path={`/errors/500`} component={Error500} />
          {/*Redirect*/}
          <Redirect to={`/errors/404`} />
        </Switch>
      </Col>
    </Row>
  </Section>
)

export default ErrorLayout

import { useState } from "react"
import { useAuthContext } from "../context/AuthContext"
import { POST } from "../helpers/axios"

const usePostChangeServiceSettlementStatus = () => {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const { user } = useAuthContext()
  const roles = user.roles

  const isTMBPerson = roles.includes("ROLE_TMB")

  const postChangeStatus = async (id, operation) => {
    setLoading(true)

    const url = isTMBPerson
      ? "workflow/change-ticket-settlement-status"
      : "workflow/change-ticket-settlement-status-to-downloaded"

    try {
      const response = await POST(url, {
        ticket_settlement_id: id,
        operation: operation,
      })

      setData((prev) => ({
        ...prev,
        ...response.data,
      }))
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  return { data, error, loading, postChangeStatus }
}

export default usePostChangeServiceSettlementStatus

import React, { useState, useEffect, useCallback } from "react"
import { Switch, Route, useHistory, Redirect } from "react-router-dom"
import { connect } from "react-redux"
import { Card, CardBody, Col, Input, Row } from "reactstrap"
import FalconCardHeader from "components/common/FalconCardHeader"
import ButtonIcon from "components/common/ButtonIcon"
import Breadcrumb from "components/common/Breadcrumb"
import AddAttachment from "components/actionModals/AddAttachment"
import AddLink from "components/actionModals/AddLink"
import EditTicket from "components/actionModals/EditTicketModal"
import AddOffer from "components/actionModals/AddOffer"
import styled from "styled-components"
import TicketChat from "components/common/TicketChat"
import { useQuery } from "@apollo/client"
import { toast } from "react-toastify"
import { LOAD_TICKET, LOAD_TICKET_VISITS } from "GraphQL/Queries"
import { settings } from "config"
import moment from "moment"
import RemoveModal from "components/common/RemoveModal"
import { DELETE, GET, GET_FILE, POST } from "helpers/axios"
import TrashButton from "components/common/TrashButton"
import { reduceId, getId } from "helpers/utils"
import PlanServiceModal from "components/actionModals/PlanServiceModal"
import AddSettlement from "components/actionModals/AddSettlement"
import ServiceDetailsModal from "components/dataModals/ServiceDetailsModal"
import { setGlobalLoading } from "redux/actions/index"
import ServiceNavigation from "./ServiceNavigation"
import SettlementsTable from "components/dataTables/SettlementsTable/SettlementsTable"
import Loader from "components/common/Loader"
import PropTypes from "prop-types"
import { useAuthContext } from "context/AuthContext"

const ActionButtons = ({
  setShowSettlementModal,
  setShowPlanServiceModal,
  setShowEditTicketModal,
  setShowAddOfferModal,
  setShowAttachmentModal,
  setShowAddLinkModal,
  setShowPlanDeliveryServiceModal,
  ticket,
  btnAnimation,
  serviceId,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { user } = useAuthContext()

  const openPlanServiceModal = async () => {
    setIsSubmitting(true)

    GET(`tickets/${serviceId}/exist-active-service-visit`)
      .then((res) => setShowPlanServiceModal(true))
      .catch((err) => {
        toast.error(
          "Aktywna może być tylko jedna wizyta. Aby zaplanować kolejną wizytę zakończ bieżącą."
        )
      })
      .finally(() => setIsSubmitting(false))
  }

  return user.roles.includes("ROLE_TMB") ? (
    <Row>
      <ButtonIcon
        icon="edit"
        transform="shrink-3 down-2"
        color="falcon-default"
        size="sm"
        onClick={() => setShowSettlementModal(true)}
      >
        Rozliczenie
      </ButtonIcon>
      {ticket?.status === "service_visit_planned" && (
        <ButtonIcon
          icon="edit"
          transform="shrink-3 down-2"
          color="falcon-default"
          animation={btnAnimation}
          disabled={isSubmitting}
          size="sm"
          onClick={openPlanServiceModal}
        >
          Planowanie serwisu
        </ButtonIcon>
      )}
      {ticket?.status === "service_visit_planned" && (
        <ButtonIcon
          icon="edit"
          transform="shrink-3 down-2"
          color="falcon-default"
          animation={btnAnimation}
          size="sm"
          onClick={() => setShowPlanDeliveryServiceModal(true)}
        >
          Obsługa kurierem
        </ButtonIcon>
      )}
      {ticket?.status !== "ended" && (
        <ButtonIcon
          icon="edit"
          transform="shrink-3 down-2"
          color="falcon-default"
          size="sm"
          onClick={() => setShowEditTicketModal(true)}
        >
          Edytuj
        </ButtonIcon>
      )}
      <ButtonIcon
        icon="edit"
        transform="shrink-3 down-2"
        color="falcon-default"
        size="sm"
        onClick={() => setShowAddOfferModal(true)}
      >
        Oferta serwisowa
      </ButtonIcon>
      <ButtonIcon
        icon="paperclip"
        transform="shrink-3 down-2"
        color="falcon-default"
        size="sm"
        onClick={() => setShowAttachmentModal(true)}
      >
        Załącznik
      </ButtonIcon>
      <ButtonIcon
        icon="link"
        transform="shrink-3 down-2"
        color="falcon-default"
        size="sm"
        onClick={() => setShowAddLinkModal(true)}
      >
        Link
      </ButtonIcon>
    </Row>
  ) : null
}

export default ActionButtons

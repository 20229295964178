import React from "react"
import { FormGroup, Input, Label } from "reactstrap"

const Select = ({
  label,
  options,
  onChange,
  currentValue,
  isDisabled,
  placeholder,
}) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Label className="m-0 mr-2">{label}</Label>
      <FormGroup className="m-0 mr-2">
        <Input
          bsSize="sm"
          type="select"
          disabled={isDisabled}
          placeholder={placeholder}
          value={currentValue}
          onChange={onChange}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Input>
      </FormGroup>
    </div>
  )
}

export default Select

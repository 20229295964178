import React from "react"
import PropTypes from "prop-types"
import { Card, CardBody, Col, Row } from "reactstrap"
import Flex from "components/common/Flex"
import styled from "styled-components"

const StyledCardWrapper = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
`

const AuthCardLayout = ({ leftSideContent, children }) => {
  return (
    <StyledCardWrapper>
      <Card className="overflow-hidden z-index-1" style={{ width: "350px" }}>
        <CardBody className="p-0">
          <Row noGutters className="h-100">
            <Col tag={Flex} align="center" justify="center">
              <div className="p-4 p-md-5 flex-grow-1">{children}</div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </StyledCardWrapper>
  )
}
AuthCardLayout.propTypes = {
  leftSideContent: PropTypes.node,
  children: PropTypes.node.isRequired,
}

export default AuthCardLayout

export const navbarBreakPoint = "xl" // Vertical navbar breakpoint
export const topNavbarBreakpoint = "lg"
export const agreementTypes = {
  tradeRental: 0, //Wynajmu
  tradeService: 1, //Obsługi
  tradeServiceRental: 2, //Serwisowa
  service: 3, //Naprawy serwisowej - z ticketu
}
export const plannerColorClasses = [
  "planner-gold",
  "planner-blue",
  "planner-violet",
  "planner-red",
  "planner-brown",
  "planner-lightblue",
  "planner-darkblue",
  "planner-cyan",
  "planner-green",
  "planner-lightgray",
]
export const settings = {
  isFluid: false,
  isRTL: false,
  isDark: false,
  isTopNav: false,
  isVertical: true,
  get isCombo() {
    return this.isVertical && this.isTopNav
  },
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: "zł",
  isNavbarVerticalCollapsed: false,
  navbarStyle: "transparent",
  dateFormat: "DD-MM-YYYY",
  dateTimeFormat: "DD-MM-YYYY, HH:mm",
  nameDayFormat: "DD-MM",
  monthFormat: "MM-YYYY",
  dateFormatPicker: "dd-MM-yyyy",
  dateTimeFormatPicker: "dd-MM-yyyy, HH:mm",
}
export default { navbarBreakPoint, topNavbarBreakpoint, settings }

import React from "react"
import { Link } from "react-router-dom"
import moment from "moment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { parseTableColumnsConditions } from "../../helpers/utils"

export const handleShowOfferType = (type, showNotSentIndicator) => {
  switch (type) {
    case 1:
      return (
        <span>
          Oferta naprawy serwisowej{" "}
          {showNotSentIndicator && (
            <FontAwesomeIcon icon="exclamation" color="red" />
          )}
        </span>
      )
    case 2:
      return "Wycena kosztów naprawy"
  }
}

export const statusesOptions = [
  { value: "", label: "Wszystkie" },
  { value: "pending", label: "Oczekuje na akceptację" },
  { value: "client_accept", label: "Akceptacja klienta" },
  { value: "client_rejection", label: "Odrzucenie klienta" },
  { value: "overdue", label: "Oferta przeterminowana" },
  { value: "ended", label: "Zakończone" },
]

export const columns = (columnsHiddenConditions, hideOrganization) => {
  const hiddenColumns = parseTableColumnsConditions(columnsHiddenConditions)

  return [
    {
      dataField: "name",
      text: "Oferta",
      classes: "border-0 align-middle",
      headerClasses: "border-0",
      formatter: (name, row) => {
        return (
          <Link
            to={`/offers/${row.id}`}
            className="font-weight-semi-bold text-link-blue"
          >
            {name}
          </Link>
        )
      },
    },
    {
      dataField: "ticket",
      text: "Organizacja",
      classes: "border-0 align-middle",
      headerClasses: "border-0",
      formatter: (ticket) => {
        return (
          <Link
            to={`/clients/${ticket.organization.id}/details`}
            className="font-weight-semi-bold text-link-blue"
          >
            {ticket.organization.name}
          </Link>
        )
      },
      hidden: hideOrganization || hiddenColumns.includes("client"),
    },
    {
      dataField: "ticket",
      text: "Odbiorca usługi",
      classes: "border-0 align-middle",
      headerClasses: "border-0",
      formatter: (ticket) => {
        return ticket.organization.recName
      },
    },
    {
      dataField: "ticket",
      text: "Urządzenie",
      classes: "border-0 align-middle",
      headerClasses: "border-0",
      formatter: (ticket) => {
        return (
          <Link
            to={`/devices/${ticket.device.id}/details`}
            className="font-weight-semi-bold text-link-blue"
          >
            {ticket.device.warehausProduct.model}
          </Link>
        )
      },
    },
    {
      dataField: "ticket",
      text: "Numer seryjny",
      classes: "border-0 align-middle",
      headerClasses: "border-0",
      formatter: (ticket) => {
        return (
          <Link
            to={`/devices/${ticket.device.id}/details`}
            className="font-weight-semi-bold text-link-blue"
          >
            {ticket.device.serialNumber}
          </Link>
        )
      },
      hidden: hiddenColumns.includes("deviceSerialNumber"),
    },
    {
      dataField: "type",
      text: "Rodzaj oferty",
      classes: "border-0 align-middle",
      headerClasses: "border-0",
      formatter: (type, row) => {
        console.log(row)
        return handleShowOfferType(type, !row.emailSent)
      },
    },
    {
      dataField: "offerExpirationDate",
      text: "Data wygaśnięcia",
      classes: "border-0 align-middle",
      headerClasses: "border-0",
      formatter: (offerExpirationDate) => {
        return moment(offerExpirationDate).format("DD.MM.YYYY")
      },
    },
    {
      dataField: "label",
      text: "Status",
      classes: "border-0 align-middle",
      headerClasses: "border-0",
    },
  ]
}

import React, { useState, useEffect, useCallback } from "react"
import { Switch, Route, useHistory, Redirect } from "react-router-dom"
import { connect } from "react-redux"
import { Card, CardBody, Col, Input, Row } from "reactstrap"
import FalconCardHeader from "components/common/FalconCardHeader"
import ButtonIcon from "components/common/ButtonIcon"
import Breadcrumb from "components/common/Breadcrumb"
import AddAttachment from "components/actionModals/AddAttachment"
import AddLink from "components/actionModals/AddLink"
import EditTicket from "components/actionModals/EditTicketModal"
import AddOffer from "components/actionModals/AddOffer"
import styled from "styled-components"
import TicketChat from "components/common/TicketChat"
import { useQuery } from "@apollo/client"
import { toast } from "react-toastify"
import { LOAD_TICKET, LOAD_TICKET_VISITS } from "GraphQL/Queries"
import { settings } from "config"
import moment from "moment"
import RemoveModal from "components/common/RemoveModal"
import { DELETE, GET, GET_FILE, POST } from "helpers/axios"
import TrashButton from "components/common/TrashButton"
import { reduceId, getId } from "helpers/utils"
import PlanServiceModal from "components/actionModals/PlanServiceModal"
import AddSettlement from "components/actionModals/AddSettlement"
import ServiceDetailsModal from "components/dataModals/ServiceDetailsModal"
import { setGlobalLoading } from "redux/actions/index"
import ServiceNavigation from "./ServiceNavigation"
import SettlementsTable from "components/dataTables/SettlementsTable/SettlementsTable"
import Loader from "components/common/Loader"
import PropTypes from "prop-types"

import ServiceDetails from "./ServiceDetails"
import ServiceVisits from "./ServiceVisits"
import ActionButtons from "./ActionButtons"
import ServiceModals from "./ServiceModals"
import VisitsTable from "components/dataTables/VisitsTable"
import { newServiceSettlementsTabs } from "../../../components/dataTables/SettlementsTable/config/tabs"

const NewService = ({
  match: {
    params: { serviceId },
  },
  location: { pathname },
}) => {
  const [ticket, setTicket] = useState(null)
  const [activityLogs, setActivityLogs] = useState(null)
  const [pageTitle, setPageTitle] = useState("")
  const [attachments, setAttachments] = useState([])
  const [links, setLinks] = useState([])
  const [showSettlementModal, setShowSettlementModal] = useState(false)
  const [showPlanServiceModal, setShowPlanServiceModal] = useState(false)
  const [showPlanDeliveryServiceModal, setShowPlanDeliveryServiceModal] =
    useState(false)
  const [showEditTicketModal, setShowEditTicketModal] = useState(false)
  const [showAddOfferModal, setShowAddOfferModal] = useState(false)
  const [showAttachmentModal, setShowAttachmentModal] = useState(false)
  const [showAddLinkModal, setShowAddLinkModal] = useState(false)
  const [btnAnimation, setBtnAnimation] = useState(false)

  const [loading, setLoading] = useState({
    ticket: false,
    activityLogs: false,
  })

  const breadcrumbLinks = [
    { name: "TMB", to: "/" },
    { name: "Serwis", to: "/services" },
    { name: ticket?.name, to: `/services/${ticket?.id}` },
  ]

  const fetchTicket = () => {
    setLoading((prev) => ({ ...prev, ticket: true }))

    GET(`tickets/${serviceId}`)
      .then(({ data }) => {
        setPageTitle(data.title)

        setTicket({
          name: data.name || "",
          id: data.id || "",
          description: data.description || "",
          status: data.status || "",
          label: data.label || "",
          endedUp: data.endedUp || null,
          manufacturer: data.device?.warehausProduct?.manufacturer?.name || "",
          model: data.device?.warehausProduct?.model || "",
          deviceId: data.device?.id || "",
          serialNumber: data.device?.serialNumber || "",
          date: data.createdAt ? new Date(data.createdAt) : "",
          clientPerson: `${data?.clientPerson?.user?.name || ""} ${
            data?.clientPerson?.user?.surname || ""
          }`,
          clientPersonId: data?.clientPerson
            ? getId(data?.clientPerson["@id"])
            : "",
          tmbPerson: `${data?.tmbPerson?.user?.name || ""} ${
            data?.tmbPerson?.user?.surname || ""
          }`,
          tmbPersonId: data?.tmbPerson ? getId(data?.tmbPerson["@id"]) : "",
          author: `${data?.author?.name || ""} ${data?.author?.surname || ""}`,
          department: data?.department?.name || "",
          departmentId: data?.department?.id || "",
          organizationId: data?.organization?.id || "",
          organization: data?.organization?.name || "",
          activeAgreement: data?.device?.activeAgreement || null,
        })
      })
      .catch((err) => {
        toast.error("Wystąpił błąd pobierania danych.")
        if (err.response.status == 404)
          return (window.location.href = "/errors/404")
      })
      .finally(() => setLoading((prev) => ({ ...prev, ticket: false })))
  }

  const fetchActivityLogs = () => {
    setLoading((prev) => ({ ...prev, activityLogs: true }))

    GET(`activity_logs?ticket=${serviceId}&pagination=false`)
      .then(({ data }) => {
        setActivityLogs(data["hydra:member"])

        setAttachments(
          data["hydra:member"]
            .filter((activityLog) => activityLog.attachment)
            .map(({ attachment, id }) => ({
              activityId: id,
              file: attachment.file,
              title: attachment.title,
            }))
        )

        setLinks(
          data["hydra:member"]
            .filter((activityLog) => activityLog.link)
            .map(({ link, id }) => ({
              activityId: id,
              linkAddress: link.linkAddress,
              title: link.title,
            }))
        )
      })
      .catch(() => {
        toast.error("Wystąpił błąd pobierania danych.")
      })
      .finally(() => setLoading((prev) => ({ ...prev, activityLogs: false })))
  }

  React.useEffect(() => {
    fetchTicket()
  }, [serviceId])

  React.useEffect(() => {
    fetchActivityLogs()
  }, [serviceId])

  const refetch = () => {
    fetchTicket()
    fetchActivityLogs()
  }

  const handleRemoveActivity = (id) => {
    setLoading((prev) => ({ ...prev, activityLogs: true }))

    DELETE(`/api/activity_logs/${id}`)
      .then((res) => {
        refetch()
      })
      .catch((err) => {
        toast.error("Wystąpił błąd")
      })
      .finally(() => setLoading((prev) => ({ ...prev, activityLogs: false })))
  }

  return (
    <>
      <ServiceModals
        setShowSettlementModal={setShowSettlementModal}
        setShowPlanServiceModal={setShowPlanServiceModal}
        setShowEditTicketModal={setShowEditTicketModal}
        setShowAddOfferModal={setShowAddOfferModal}
        setShowAttachmentModal={setShowAttachmentModal}
        setShowAddLinkModal={setShowAddLinkModal}
        showSettlementModal={showSettlementModal}
        showPlanServiceModal={showPlanServiceModal}
        showEditTicketModal={showEditTicketModal}
        showAddOfferModal={showAddOfferModal}
        showAttachmentModal={showAttachmentModal}
        showAddLinkModal={showAddLinkModal}
        showPlanDeliveryServiceModal={showPlanDeliveryServiceModal}
        setShowPlanDeliveryServiceModal={setShowPlanDeliveryServiceModal}
        serviceId={serviceId}
        ticket={ticket}
        refetch={refetch}
      />
      <Card>
        <Breadcrumb links={breadcrumbLinks} />
        <FalconCardHeader title={pageTitle} light={false} className="pt-0">
          <ActionButtons
            ticket={ticket}
            setShowSettlementModal={setShowSettlementModal}
            setShowPlanServiceModal={setShowPlanServiceModal}
            setShowEditTicketModal={setShowEditTicketModal}
            setShowAddOfferModal={setShowAddOfferModal}
            setShowAttachmentModal={setShowAttachmentModal}
            setShowAddLinkModal={setShowAddLinkModal}
            setShowPlanDeliveryServiceModal={setShowPlanDeliveryServiceModal}
            btnAnimation={btnAnimation}
            serviceId={serviceId}
          />
        </FalconCardHeader>
        <ServiceNavigation location={pathname} serviceId={serviceId} />
      </Card>
      <Switch>
        <Route path={`/services/${serviceId}/details`} exact>
          <ServiceDetails
            ticket={ticket}
            attachments={attachments}
            links={links}
            handleRemoveActivity={handleRemoveActivity}
            isLoading={loading.ticket || loading.activityLogs}
            activityLogs={activityLogs}
            serviceId={serviceId}
            refetch={refetch}
            setBtnAnimation={setBtnAnimation}
          />
        </Route>
        <Route path={`/services/${serviceId}/visits`} exact>
          <Card className="mb-3">
            <CardBody className="p-0 pb-3">
              <VisitsTable serviceId={serviceId} />
            </CardBody>
          </Card>
        </Route>
        <Route path={`/services/${serviceId}/settlements`} exact>
          <Card className="mb-3">
            <CardBody className="p-0 pb-3">
              <SettlementsTable
                tabs={newServiceSettlementsTabs}
                ticketId={serviceId}
              />
            </CardBody>
          </Card>
        </Route>
        <Redirect to={`/services/${serviceId}/details`} />
      </Switch>
    </>
  )
}

export default NewService

import React from "react"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"
import { GET_FILE } from "../../helpers/axios"
import { parseTableColumnsConditions } from "../../helpers/utils"
import Badge, { getStatusColor } from "../common/Badge"

const getTicketSettlement = (ticketSettlements) => {
  if (ticketSettlements.length > 0) {
    return ticketSettlements[ticketSettlements.length - 1]?.ticketSettlement
  }

  return null
}

export const columns = (columnsHiddenConditions, hideOrganization) => {
  const hiddenColumns = parseTableColumnsConditions(columnsHiddenConditions)

  return [
    {
      dataField: "name",
      text: "Wizyta",
      formatter: (name, { id, repeatVisit, deviceBroken }) => (
        <>
          <Link
            to={`/visits/${id}`}
            className="font-weight-semi-bold text-link-blue text-link-blue"
          >
            {name}
          </Link>
          <br />
          {repeatVisit && (
            <label
              title="Wizyta wymagała ponownego przyjazdu"
              style={{ marginRight: "5px" }}
            >
              <FontAwesomeIcon icon={"sync"} />
            </label>
          )}
          {deviceBroken && (
            <label title="Urządzenie popsute">
              <FontAwesomeIcon icon={"tools"} />
            </label>
          )}
        </>
      ),
      classes: "border-0 align-middle",
      headerClasses: "border-0",
    },
    {
      dataField: "organization",
      text: "Organizacja",
      formatter: (_, row) => (
        <Link
          to={`/clients/${row.ticket?.organization?.id}`}
          className="font-weight-semi-bold text-link-blue text-link-blue"
        >
          {row.ticket?.organization?.name}
        </Link>
      ),
      classes: "border-0 align-middle",
      headerClasses: "border-0",
      hidden: hideOrganization || hiddenColumns.includes("organization"),
    },
    {
      dataField: "department",
      text: "Lokalizacja",
      formatter: (_, row) => (
        <Link
          to={`/clients/${row.ticket?.organization?.id}/department/${row.ticket?.department?.id}/details`}
          className="font-weight-semi-bold text-link-blue text-link-blue"
        >
          {row.ticket?.department?.name}
        </Link>
      ),
      classes: "border-0 align-middle",
      headerClasses: "border-0",
    },
    {
      dataField: "ticket.device.warehausProduct.model",
      text: "Model",
      classes: "border-0 align-middle",
      headerClasses: "border-0",
    },
    {
      dataField: "ticket.device.serialNumber",
      text: "Numer seryjny",
      formatter: (serialNumber, row) => {
        return (
          <Link
            to={`/devices/${row.ticket?.device?.id}/details`}
            className="font-weight-semi-bold text-link-blue"
          >
            {serialNumber}
          </Link>
        )
      },
      classes: "border-0 align-middle",
      headerClasses: "border-0",
      hidden: hiddenColumns.includes("ticket.device.serialNumber"),
    },
    {
      dataField: "datetimeStart",
      text: "Data wizyty",
      formatter: (datetimeStart) =>
        datetimeStart ? moment(datetimeStart).format("DD.MM.YYYY") : "--",
      classes: "border-0 align-middle",
      headerClasses: "border-0",
    },
    {
      dataField: "type",
      text: "Rodzaj wizyty",
      formatter: (type) => formatTypeMessage(type),
      classes: "border-0 align-middle",
      headerClasses: "border-0",
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (status, { tmbPerson }) => {
        const { bgColor, color } = getStatusColor(status, !tmbPerson)
        return (
          <Badge bgColor={bgColor} color={color}>
            {formatStatusMessage(status)}
          </Badge>
        )
      },
      classes: "border-0 align-middle",
      headerClasses: "border-0",
    },
    {
      dataField: "ticketSettlementDetails",
      text: "RW/F-ra",
      classes: "border-0 align-middle",
      headerClasses: "border-0",
      formatter: (ticketSettlementDetails) => {
        const ticketSettlement = getTicketSettlement(ticketSettlementDetails)

        return ticketSettlement ? (
          <div>
            <Link
              to={`/settlements/${ticketSettlement.id}`}
              className="font-weight-semi-bold text-link-blue"
            >
              {ticketSettlement.attachmentNameSubiekt}
            </Link>
            <FontAwesomeIcon
              icon="download"
              transform="shrink-4 down-1"
              className="mr-1"
              style={{ cursor: "pointer" }}
              onClick={() => {
                GET_FILE(ticketSettlement.attachment)
              }}
            />
          </div>
        ) : (
          <p style={{ textAlign: "center" }}>--</p>
        )
      },
    },
  ]
}

export const statusesOptions = [
  { label: "Wszystkie", value: "" },
  { label: "Serwis do zaplanowania", value: "planning" },
  { label: "Serwis zaplanowany", value: "planned" },
  { label: "Serwis w trakcie", value: "service_visit_during" },
  { label: "Serwis zakończony", value: "done" },
  { label: "Akceptacja klienta", value: "client_accept" },
  { label: "Odrzucenie klienta", value: "client_rejection" },
  { label: "Odmowa", value: "refusal" },
  { label: "Rozliczona", value: "settled" },
]

export const formatStatusMessage = (status) =>
  statusesOptions.find((option) => option.value === status)?.label

export const typesOptions = [
  { label: "Wszystkie", value: "" },
  { label: "Wizyta serwisowa", value: 1 },
  { label: "Obsługa kurierem", value: 2 },
  { label: "Wizyta okresowa", value: 3 },
]

export const formatTypeMessage = (type) => {
  switch (type) {
    case 1:
      return "Wizyta serwisowa"
    case 2:
      return "Obsługa kurierem"
    case 3:
      return "Wizyta okresowa"
  }
}

import React from "react"
import { GET } from "helpers/axios"
import { toast } from "react-toastify"
import { API } from "helpers/endpoints"

export const formatParams = (options, paramsToSkipEncode = []) => {
  return Object.entries(options).reduce((acc, [key, value]) => {
    if (Array.isArray(value)) {
      return (
        acc +
        value.reduce(
          (acc2, val) => acc2 + `${key}[]=${encodeURIComponent(val)}&`,
          ""
        )
      )
    } else {
      return value
        ? acc +
            `${key}=${
              paramsToSkipEncode.includes(key)
                ? value
                : encodeURIComponent(value)
            }&`
        : acc
    }
  }, "")
}

export const usePaginationFetch = (url, options) => {
  const [data, setData] = React.useState(null)
  const [currentPage, setCurrentPage] = React.useState(1)
  const [totalPages, setTotalPages] = React.useState(1)
  const [isLoading, setIsLoading] = React.useState(true)

  const fetchPage = async (page) => {
    setIsLoading(true)
    const urlWithParams = `${url}?${formatParams(
      { ...options.params, page },
      options.paramsToSkipEncode
    )}`

    try {
      const response = await GET(urlWithParams)

      const hydraView = response.data["hydra:view"]

      const lastPageUrl = hydraView
        ? hydraView["hydra:last"]
        : `${API}/${url}?page=1`

      const [_, params] = lastPageUrl ? lastPageUrl.split("?") : [null, false]

      const data = response.data["hydra:member"]
      const totalPagesParam = params
        ? Number(new URLSearchParams(params).get("page"))
        : 1

      setData(options.organizeData ? options.organizeData(data) : data)
      setTotalPages(totalPagesParam)

      if (page > totalPages) setCurrentPage(totalPagesParam)
    } catch (err) {
      toast.error("Wystąpił błąd pobierania danych")
      if (options.enable404Redirect && err.response.status == 404)
        return (window.location.href = "/errors/404")
    }

    setIsLoading(false)
  }

  React.useEffect(() => {
    setCurrentPage(1)
    fetchPage(1)
  }, [...options.refetchDeps])

  const incrementPage = () => {
    const nextPage = currentPage + 1
    setCurrentPage(nextPage)
    return fetchPage(nextPage)
  }

  const decrementPage = () => {
    const prevPage = currentPage - 1
    setCurrentPage(prevPage)
    return fetchPage(prevPage)
  }

  const setPage = (page) => {
    setCurrentPage(page)
    return fetchPage(page)
  }

  return {
    data,
    currentPage,
    totalPages,
    isLoading,
    incrementPage,
    decrementPage,
    setPage,
  }
}

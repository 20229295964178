import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

// const check = `\2713`

const StyledColorElement = styled.div`
  margin-right: 0.3rem;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`

const PlannerColorPicker = ({ values, onSelect, selectedValue }) => {
  return (
    <StyledContainer>
      {values.map((value, index) => (
        <StyledColorElement
          key={`${value}${index}`}
          className={value}
          onClick={() => onSelect(value)}
          selected={value === selectedValue}
        >
          {value === selectedValue && (
            <FontAwesomeIcon
              icon="check"
              transform="shrink-5"
              style={{ color: "#fff" }}
            />
          )}
        </StyledColorElement>
      ))}
    </StyledContainer>
  )
}

export default PlannerColorPicker

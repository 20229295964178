import React, { useState } from "react"
import { usePaginationFetch } from "hooks/usePaginationFetch"
import Filters from "components/common/Filters/Filters"
import Table from "components/Table"
import {
  columnsConfig,
  columnsConfigAgreementsSettlements,
  columnsConfigSettlementsRW,
  columnsConfigSettlementsService,
} from "./config/columns.config"
import { useDebounce } from "hooks/useDebounce"
import { useAuthContext } from "context/AuthContext"
import { filters } from "./config/filters.config"
import useGetStatusList from "../../../hooks/useGetStatusList"
import useGetCounterpartyList from "../../../hooks/useGetCounterpartyList"
import { StyledFilterContainer } from "../AgreementsTable"
import SettlementsNav from "./SettlementsNav"
import { getDefaultDateRange } from "../../common/Filters/DateRagePicker/DateRangePicker"

const SettlementsTable = ({
  searchValue = "",
  ticketId,
  organizationId,
  showFilters = false,
  tabs = [],
}) => {
  const debouncedSearchValue = useDebounce(searchValue, 1000)
  const [selectedOptions, setSelectedOptions] = useState({})
  const [selectDate, setSelectDate] = useState({})
  const debouncedSelectedOptions = useDebounce(selectDate, 1000)
  const [statusOption, setStatusOption] = useState("")
  const [tab, setTab] = useState(1)

  const { user } = useAuthContext()
  const roles = user.roles
  const isTMBPerson = roles.includes("ROLE_TMB")
  const {
    statusList,
    error: statusListErr,
    loading: isStatusListLoading,
    refetch: statusListRefetch,
  } = useGetStatusList()
  const {
    counterpartyList,
    error: counterpartyListErr,
    loading: isCounterpartyListLoading,
    refetch: counterpartyListRefetch,
  } = useGetCounterpartyList()

  const defaultDateRange = getDefaultDateRange()
  const url =
    tab === 3
      ? "agreement_settlement_details/excluding_unsettled"
      : "ticket_settlements"

  const setParams = () => {
    const baseQuery = {
      search: searchValue,
      status: selectedOptions.status?.option,
      "ticket.organization": selectedOptions.organization?.option,
    }

    if (ticketId || organizationId) {
      return {
        ...baseQuery,
        ticket: ticketId,
        "ticket.organization": organizationId,
        type: tab,
      }
    }

    const isValidDate = (dateStr) => /\d{4}-\d{2}-\d{2}/.test(dateStr)

    const dateRange = {
      end: selectDate.end?.option || `${defaultDateRange[1]} 24:00`,
      start: selectDate.start?.option || `${defaultDateRange[0]} 00:00`,
    }

    const commonDateQuery = {}
    if (isValidDate(dateRange.start)) {
      commonDateQuery["createdAt[after]"] = dateRange.start
    }
    if (isValidDate(dateRange.end)) {
      commonDateQuery["createdAt[before]"] = dateRange.end
    }

    if (tab === 3) {
      return {
        ...baseQuery,
        "agreement.organization": selectedOptions.organization?.option,
        ...commonDateQuery,
      }
    }

    return {
      ...baseQuery,
      type: tab,
      ...commonDateQuery,
    }
  }

  const {
    data,
    isLoading,
    totalPages,
    currentPage,
    incrementPage,
    decrementPage,
    setPage,
  } = usePaginationFetch(url, {
    params: setParams(),
    refetchDeps: [
      debouncedSearchValue,
      debouncedSelectedOptions,
      selectedOptions,
      ticketId,
      tab,
    ],
    enable404Redirect: true,
  })

  const handleChange = (e, filterName) => {
    const value = e.target.value
    setSelectedOptions((prev) => ({
      ...prev,
      [filterName]: {
        option: value,
      },
    }))
  }

  const handleDateChange = (range) => {
    setSelectDate((prev) => ({
      ...prev,
      start: {
        option: range[0] + " " + "00:00",
      },
      end: {
        option: range[1] + " " + "24:00",
      },
    }))
  }

  const handleChangeTab = (tab) => {
    setTab(tab)
  }

  const setColumns = () => {
    if (ticketId) {
      return columnsConfig
    } else if (tab === 1) {
      return columnsConfigSettlementsService
    } else if (tab === 2 && user.roles.includes("ROLE_TMB")) {
      return columnsConfigSettlementsRW
    } else if (tab === 3) {
      return columnsConfigAgreementsSettlements
    }
  }

  return (
    <>
      {showFilters && (
        <Filters showFilters={true}>
          <StyledFilterContainer>
            {filters.map((filter) => {
              if (filter.type === "dateRange") {
                return (
                  <Filters.DateRangePicker
                    key={filter?.name || ""}
                    label={filter?.label || ""}
                    onChange={(range) => handleDateChange(range)}
                    isDisabled={isLoading}
                  />
                )
              }

              if (filter.name === "organization" && isTMBPerson) {
                return (
                  <Filters.Select
                    key={filter?.name || ""}
                    label={filter?.label || ""}
                    isDisabled={isCounterpartyListLoading}
                    options={[
                      {
                        value: "",
                        label: "Wszystkie",
                      },
                      ...(counterpartyList || []),
                    ]}
                    onChange={(e) => handleChange(e, filter.name)}
                    currentValue={
                      selectedOptions[filter.name]?.option ||
                      filter?.options[0]?.value ||
                      ""
                    }
                  />
                )
              }

              if (filter.name === "status" && tab !== 2) {
                return (
                  <Filters.Select
                    key={filter?.name || ""}
                    label={filter?.label || ""}
                    placeholder={"test"}
                    options={[
                      {
                        value: "",
                        label: "Wszystkie",
                      },
                      ...(statusList || []),
                    ]}
                    onChange={(e) => handleChange(e, filter.name)}
                    currentValue={
                      selectedOptions[filter.name]?.option ||
                      filter?.options[0]?.value ||
                      ""
                    }
                    isDisabled={isLoading}
                  />
                )
              }

              return null
            })}
          </StyledFilterContainer>
        </Filters>
      )}
      {tabs.length > 0 && (
        <SettlementsNav onChange={(tab) => handleChangeTab(tab)} tabs={tabs} />
      )}
      <Table
        columns={setColumns()}
        data={data}
        currentPage={currentPage}
        totalPages={totalPages}
        incrementPage={incrementPage}
        decrementPage={decrementPage}
        setPage={setPage}
        isLoading={isLoading}
      />
    </>
  )
}

export default SettlementsTable

import { useState } from "react"
import { POST } from "../helpers/axios"

const usePostServicePaidButton = () => {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  const postServicePaid = async (id) => {
    setLoading(true)

    const url = "workflow/get-ticket-settlement-status"

    try {
      const response = await POST(url, {
        ticket_settlement_id: id,
      })

      setData((prev) => ({
        ...prev,
        ...response.data[0],
      }))

      return response.data
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  return { data, error, loading, postServicePaid }
}

export default usePostServicePaidButton

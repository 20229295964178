import React from "react"
import styled from "styled-components"
import { Nav, NavItem, NavLink } from "reactstrap"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"

const Navbar = styled.div`
  background-color: #f4f7ff;
  border-top: 1px solid #d8e2ee;
  border-bottom: 1px solid #d8e2ee;

  .active {
    border-bottom: 2px solid #f01c25;
  }
  a {
    text-decoration: none;
  }
`

const ServiceNavigation = ({ location, serviceId }) => {
  return (
    <Navbar className="text-link-blue">
      <Nav>
        <NavItem>
          <NavLink
            tag={Link}
            to={`details`}
            className={
              location === `/services/${serviceId}/details`
                ? "active text-link-blue"
                : "text-link-blue"
            }
          >
            Szczegóły
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            tag={Link}
            to={`visits`}
            className={`
              ${
                location === `/services/${serviceId}/visits`
                  ? "active text-link-blue"
                  : "text-link-blue"
              }
            `}
          >
            Wizyty serwisowe
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            tag={Link}
            to={`settlements`}
            className={`
            ${
              location === `/services/${serviceId}/settlements`
                ? "active text-link-blue"
                : "text-link-blue"
            }
          `}
          >
            Rozliczenia
          </NavLink>
        </NavItem>
      </Nav>
    </Navbar>
  )
}

ServiceNavigation.propTypes = {
  location: PropTypes.string.isRequired,
  serviceId: PropTypes.string.isRequired,
}

export default ServiceNavigation

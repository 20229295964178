import React, { useEffect, useState } from "react"
import { FormGroup, Label } from "reactstrap"
import { toast } from "react-toastify"
import TrashButton from "./TrashButton"
import styled from "styled-components"
import { connect } from "react-redux"
import { setGlobalLoading } from "redux/actions"
import { GET } from "helpers/axios"

const ProductRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 65px 95px auto;
  gap: 5px;
  margin-bottom: 5px;
`

const StyledOption = styled.div`
  font-size: 16px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  color: ${({ disabled }) => (disabled ? "#e63737" : "inherit")};
`

const InputWrapper = styled.div`
  width: 100%;
  max-width: 100%;
`

const OverflowInput = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 150px;
  border: 1px solid #d8e2ef;
  border-radius: 0.25rem;
  padding: 0.3125rem 1rem;
  width: 100%;
  max-width: 85vw;
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
`

const ActivitiesSelector = ({ selectedActivities, setSelectedActivities }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [serviceTitles, setServiceTitles] = useState([])

  useEffect(() => {
    setIsLoading(true)

    GET(`service_titles?type=toDo&pagination=false`)
      .then(({ data }) => {
        setServiceTitles(data["hydra:member"])
      })
      .catch((err) => toast.error("Nieudane pobieranie danych"))
      .finally(() => setIsLoading(false))
  }, [])

  const selectActivity = (name) => {
    if (selectedActivities.some((el) => el === name)) return

    setSelectedActivities((prevState) => [...prevState, name])
  }

  const unselectActivity = (name) => {
    setSelectedActivities((prevState) => prevState.filter((el) => el !== name))
  }

  return (
    <>
      <FormGroup>
        <Label for="clientPersonNA">Dostępne czynności</Label>
        <InputWrapper>
          <OverflowInput>
            {serviceTitles.map((el) => (
              <StyledOption
                key={el.id}
                onClick={() => selectActivity(el.title)}
                value={el.id}
              >
                {el.title}
              </StyledOption>
            ))}
          </OverflowInput>
        </InputWrapper>
      </FormGroup>
      <FormGroup>
        <Label for="clientPerson">Wybrane czynności:</Label>
        {!selectedActivities.length ? (
          <p>Nie wybrano czynności</p>
        ) : (
          selectedActivities.map((el) => (
            <ProductRow key={el}>
              <span>{el}</span>
              <TrashButton onClick={() => unselectActivity(el)} />
            </ProductRow>
          ))
        )}
      </FormGroup>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setGlobalLoading: (component, value) =>
      dispatch(setGlobalLoading({ [component]: value })),
  }
}
export default connect(null, mapDispatchToProps)(ActivitiesSelector)

import React from "react"
import { Redirect, Route } from "react-router-dom"
import { useAuthContext } from "context/AuthContext"

const PrivateRoute = ({ children, ...rest }) => {
  const { isAuth, isLoading } = useAuthContext()

  if (!isLoading && !isAuth) return <Redirect to="/auth" />

  return <Route {...rest}>{children}</Route>
}

export default PrivateRoute

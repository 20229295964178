import styled from "styled-components"
import { Link } from "react-router-dom"

export const StyledGreenLink = styled(Link)`
  color: #3bbd40;
  text-decoration: none;
  font-weight: 600;
  transition: 0.3s;

  &:hover {
    color: #1e6824;
  }
`

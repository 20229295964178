import React, { useEffect, useState } from "react"
import { FormGroup, Input, Label } from "reactstrap"
import { toast } from "react-toastify"
import Loader from "./Loader"
import TrashButton from "./TrashButton"
import styled from "styled-components"
import { connect } from "react-redux"
import { setGlobalLoading } from "redux/actions"
import { GET } from "helpers/axios"
import { formatParams } from "hooks/usePaginationFetch"
import { useDebounce } from "hooks/useDebounce"

const ProductRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 65px 95px auto;
  gap: 5px;
  margin-bottom: 5px;
`

const StyledOption = styled.div`
  font-size: 16px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  color: ${({ disabled }) => (disabled ? "#e63737" : "inherit")};
`

const InputWrapper = styled.div`
  width: 100%;
  max-width: 100%;
`

const OverflowInput = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 300px;
  border: 1px solid #d8e2ef;
  border-radius: 0.25rem;
  padding: 0.3125rem 1rem;
  width: 100%;
  max-width: 85vw;
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
`

export const countQuantity = (quantity, productTypeId) => {
  switch (productTypeId) {
    case 1:
      return parseInt(quantity.find((el) => el.warehouse_id === 4)?.quantity)
    default:
      return parseInt(quantity.find((el) => el.warehouse_id === 1)?.quantity)
  }
}

const ProductSelectorValidator = ({
  selected,
  setSelected,
  agreementProducts = [],
  btnDisabled,
  setBtnDisabled,
  setGlobalLoading,
  filters,
  deviceId,
  allowEmptyQuantity = false,
  allowOverValue = false,
  productsInOfferList,
  missingProductsList,
}) => {
  const [products, setProducts] = useState([])
  const debouncedSearch = useDebounce(filters?.model || "", 1000)
  const [isLoading, setIsLoading] = useState(false)
  const [servicePartsAgreement, setServicePartsAgreement] = useState(null)

  const getPrice = (product) => {
    if (product.hasOwnProperty("priceNettoC")) return product.priceNettoC

    const productInAgreement =
      servicePartsAgreement?.warehouseProductCopy?.find(
        (el) => el.id == product.id
      )

    if (product.hasOwnProperty("priceNettoB") && !!productInAgreement) {
      return Number(productInAgreement.price)
    }

    return product.priceNettoA
  }

  useEffect(() => {
    if (!deviceId) return

    GET(`devices/${deviceId}/service-parts-agreement`)
      .then((res) => setServicePartsAgreement(res.data))
      .catch((err) => {
        toast.error("Nie udało się pobrać danych umowy")
      })
  }, [deviceId])

  useEffect(() => {
    if (!deviceId) {
      return setProducts([])
    }

    setIsLoading(true)

    GET(`warehaus-product/searches?${formatParams(filters)}device=${deviceId}`)
      .then(({ data }) => {
        const formattedData = data["hydra:member"].map((item) => {
          return {
            ...item,
            quantity: countQuantity(item?.quantity, item.productType?.id),
            maxQuantity: countQuantity(item?.quantity, item.productType?.id),
            warehouses: item?.quantity,
            isService: item.productType?.name === "usluga",
          }
        })

        setProducts(formattedData)
      })
      .catch((err) => {
        return toast.error("Nie udało się wyszukać produktów")
      })
      .finally(() => setIsLoading(false))
  }, [
    filters.manufacturer,
    filters.productType,
    filters.familyType,
    debouncedSearch,
    deviceId,
  ])

  const selectProduct = (
    id,
    model,
    productPrice,
    quantity,
    isService = false,
    leadTime = 0
  ) => {
    const existingUnselectedItem =
      productsInOfferList?.find((el) => el.model === model) ||
      missingProductsList?.find((el) => el.model === model)

    if (existingUnselectedItem) {
      productPrice = existingUnselectedItem.price
    }

    if (!selected.some((el) => el.model === model)) {
      setSelected([
        ...selected,
        {
          id,
          model,
          price: productPrice,
          quantity: quantity || isService ? 1 : 0,
          maxQuantity: isService ? Infinity : quantity,
          isService,
          leadTime,
        },
      ])
    }
  }

  const unselectProduct = (model) => {
    const ind = selected.findIndex((el) => el.model === model)
    if (ind !== -1) {
      const newArray = [...selected]

      newArray.splice(ind, 1)
      setSelected(newArray)
    }
  }

  const generateNewSelected = (newQuantity, index) => {
    let newSelected = [...selected]
    const { id, leadTime, model, price, maxQuantity, isService } =
      newSelected[index]

    const quantity =
      newQuantity === ""
        ? ""
        : isService
        ? Number(newQuantity)
        : parseInt(newQuantity)

    newSelected[index] = {
      id,
      leadTime,
      model,
      price,
      maxQuantity,
      isService,
      quantity,
    }

    return newSelected
  }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <FormGroup>
            <Label for="clientPersonNA">Dostępne produkty</Label>
            <InputWrapper>
              <OverflowInput>
                {products.map((el) => (
                  <StyledOption
                    key={el.id}
                    disabled={
                      allowEmptyQuantity
                        ? false
                        : !el.isService && el.quantity === 0
                    }
                    onClick={() => {
                      if (
                        allowEmptyQuantity
                          ? false
                          : !el.isService && el.quantity === 0
                      )
                        return

                      const price = getPrice(el)

                      return selectProduct(
                        el.id,
                        el.model,
                        price,
                        el.quantity,
                        el.isService,
                        el.leadTime
                      )
                    }}
                    value={el.model}
                  >
                    {`${el.model}${el.isService ? "" : ` (${el.quantity})`}`}
                  </StyledOption>
                ))}
              </OverflowInput>
            </InputWrapper>
          </FormGroup>
          <FormGroup>
            <Label for="clientPerson">Wybrane produkty:</Label>
            {!selected.length ? (
              <p>Nie wybrano produktu</p>
            ) : (
              selected.map((el, index) => (
                <ProductRow key={el.id}>
                  <span>{el.model}</span>
                  {selected.length === 0 ? (
                    <>
                      <div />
                      <div />
                    </>
                  ) : (
                    <>
                      <Input
                        placeholder="szt"
                        type="number"
                        value={selected[index].quantity}
                        onChange={(e) => {
                          let newValue = ""
                          const selectedItem = selected[index]

                          if (e.target.value === "") {
                            newValue = ""
                          }

                          if (e.target.value === "0" || e.target.value < 0) {
                            newValue = allowEmptyQuantity
                              ? selectedItem.maxQuantity > 0
                                ? 1
                                : 0
                              : 1
                          }

                          if (e.target.value > 0) {
                            newValue =
                              e.target.value > selectedItem.maxQuantity &&
                              !allowOverValue
                                ? selectedItem.maxQuantity
                                : e.target.value
                          }

                          if (
                            e.target.value > selectedItem.maxQuantity &&
                            !allowOverValue
                          )
                            toast.error(
                              `Nie można dodać takiej ilości tego produktu, ponieważ w magazynie znajduje się aktualnie ${selectedItem.maxQuantity} szt.`
                            )

                          if (e.target.value === "0" && !allowEmptyQuantity)
                            toast.error("Nie można dodać produktu w ilości 0")

                          if (e.target.value < 0)
                            toast.error(
                              "Nie można dodać produktu w ilości ujemnej"
                            )

                          setSelected(generateNewSelected(newValue, index))
                        }}
                      />
                      <Input
                        placeholder="zł"
                        type="number"
                        value={selected[index].price}
                        onChange={(e) => {
                          if (
                            e.target.value === "" ||
                            Number(e.target.value) === 0
                          ) {
                            toast.error(
                              `Aby kontynuować, wprowadź dodatnią wartość.`
                            )
                            let newSelected = [...selected]
                            newSelected[index].price = e.target.value
                            setSelected(newSelected)
                            if (!btnDisabled && setBtnDisabled)
                              setBtnDisabled(true)
                          }
                          if (e.target.value && Number(e.target.value) > 0) {
                            let newSelected = [...selected]
                            newSelected[index].price = e.target.value
                            setSelected(newSelected)
                            if (btnDisabled && setBtnDisabled)
                              setBtnDisabled(false)
                          }
                        }}
                      />
                    </>
                  )}
                  <TrashButton onClick={() => unselectProduct(el.model)} />
                </ProductRow>
              ))
            )}
          </FormGroup>
        </>
      )}
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setGlobalLoading: (component, value) =>
      dispatch(setGlobalLoading({ [component]: value })),
  }
}
export default connect(null, mapDispatchToProps)(ProductSelectorValidator)

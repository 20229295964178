import React from "react"
import { GET, POST, PATCH, POST_SINGLE_IMAGE } from "helpers/axios"
import {
  Modal,
  ModalHeader,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
} from "reactstrap"
import { toast } from "react-toastify"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import pl from "date-fns/locale/pl"
import { connect } from "react-redux"
import { setReloadTrue } from "redux/actions"
import { setGlobalLoading } from "redux/actions"
import "react-datepicker/dist/react-datepicker.css"
import Checkbox from "components/common/Form/Checkbox"
import CheckboxLabel from "components/common/Form/Label"
import InlineGroup from "components/common/Form/InlineGroup"
import { useHistory } from "react-router-dom"
import Avatar from "components/common/Avatar"
import { publicUploads } from "helpers/endpoints"
import defaultProfile from "assets/img/default_user.png"
import ChangeImageButton from "components/common/ChangeImageButton"
import DatePicker, { registerLocale } from "react-datepicker"
import { settings } from "config"
import moment from "moment"
import { truncate, getId, validateImageFormat } from "helpers/utils"

registerLocale("pl", pl)

const emptyData = {
  name: "",
  surname: "",
  birthdate: "",
  nameday: "",
  phone: "",
  mobile: "",
  email: "",
  password: "",
  repPassword: "",
  position: "",
  avatar: "",
  organization: "",
  department: "",
  dataConsent: false,
  marketingConsent: false,
  ROLE_CLIENT_DEVICE_OPERATOR: false,
  ROLE_CLIENT_INVOICE_RECIPIENT: false,
  ROLE_CLIENT_DECISION_MAKER: false,
  ROLE_CLIENT_ADMINISTRATOR: false,
  isActive: true,
}

export const roles = [
  {
    role: "ROLE_CLIENT_DEVICE_OPERATOR",
    label: "Obsługa urządzenia",
  },
  {
    role: "ROLE_CLIENT_INVOICE_RECIPIENT",
    label: "Odbiorca faktury (były kontakt księgowy)",
  },
  {
    role: "ROLE_CLIENT_DECISION_MAKER",
    label: "Kontakt decyzyjny",
  },
  {
    role: "ROLE_CLIENT_ADMINISTRATOR",
    label: "Kierownik administracyjny",
  },
]

const ClientUserModal = ({
  isOpen,
  handleCloseModal,
  type = "create",
  personId,
  setGlobalLoading,
  setReloadTrue,
  organizationId,
  disableOrganizationEdit,
}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [departmentId, setDepartmentId] = React.useState("")
  const [form, setForm] = React.useState({
    ...emptyData,
    organization: organizationId || "",
  })
  const [showErrors, setShowErrors] = React.useState(false)

  const [sendingFile, setSendingFile] = React.useState(false)
  const [receivedFilename, setReceivedFilename] = React.useState("")

  const history = useHistory()

  const [organizationsLoading, setOrganizationsLoading] = React.useState(false)
  const [organizations, setOrganizations] = React.useState([])
  const currentDepartments = React.useMemo(
    () =>
      organizations.find((organization) => organization.id == form.organization)
        ?.departments,
    [form.organization, organizations]
  )

  React.useEffect(() => {
    setOrganizationsLoading(true)

    GET(`organizations-with-departments`)
      .then(({ data }) => {
        setOrganizations(
          data["hydra:member"].map(({ id, shortName, departments }) => ({
            id,
            name: shortName,
            departments,
          }))
        )
      })
      .catch(() => {
        toast.error("Wystąpił błąd pobierania danych.")
      })
      .finally(() => setOrganizationsLoading(false))
  }, [])

  const hiddenFileInput = React.useRef(null)
  const addFile = () => {
    hiddenFileInput.current.click()
  }

  React.useEffect(() => {
    if (type !== "edit") return

    setIsLoading(true)

    GET(`client_people/${personId}`)
      .then((res) => {
        const { data } = res

        const formData = {
          name: data.user?.name || "",
          surname: data.user?.surname || "",
          phone: data.user?.phone || "",
          mobile: data.user?.mobile || "",
          email: data.user?.email || "",
          colour: data.colour || "",
          avatar: data.user?.avatar || "",
          birthdate: data.birthdate ? new Date(data.birthdate) : "",
          nameday: data.nameday ? new Date(data.nameday) : "",
          position: data.position || "",
          organization: data.Organization["@id"]
            ? getId(data.Organization["@id"])
            : "",
          department: data.Department["@id"]
            ? getId(data.Department["@id"])
            : "",
          dataConsent: data.dataConsent || false,
          marketingConsent: data.marketingConsent || false,
          ROLE_CLIENT_DEVICE_OPERATOR: data.user?.roles.includes(
            "ROLE_CLIENT_DEVICE_OPERATOR"
          ),
          ROLE_CLIENT_INVOICE_RECIPIENT: data.user?.roles.includes(
            "ROLE_CLIENT_INVOICE_RECIPIENT"
          ),
          ROLE_CLIENT_DECISION_MAKER: data.user?.roles.includes(
            "ROLE_CLIENT_DECISION_MAKER"
          ),
          ROLE_CLIENT_ADMINISTRATOR: data.user?.roles.includes(
            "ROLE_CLIENT_ADMINISTRATOR"
          ),
          password: "",
          repPassword: "",
          isActive: data.user?.isActive,
        }

        setForm(formData)
        setDepartmentId(formData.department)
        setReceivedFilename(data.user?.avatar)
      })
      .catch((err) => {
        toast.error("Wystąpił błąd pobierania danych.")
      })
      .finally(() => setIsLoading(false))
  }, [type])

  const cleanForm = () => setForm(emptyData)

  const handleValidate = () => {
    return (
      form.name &&
      form.surname &&
      form.email &&
      form.phone &&
      form.position &&
      form.organization &&
      form.department
    )
  }

  const checkPasswords = () => {
    return form.password && form.password === form.repPassword
  }

  const checkRoles = () => {
    let checkedRoles = 0

    roles.forEach(({ role }) => {
      if (form[role]) checkedRoles++
    })

    return checkedRoles > 0
  }

  const handleAddClientPerson = () => {
    if (!checkPasswords()) {
      setShowErrors(true)
      toast.error("Hasła nie są takie same")
      return setIsLoading(false)
    }

    const clientPersonModel = {
      Organization: form.organization
        ? `/api/organizations/${form.organization}`
        : null,
      Department: form.department
        ? `/api/departments/${form.department}`
        : null,
      dataConsent: form.dataConsent || false,
      marketingConsent: form.marketingConsent || false,
      position: form.position || null,
      birthdate: form.birthdate ? moment(form.birthdate).format() : null,
      nameday: form.nameday ? moment(form.nameday).format() : null,
      email: form.email,
      roles: ["ROLE_CLIENT"],
      password: form.password,
      tmpPerson: null,
      name: form.name,
      surname: form.surname,
      avatar: receivedFilename || null,
      phone: form.phone,
      mobile: form.mobile,
    }

    const user = {
      email: form.email || null,
      roles: ["ROLE_CLIENT"],
      password: form.password,
      clientPerson: "",
      tmbPerson: null,
      name: form.name,
      surname: form.surname,
      avatar: receivedFilename || null,
      phone: form.phone,
      mobile: form.mobile,
    }

    roles.forEach(({ role }) => {
      if (form[role]) user.roles.push(role)
    })

    POST("client_people/custom", clientPersonModel)
      .then((res) => {
        user.clientPerson = res.data["@id"]
        cleanForm()
        handleCloseModal()
        history.go(0)
        toast.success("Dodano nowego użytkownika")
      })
      .catch((err) => {
        setIsLoading(false)
        toast.error("Nie udało się dodać użytkownika")
      })
      .finally(() => setIsLoading(false))
  }

  const handleUpdateClientPerson = async () => {
    let canRelocate = true

    const user = {
      email: form.email || null,
      roles: ["ROLE_CLIENT"],
      password: form.password ? form.password : undefined,
      name: form.name,
      surname: form.surname,
      avatar: receivedFilename || null,
      phone: form.phone,
      mobile: form.mobile,
      isActive: form.isActive,
    }

    const clientPersonModel = {
      Organization: form.organization
        ? `/api/organizations/${form.organization}`
        : null,
      Department: form.department
        ? `/api/departments/${form.department}`
        : null,
      dataConsent: form.dataConsent || false,
      marketingConsent: form.marketingConsent || false,
      position: form.position || null,
      birthdate: form.birthdate ? moment(form.birthdate).format() : null,
      nameday: form.nameday ? moment(form.nameday).format() : null,
      ...user,
    }

    if (departmentId !== form.department) {
      await GET(`client_people/${personId}/check-relocation`).catch(
        (err) => (canRelocate = false)
      )
    }

    if (!canRelocate) {
      setIsLoading(false)
      return toast.error(
        "Aby wykonać ten krok odepnij od osoby wszystkie przypisane urządzenia i zamknij wszystkie zlecenia serwisowe."
      )
    }

    if (form.password !== form.repPassword) {
      setShowErrors(true)
      toast.error("Hasła nie są takie same")
      return setIsLoading(false)
    }

    roles.forEach(({ role }) => {
      if (form[role]) user.roles.push(role)
    })

    PATCH(`/api/client_people/${personId}/custom`, clientPersonModel)
      .then((res) => {
        cleanForm()
        handleCloseModal()
        history.go(0)
        toast.success("Użytkownik został zaktualizowany")
      })
      .catch((res) => {
        setIsLoading(false)
        toast.error("Nie udało się zapisać zmian")
      })
      .finally(() => setIsLoading(false))
  }

  const handleSelectFile = (event) => {
    if (!event.target.files[0]) return

    if (event.target.files[0].size > 5242880) {
      event.target.value = null
      return toast.error("Rozmiar pliku jest za duży")
    }

    if (!validateImageFormat(event.target.files[0])) {
      event.target.value = null
      return toast.error("Nieobsługiwany format pliku")
    }

    setSendingFile(true)
    setGlobalLoading("TmbUserModal", true)
    POST_SINGLE_IMAGE(event.target.files[0], "true")
      .then((res) => {
        setReceivedFilename(res.data[0])
        setForm({ ...form, avatar: res.data[0] })
      })
      .catch((err) => {
        toast.error("Błąd przesyłania pliku")
      })
      .finally(() => {
        setSendingFile(false)
        setGlobalLoading("TmbUserModal", false)
      })
  }

  const phoneRegex = new RegExp(
    /^(\+(\d){2})?(?:\(?\?)?(?:[-\(\)\s]*(\d)){9}\)?$/
  )

  const handleSubmit = (e) => {
    e.preventDefault()
    if (sendingFile) {
      return toast.error("Poczekaj na przesłanie pliku")
    }

    if (!handleValidate()) {
      setShowErrors(true)
      return toast.error("Sprawdź poprawność danych")
    }
    if (!checkRoles()) {
      setShowErrors(true)
      return toast.error("Wybierz przynajmniej jedną rolę")
    }

    if (form.phone && phoneRegex.test(form.phone) === false) {
      setShowErrors(true)
      return toast.error("Numer telefonu jest niepoprawny")
    }

    if (form.mobile && phoneRegex.test(form.mobile) === false) {
      setShowErrors(true)
      return toast.error("Numer telefonu jest niepoprawny")
    }

    setShowErrors(false)
    setIsLoading(true)

    return type === "edit"
      ? handleUpdateClientPerson()
      : handleAddClientPerson()
  }

  return (
    <Modal
      isOpen={isOpen}
      modalClassName="modal-fixed-right modal-theme"
      className="modal-dialog-vertical"
      contentClassName="min-vh-100 w-500 border-0"
      toggle={handleCloseModal}
    >
      <div className="position-absolute t-0 r-0 z-index-1">
        <Button
          size="sm"
          className="close close-circle d-flex flex-center transition-base mt-3 mr-3"
          onClick={handleCloseModal}
        >
          <FontAwesomeIcon
            icon="times"
            transform="shrink-6 right-0.3 down-0.3"
          />
        </Button>
      </div>
      <ModalHeader tag="div" className="modal-header-settings">
        <div className="py-1 flex-grow-1">
          <h5>
            {type === "edit"
              ? "Edycja osoby klienta"
              : "Dodawanie osoby klienta"}
          </h5>
        </div>
      </ModalHeader>
      <Form className="p-4 overflow-auto">
        <div className="d-flex flex-column align-items-center mb-2">
          <Avatar
            size="4xl"
            src={
              form.avatar ? `${publicUploads}/${form.avatar}` : defaultProfile
            }
          />
          <ChangeImageButton text="Zmień zdjęcie" event={() => addFile()} />
        </div>
        <input
          style={{ display: "none" }}
          type="file"
          name="file"
          id="file"
          ref={hiddenFileInput}
          onChange={(e) => handleSelectFile(e)}
        />
        <FormGroup>
          <Label for="name">Imię</Label>
          <Input
            type="text"
            name="name"
            id="name"
            invalid={showErrors && !form.name}
            disabled={isLoading}
            value={form.name}
            onChange={({ target: { value } }) =>
              setForm((prev) => ({ ...prev, name: value }))
            }
          />
        </FormGroup>
        <FormGroup>
          <Label for="surname">Nazwisko</Label>
          <Input
            type="text"
            name="surname"
            id="surname"
            value={form.surname}
            invalid={showErrors && !form.surname}
            disabled={isLoading}
            onChange={({ target: { value } }) =>
              setForm((prev) => ({ ...prev, surname: value }))
            }
          />
        </FormGroup>
        <FormGroup>
          <Label for="birthdate">Data urodzin</Label>
          <div>
            <DatePicker
              locale="pl"
              showYearDropdown
              yearDropdownItemNumber={70}
              scrollableYearDropdown
              dateFormat={settings.dateFormatPicker}
              disabled={isLoading}
              className="custom-select form-control"
              selected={form.birthdate}
              onChange={(date) =>
                setForm((prev) => ({ ...prev, birthdate: date }))
              }
            />
          </div>
        </FormGroup>
        <FormGroup>
          <Label for="nameday">Data imienin</Label>
          <div>
            <DatePicker
              locale="pl"
              showYearDropdown
              yearDropdownItemNumber={70}
              scrollableYearDropdown
              dateFormat={settings.dateFormatPicker}
              disabled={isLoading}
              className="custom-select form-control"
              selected={form.nameday}
              onChange={(date) =>
                setForm((prev) => ({ ...prev, nameday: date }))
              }
            />
          </div>
        </FormGroup>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="phone">Telefon</Label>
              <Input
                type="text"
                name="phone"
                id="phone"
                disabled={isLoading}
                invalid={showErrors && !form.phone}
                value={form.phone}
                onChange={({ target: { value } }) =>
                  setForm((prev) => ({ ...prev, phone: value }))
                }
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="mobile">Telefon komórkowy</Label>
              <Input
                type="text"
                name="mobile"
                id="mobile"
                disabled={isLoading}
                value={form.mobile}
                onChange={({ target: { value } }) =>
                  setForm((prev) => ({ ...prev, mobile: value }))
                }
              />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup style={{ position: "relative" }}>
          <Label for="email">Email</Label>
          <Input
            type="email"
            name="email"
            id="email"
            disabled={isLoading}
            value={form.email}
            invalid={showErrors && !form.email}
            onChange={({ target: { value } }) =>
              setForm((prev) => ({ ...prev, email: value }))
            }
          />
        </FormGroup>
        <FormGroup style={{ position: "relative" }}>
          <Label for="password">Hasło</Label>
          <Input
            type="password"
            name="password"
            id="password"
            disabled={isLoading}
            invalid={showErrors && type === "create" && !form.password}
            value={form.password}
            onChange={({ target: { value } }) =>
              setForm((prev) => ({ ...prev, password: value }))
            }
          />
        </FormGroup>
        <FormGroup>
          <Label for="repeat-password">Powtórz hasło</Label>
          <Input
            type="password"
            name="repeat-password"
            id="repeat-password"
            disabled={isLoading}
            invalid={showErrors && type === "create" && !form.repPassword}
            value={form.repPassword}
            onChange={({ target: { value } }) =>
              setForm((prev) => ({ ...prev, repPassword: value }))
            }
          />
        </FormGroup>
        <FormGroup>
          <Label for="position">Stanowisko</Label>
          <Input
            type="text"
            name="position"
            id="position"
            invalid={showErrors && !form.position}
            disabled={isLoading}
            value={form.position}
            onChange={({ target: { value } }) =>
              setForm((prev) => ({ ...prev, position: value }))
            }
          />
        </FormGroup>
        {!(type === "edit" && disableOrganizationEdit) && (
          <FormGroup>
            <Label for="organization">Organizacja</Label>
            <Input
              bsSize="sm"
              type="select"
              name="organization"
              id="organization"
              disabled={organizationsLoading || organizationId || isLoading}
              invalid={showErrors && !form.organization}
              value={form.organization}
              onChange={({ target: { value } }) =>
                setForm((prev) => ({
                  ...prev,
                  organization: value,
                  department: "",
                }))
              }
            >
              <option value="" />
              {organizations.map(({ id, name }) => (
                <option key={id} value={id}>
                  {truncate(name)}
                </option>
              ))}
            </Input>
          </FormGroup>
        )}
        <FormGroup>
          <Label for="department">Lokalizacja</Label>
          <Input
            bsSize="sm"
            type="select"
            name="department"
            id="department"
            disabled={organizationsLoading || isLoading}
            invalid={showErrors && !form.department}
            value={form.department}
            onChange={({ target: { value } }) =>
              setForm((prev) => ({ ...prev, department: value }))
            }
          >
            <option value="" />
            {currentDepartments?.map((department) => (
              <option key={department.id} value={department.id}>
                {department.name}
              </option>
            ))}
          </Input>
        </FormGroup>
        <InlineGroup>
          <CheckboxLabel for="dataConsent">Zgoda dane</CheckboxLabel>
          <Checkbox
            id="dataConsent"
            disabled={isLoading}
            checked={form.dataConsent}
            onChange={() =>
              setForm((prev) => ({ ...prev, dataConsent: !prev.dataConsent }))
            }
          />
        </InlineGroup>
        <InlineGroup>
          <CheckboxLabel for="marketingConsent">Zgoda marketing</CheckboxLabel>
          <Checkbox
            id="marketingConsent"
            disabled={isLoading}
            checked={form.marketingConsent}
            onChange={() =>
              setForm((prev) => ({
                ...prev,
                marketingConsent: !prev.marketingConsent,
              }))
            }
          />
        </InlineGroup>
        {type === "edit" && (
          <InlineGroup>
            <CheckboxLabel for="isActive">Aktywny użytkownik</CheckboxLabel>
            <Checkbox
              id="isActive"
              disabled={isLoading}
              checked={form.isActive}
              onChange={() =>
                setForm((prev) => ({ ...prev, isActive: !prev.isActive }))
              }
            />
          </InlineGroup>
        )}
        <h5 style={{ padding: "15px 0" }}>Uprawnienia:</h5>
        {roles.map(({ role, label }) => (
          <InlineGroup key={role}>
            <CheckboxLabel for={role}>{label}</CheckboxLabel>
            <Checkbox
              id={role}
              disabled={isLoading}
              checked={form[role]}
              onChange={() =>
                setForm((prev) => ({ ...prev, [role]: !prev[role] }))
              }
            />
          </InlineGroup>
        ))}
        <Button
          className="mt-3 mb-5"
          color="success"
          onClick={handleSubmit}
          disabled={isLoading || sendingFile}
        >
          Zapisz
        </Button>
      </Form>
    </Modal>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setReloadTrue: () => dispatch(setReloadTrue()),
    setGlobalLoading: (component, value) =>
      dispatch(setGlobalLoading({ [component]: value })),
  }
}

export default connect(null, mapDispatchToProps)(ClientUserModal)

import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { setGlobalLoading } from "redux/actions"
import { setReloadTrue } from "redux/actions/index"
import { Modal, ModalHeader } from "reactstrap"
import { Button, Form, FormGroup, Input, Label } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { GET, POST } from "helpers/axios"
import { toast } from "react-toastify"
import styled from "styled-components"
import { formatCurrency } from "helpers/utils"
import ProductSelectorValidator from "../common/ProductSelectorValidator"
import ActivitiesSelector from "../common/ActivitiesSelector"
import Checkbox from "components/common/Form/Checkbox"
import CheckboxLabel from "components/common/Form/Label"
import InlineGroup from "components/common/Form/InlineGroup"
import TrashButton from "components/common/TrashButton"
import { useHistory } from "react-router-dom"
import { StyledGreenLink } from "../Links/StyledLinks"
import { StyledAlertInfo } from "../agreement/StyledAlertInfo"

const ModalDual = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 25px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`

const ShowOnMobile = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`

const HideOnMobile = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`

const ProductRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 65px 95px auto;
  gap: 5px;
  margin-bottom: 5px;
`

const InputRow = styled.div`
  display: flex;
  align-items: center;
`

const AddOffer = ({
  serviceId,
  isOpen,
  handleCloseModal,
  deviceId,
  setReloadTrue,
  setGlobalLoading,
  refetch,
  isDelivery,
  description,
}) => {
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [productTypes, setProductTypes] = useState([])
  const [familyTypes, setFamilyTypes] = useState([])
  const [manufacturers, setManufacturers] = useState([])
  const [activeVisits, setActiveVisits] = useState([])
  const [deviceIdState, setDeviceIdState] = useState(deviceId)
  const [emailLoading, setEmailLoading] = useState(true)
  const history = useHistory()
  const [activeAgreement, setActiveAgreement] = useState(null)

  useEffect(() => {
    if (!deviceIdState) return

    GET(`devices/${deviceIdState}/active-agreement`)
      .then((res) => {
        setActiveAgreement(res.data)
      })
      .catch((err) =>
        toast.error("Nie udało się załadować aktywnej umowy, spróbuj ponownie.")
      )
  }, [deviceIdState])

  useEffect(() => {
    if (serviceId) return

    GET(
      `tickets?label[]=Nowe&label[]=Rozpatrywanie&label[]=Planowanie%20serwisu&label[]=Oczekiwanie%20na%20serwis&label[]=Serwis%20w%20trakcie&pagination=false`
    ).then(({ data }) => {
      const filteredData = data["hydra:member"]

      setActiveVisits(filteredData)
    })
  }, [serviceId])

  useEffect(() => {
    GET(`product_types`).then(({ data }) => {
      const filteredData = data["hydra:member"].filter((item) => item.name)

      setProductTypes(filteredData)
    })
  }, [])

  useEffect(() => {
    GET(`family_types`).then(({ data }) => {
      const filteredData = data["hydra:member"].filter((item) => item.name)

      setFamilyTypes(filteredData)
    })
  }, [])

  useEffect(() => {
    GET(`manufacturers`).then(({ data }) => {
      const filteredData = data["hydra:member"].filter((item) => item.name)

      setManufacturers(filteredData)
    })
  }, [])

  const [form, setForm] = useState({
    description: description,
    isCostOverrun: false,
    outOfWarehouse: false,
    duration: 7,
    serviceId: serviceId || "",
    email: "",
  })
  const [filters, setFilters] = useState({
    manufacturer: "",
    productType: "",
    familyType: "",
    model: "",
  })

  useEffect(() => {
    if (!form.serviceId) return setForm((prev) => ({ ...prev, email: "" }))

    setEmailLoading(true)
    GET(`tickets/${form.serviceId}/get-client-administrator`)
      .then(({ data }) => {
        setForm((prev) => ({ ...prev, email: data.email || "" }))
      })
      .finally(() => setEmailLoading(false))
  }, [form.serviceId])

  const [submitting, setSubmitting] = useState(false)
  const [agreementProducts, setAgreementProducts] = useState([])
  const [selected, setSelected] = useState([])
  const [offerType] = useState("0")
  const [selectedActivities, setSelectedActivities] = useState([])
  const [totalPrice, setTotalPrice] = useState(0)
  const [activityMissingInput, setActivityMissingInput] = useState("")
  const [activityMissingQuantity, setActivityMissingQuantity] = useState("")
  const [activitiesMissing, setActivitiesMissing] = useState([])

  const handleAddMissingActivity = () => {
    if (
      activityMissingQuantity === "" ||
      activityMissingQuantity === "0" ||
      parseInt(activityMissingQuantity) <= 0
    )
      return toast.error("Ilość musi być większa od 0")

    if (!activityMissingInput) return toast.error("Podaj nazwę aktywności")

    setActivitiesMissing((prev) => [
      ...prev,
      {
        activity: activityMissingInput,
        quantity: Number(activityMissingQuantity),
      },
    ])
    setActivityMissingInput("")
    setActivityMissingQuantity("")
  }

  const handleRemoveMissingActivity = (activity) => {
    setActivitiesMissing(
      activitiesMissing.filter((item) => item.activity !== activity)
    )
  }

  useEffect(() => {
    let total = 0
    selected.map(
      (product) => (total += parseFloat(product.price * product.quantity))
    )
    setTotalPrice(total)
  }, [selected])

  const wrongActivities = selected.find(
    (el) =>
      typeof el.quantity !== "number" || el.quantity === "" || el.price === ""
  )

  const handleAddServiceVisit = () => {
    if (!form.duration) {
      return toast.error("Podaj termin ważności")
    }

    if (!form.serviceId) {
      return toast.error("Wybierz zgłoszenie serwisowe")
    }

    if (wrongActivities) {
      return toast.error("Sprawdź poprawność aktywności serwisowych")
    }

    const activitiesArr = selected.map((el) => {
      const { id, model, quantity, price, leadTime } = el
      return {
        id,
        model,
        quantity,
        price: parseFloat(price).toFixed(2),
        leadTime,
      }
    })

    const model = {
      activities: [...activitiesArr],
      serviceOperations: [...selectedActivities],
      description: form.description,
      isCostOverrun: form.isCostOverrun,
      outOfWarehouse: form.outOfWarehouse,
      activitiesMissing: [...activitiesMissing],
      ticket: `/api/tickets/${form.serviceId}`,
      duration: Number(form.duration),
      emailRecipient: form.email,
    }

    setSubmitting(true)
    POST("service_offers", model)
      .then((res) => {
        setReloadTrue()
        handleCloseModal()
        history.go(0)
      })
      .catch((err) => {
        toast.error("Wystąpił błąd")
      })
      .finally(() => setSubmitting(false))
  }

  console.log(window.matchMedia("(min-width: 768px)"))

  return (
    <>
      <Modal
        isOpen={isOpen}
        modalClassName="modal-fixed-right-wide modal-theme"
        className="modal-dialog-vertical"
        contentClassName="min-vh-100 w-500 border-0"
        toggle={handleCloseModal}
      >
        <div className="position-absolute t-0 r-0  z-index-1">
          <Button
            size="sm"
            className="close close-circle d-flex flex-center transition-base mt-3 mr-3"
            onClick={() => handleCloseModal()}
          >
            <FontAwesomeIcon
              icon="times"
              transform="shrink-6 right-0.3 down-0.3"
            />
          </Button>
        </div>
        <ModalHeader tag="div" className="modal-header-settings">
          <div className="py-1 flex-grow-1">
            <h5>Utwórz ofertę</h5>
          </div>
        </ModalHeader>
        <>
          <Form className="p-4">
            <ModalDual>
              {/* LEFT SIDE */}
              <div>
                <FormGroup>
                  <Label for="clientPerson">Umowa:</Label>
                  {activeAgreement ? (
                    <p>
                      <StyledGreenLink to={`/agreements/${activeAgreement.id}`}>
                        {activeAgreement.name}
                      </StyledGreenLink>
                    </p>
                  ) : (
                    <StyledAlertInfo>Brak aktywnej umowy</StyledAlertInfo>
                  )}
                </FormGroup>
                {activeAgreement?.warehouseProductCopy?.length > 0 && (
                  <FormGroup>
                    <Label>Pozycje z umowy:</Label>
                    {activeAgreement?.warehouseProductCopy?.map((activity) => (
                      <div key={activity.id}>
                        {activity.model}
                        <Button
                          size="sm"
                          className="ml-2 px-2 py-1"
                          color="falcon-default"
                          onClick={() =>
                            setFilters({ ...filters, model: activity.model })
                          }
                        >
                          <FontAwesomeIcon icon="search" size="sm" />
                        </Button>
                      </div>
                    ))}
                  </FormGroup>
                )}
                {!serviceId && (
                  <FormGroup>
                    <Input
                      type="select"
                      className="mb-2"
                      value={form.serviceId}
                      onChange={(e) => {
                        setForm({ ...form, serviceId: e.target.value })
                        setDeviceIdState(
                          activeVisits.find((el) => el.id == e.target.value)
                            ?.device?.id || ""
                        )
                        setSelected([])
                      }}
                      disabled={activeVisits.length === 0}
                    >
                      <option value="">Wybierz zlecenie</option>
                      {activeVisits.map((el) => (
                        <option key={el.id} value={el.id}>
                          {el.name} ({el.organization.name})
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                )}
                <FormGroup>
                  <Label for="filters">Wybierz produkt</Label>
                  {offerType === "0" && (
                    <>
                      <Input
                        type="select"
                        className="mb-2"
                        onChange={(e) =>
                          setFilters({
                            ...filters,
                            productType: e.target.value,
                          })
                        }
                      >
                        <option value="">Typ</option>
                        {productTypes.map((el) => (
                          <option key={el.id} value={el.id}>
                            {el.name}
                          </option>
                        ))}
                      </Input>
                      <Input
                        type="select"
                        className="mb-2"
                        onChange={(e) =>
                          setFilters({
                            ...filters,
                            familyType: e.target.value,
                          })
                        }
                      >
                        <option value="">Rodzaj</option>
                        {familyTypes.map((el) => (
                          <option key={el.id} value={el.id}>
                            {el.name}
                          </option>
                        ))}
                      </Input>
                    </>
                  )}
                  <Input
                    type="select"
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        manufacturer: e.target.value,
                      })
                    }
                  >
                    <option value="">Producent</option>
                    {manufacturers.map((el) => (
                      <option key={el.id} value={el.id}>
                        {el.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Input
                    placeholder="Wyszukaj"
                    value={filters.model}
                    onChange={(e) =>
                      setFilters({ ...filters, model: e.target.value })
                    }
                  />
                </FormGroup>
                <ShowOnMobile>
                  {window.matchMedia("(max-width: 768px)").matches && (
                    <ProductSelectorValidator
                      filters={filters}
                      selected={selected}
                      setSelected={setSelected}
                      agreementProducts={agreementProducts}
                      btnDisabled={btnDisabled}
                      setBtnDisabled={setBtnDisabled}
                      deviceId={deviceIdState}
                      allowEmptyQuantity
                    />
                  )}
                </ShowOnMobile>
                <FormGroup>
                  <ActivitiesSelector
                    selectedActivities={selectedActivities}
                    setSelectedActivities={setSelectedActivities}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="mt-3" for="duration">
                    Termin ważności
                  </Label>
                  <Input
                    type="select"
                    name="duration"
                    id="duration"
                    value={form.duration}
                    onChange={(e) =>
                      setForm({ ...form, duration: e.target.value })
                    }
                  >
                    <option value={7}>7 dni</option>
                    <option value={14}>14 dni</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label className="mt-3" for="email">
                    Email odbiorcy
                  </Label>
                  <Input
                    name="email"
                    id="email"
                    value={form.email}
                    disabled={emailLoading}
                    onChange={(e) =>
                      setForm({ ...form, email: e.target.value })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="mt-3" for="description">
                    Opis/uwagi
                  </Label>
                  <Input
                    type="textarea"
                    rows={5}
                    name="description"
                    id="description"
                    value={form.description}
                    onChange={(e) =>
                      setForm({ ...form, description: e.target.value })
                    }
                  />
                </FormGroup>
                <InlineGroup>
                  <CheckboxLabel for="isCostOverrun">
                    Wartość naprawy przekracza wartość urządzenia
                  </CheckboxLabel>
                  <Checkbox
                    id="isCostOverrun"
                    checked={form.isCostOverrun}
                    onChange={() =>
                      setForm((prev) => ({
                        ...prev,
                        isCostOverrun: !prev.isCostOverrun,
                      }))
                    }
                  />
                </InlineGroup>
                <p>
                  <b>Suma: </b>
                  {formatCurrency(totalPrice)}
                </p>
              </div>

              {/* RIGHT SIDE */}
              <div>
                <HideOnMobile>
                  {window.matchMedia("(min-width: 768px)").matches && (
                    <ProductSelectorValidator
                      filters={filters}
                      selected={selected}
                      setSelected={setSelected}
                      agreementProducts={agreementProducts}
                      btnDisabled={btnDisabled}
                      setBtnDisabled={setBtnDisabled}
                      deviceId={deviceIdState}
                      allowEmptyQuantity
                    />
                  )}
                </HideOnMobile>
                <InlineGroup className="mt-4">
                  <CheckboxLabel for="outOfWarehouse">
                    Brak części na magazynie
                  </CheckboxLabel>
                  <Checkbox
                    id="outOfWarehouse"
                    checked={form.outOfWarehouse}
                    onChange={() => {
                      setForm((prev) => ({
                        ...prev,
                        outOfWarehouse: !prev.outOfWarehouse,
                      }))
                      setActivitiesMissing([])
                      setActivityMissingInput("")
                    }}
                  />
                </InlineGroup>
                {form.outOfWarehouse && (
                  <>
                    <FormGroup>
                      <InputRow>
                        <Input
                          placeholder="Brakująca część..."
                          value={activityMissingInput}
                          onChange={(e) =>
                            setActivityMissingInput(e.target.value)
                          }
                        />
                        <Input
                          placeholder="Ilość"
                          type="number"
                          value={activityMissingQuantity}
                          onChange={(e) =>
                            setActivityMissingQuantity(e.target.value)
                          }
                        />
                        <Button
                          className="m-0"
                          color="success"
                          onClick={() => handleAddMissingActivity()}
                        >
                          Dodaj
                        </Button>
                      </InputRow>
                    </FormGroup>
                    <FormGroup className="mb-6">
                      <Label for="clientPerson">Dodane brakujące części:</Label>
                      {!activitiesMissing.length ? (
                        <p>Nie wybrano części</p>
                      ) : (
                        activitiesMissing.map((el) => (
                          <ProductRow key={el.activity}>
                            <span>
                              {el.activity} x {el.quantity}
                            </span>
                            <TrashButton
                              onClick={() =>
                                handleRemoveMissingActivity(el.activity)
                              }
                            />
                          </ProductRow>
                        ))
                      )}
                    </FormGroup>
                  </>
                )}
              </div>
            </ModalDual>
            <Button
              className="mt-3"
              color="success"
              onClick={() => handleAddServiceVisit()}
              disabled={submitting || !!wrongActivities}
            >
              Zapisz
            </Button>
          </Form>
        </>
      </Modal>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setReloadTrue: () => dispatch(setReloadTrue()),
    setGlobalLoading: (component, value) =>
      dispatch(setGlobalLoading({ [component]: value })),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddOffer)

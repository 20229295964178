import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Link } from "react-router-dom"
import logo from "assets/img/logo-tmb.png"
import styled from "styled-components"

const LogoLink = styled(Link)`
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`

const Logo = ({ at, width, className, ...rest }) => {
  return (
    <LogoLink
      to="/"
      className={classNames(
        "text-decoration-none",
        { "navbar-brand text-left": at === "navbar-vertical" },
        { "navbar-brand text-left": at === "navbar-top" }
      )}
      {...rest}
    >
      <div
        className={classNames(
          "d-flex",
          {
            "align-items-center py-3": at === "navbar-vertical",
            "align-items-center": at === "navbar-top",
            "flex-center font-weight-extra-bold fs-5 mb-6": at === "auth",
          },
          className
        )}
      >
        <img className="mr-2" src={logo} alt="Logo" width={width} />
      </div>
    </LogoLink>
  )
}

Logo.propTypes = {
  at: PropTypes.oneOf(["navbar-vertical", "navbar-top", "auth"]),
  width: PropTypes.number,
  className: PropTypes.string,
}

Logo.defaultProps = { at: "auth", width: 58 }

export default Logo

import { combineReducers } from "redux"
import userReducer from "./user"
import reloadDataReducer from "./reloadData"
import pageReducer from "./page"
import globalLoading from "./globalLoading"

const allReducers = combineReducers({
  user: userReducer,
  reloadData: reloadDataReducer,
  page: pageReducer,
  globalLoading: globalLoading,
})

export default allReducers

import React, { useState, useEffect, useCallback } from "react"
import { GET_VISIT_BTNS, ACCEPT_REJECT_SERVICE_VISIT } from "helpers/axios"
import { reduceId, visitStatusFormatter } from "helpers/utils"
import { connect } from "react-redux"
import { Button, Row } from "reactstrap"
import AcceptRejectVisitModal from "components/actionModals/AcceptRejectVisitModal"
import { toast } from "react-toastify"
import { useQuery } from "@apollo/client"
import { LOAD_SERVICE_VISIT_STATUS } from "GraphQL/Queries"
import {
  formatStatusMessage,
  formatTypeMessage,
} from "components/dataTables/VisitsTable.config"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { getId } from "helpers/utils"
import VisitReceptionModal from "components/actionModals/VisitReceptionModal"
import { GET } from "helpers/axios"
import { useHistory } from "react-router-dom"

const MessageVisit = ({ name, visitId, status, refetch, visitType }) => {
  const [showReceptionModal, setShowReceptionModal] = useState(false)
  const [isFetching, setIsFetching] = useState(true)
  const [departmentId, setDepartmentId] = useState(null)
  const history = useHistory()

  useEffect(() => {
    setIsFetching(true)

    GET(`service_visits/${visitId}`)
      .then((res) => setDepartmentId(res.data?.ticket?.device?.Department?.id))
      .finally(() => setIsFetching(false))
  }, [visitId])

  return (
    <>
      {showReceptionModal && (
        <VisitReceptionModal
          isOpen={showReceptionModal}
          handleCloseModal={() => setShowReceptionModal(false)}
          departmentId={departmentId}
          visitId={visitId}
          refetch={refetch}
        />
      )}
      <p className="m-0">
        <b>Wizyta:</b> {name}
      </p>
      <p>
        <b>Status wizyty:</b> {formatStatusMessage(status)} <br />
        <b>Typ wizyty:</b> {formatTypeMessage(visitType)}
      </p>
      <Button
        className="mt-1 mr-2 btn btn-falcon-default btn-sm"
        onClick={() => history.push(`/visits/${visitId}/details`)}
      >
        Pokaż szczegóły
      </Button>
      {status === "done" && (
        <Button
          className="mt-1 btn btn-falcon-default btn-sm"
          onClick={() => setShowReceptionModal(true)}
          disabled={isFetching}
        >
          Odbiór wizyty
        </Button>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps, null)(MessageVisit)

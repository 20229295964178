import React, { useContext, useEffect } from "react"
import NavbarTop from "components/navbar/NavbarTop"
import NavbarVertical from "components/navbar/NavbarVertical"
import Footer from "components/footer/Footer"
import { connect } from "react-redux"
import { Redirect, Switch, Route, useHistory } from "react-router-dom"
import AppContext from "context/Context"
import { getPageName } from "helpers/utils"
import loadable from "@loadable/component"
import { removeRedirectPage } from "redux/actions"

const MainDashboard = loadable(() => import("views/clientViews/MainDashboard"))
const Devices = loadable(() => import("views/clientViews/Devices/Devices"))
const Device = loadable(() => import("views/clientViews/Devices/Device"))

const Departments = loadable(() =>
  import("views/clientViews/Departments/Departments")
)
const Department = loadable(() =>
  import("views/clientViews/Departments/Department")
)
const Agreements = loadable(() =>
  import("views/clientViews/Agreements/Agreements")
)
const Agreement = loadable(() =>
  import("views/clientViews/Agreements/Agreement")
)
const Services = loadable(() => import("views/clientViews/Services/Services"))
const Service = loadable(() => import("views/clientViews/Services/Service"))

const Settlements = loadable(() =>
  import("views/clientViews/Settlements/Settlements")
)
const Settlement = loadable(() =>
  import("views/clientViews/Settlements/Settlement")
)

const Offers = loadable(() => import("views/clientViews/Offers/Offers"))
const Offer = loadable(() => import("views/clientViews/Offers/Offer"))

const UserDetails = loadable(() =>
  import("components/detailsCards/UserDetails")
)

const ChangeLog = loadable(() => import("views/tmbViews/ChangeLog/ChangeLog"))

const ClientDashboardLayout = ({ page, removeRedirectPage }) => {
  const history = useHistory()
  useEffect(() => {
    if (page?.redirectPath) {
      history.push(page.redirectPath)
      removeRedirectPage()
    }
  }, [history, page, removeRedirectPage])

  const { isFluid, isVertical, navbarStyle } = useContext(AppContext)

  const isKanban = getPageName("kanban")

  return (
    <div className={isFluid || isKanban ? "container-fluid" : "container"}>
      {isVertical && (
        <NavbarVertical isKanban={isKanban} navbarStyle={navbarStyle} />
      )}
      <div className="content">
        <NavbarTop />
        <Switch>
          <Route path="/" exact component={MainDashboard} />
          {/* Devices */}
          <Route path="/devices" exact component={Devices} />
          <Route path="/devices/:deviceId" component={Device} />
          <Route path="/devices/:deviceId/details" exact component={Device} />
          <Route path="/devices/:deviceId/agreement" exact component={Device} />
          <Route path="/devices/:deviceId/persons" exact component={Device} />
          <Route path="/devices/:deviceId/service" exact component={Device} />
          {/* Departments */}
          <Route path="/departments" exact component={Departments} />
          <Route path="/departments/:departmentId" component={Department} />
          {/* AGREEMENTS */}
          <Route path="/agreements" exact component={Agreements} />
          <Route path="/agreements/:agreementId" exact component={Agreement} />
          {/* SERVICE */}
          <Route path="/services" exact component={Services} />
          <Route path="/services/:serviceId" exact component={Service} />
          <Route
            path="/services/:serviceId/details"
            exact
            component={Service}
          />
          <Route
            path="/services/:serviceId/settlements"
            exact
            component={Service}
          />
          {/* SETTLEMENTS */}
          <Route path="/settlements" exact component={Settlements} />
          <Route path="/settlements/:settlementId" component={Settlement} />
          {/* OFFERS */}
          <Route path="/offers" exact component={Offers} />
          <Route path="/offers/service" exact component={Offers} />
          <Route path="/offers/commercial" exact component={Offers} />
          <Route path="/offers/:offerId" component={Offer} />
          {/* PROFILE */}
          <Route path="/profile" exact component={UserDetails} />
          {/* CHANGELOG */}
          <Route path="/changelog" exact component={ChangeLog} />
          <Redirect to="/errors/404" />
        </Switch>

        {!isKanban && <Footer />}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    page: state.page,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    removeRedirectPage: () => dispatch(removeRedirectPage()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientDashboardLayout)

export const monthOptions = new Array(12).fill(null).map((_, index) => {
  const date = new Date(0, index)
  const currentMonth = new Date().getMonth()

  if (index === currentMonth) {
    return {
      value: index + 1,
      label: date.toLocaleString("pl", { month: "long" }),
    }
  }

  return {
    value: index + 1,
    label: date.toLocaleString("pl", { month: "long" }),
  }
})

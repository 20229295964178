import React from "react"
import { Switch, Route, useHistory, Redirect } from "react-router-dom"
import { connect } from "react-redux"
import { Card, CardBody, Col, Input, Row } from "reactstrap"
import FalconCardHeader from "components/common/FalconCardHeader"
import ButtonIcon from "components/common/ButtonIcon"
import Breadcrumb from "components/common/Breadcrumb"
import AddAttachment from "components/actionModals/AddAttachment"
import AddLink from "components/actionModals/AddLink"
import EditTicket from "components/actionModals/EditTicketModal"
import AddOffer from "components/actionModals/AddOffer"
import styled from "styled-components"
import TicketChat from "components/common/TicketChat"
import { useQuery } from "@apollo/client"
import { toast } from "react-toastify"
import { LOAD_TICKET, LOAD_TICKET_VISITS } from "GraphQL/Queries"
import { settings } from "config"
import moment from "moment"
import RemoveModal from "components/common/RemoveModal"
import { DELETE, GET, GET_FILE, POST } from "helpers/axios"
import TrashButton from "components/common/TrashButton"
import { reduceId, getId } from "helpers/utils"
import PlanServiceModal from "components/actionModals/PlanServiceModal"
import AddSettlement from "components/actionModals/AddSettlement"
import ServiceDetailsModal from "components/dataModals/ServiceDetailsModal"
import { setGlobalLoading } from "redux/actions/index"
import ServiceNavigation from "./ServiceNavigation"
import SettlementsTable from "components/dataTables/SettlementsTable/SettlementsTable"
import Loader from "components/common/Loader"
import PropTypes from "prop-types"

const ServiceVisits = () => {
  return (
    <div>This route will be refactored</div>
    // <Card className="mb-3">
    //   <FalconCardHeader title="Powiązane wizyty serwisowe:" />
    //   <CardBody className="fs--1 p-0">
    //     <AttachmentsLayout>
    //       {relatedVisitsArray.length ? (
    //         relatedVisitsArray.map((visit) => (
    //           <VisitTitle
    //             key={visit._id}
    //             className="text-link-blue-600-underline-hover"
    //           // onClick={() => {
    //           //   setServiceDetailsModalParams({
    //           //     isOpen: true,
    //           //     serviceData: visit
    //           //   });
    //           // }}
    //           >{`- wizyta #${visit._id}`}</VisitTitle>
    //         ))
    //       ) : (
    //         <p>brak wizyt</p>
    //       )}
    //     </AttachmentsLayout>
    //   </CardBody>
    // </Card>
  )
}

export default ServiceVisits

const AttachmentsLayout = styled.div`
  padding: 20px 20px;
  display: flex;
  flex-flow: column;
`

const VisitTitle = styled.p`
  font-size: 18px;
  font-weight: 600;
`

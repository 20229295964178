import React from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { Fragment } from "react"
import PropTypes from "prop-types"

const BreadcrumbArea = styled.div`
  padding: 10px 0 0 20px;
  font-size: 0.8rem;
  color: #1c4f93;

  a {
    color: #1c4f93;
  }
`

const Breadcrumb = ({ links }) => (
  <BreadcrumbArea>
    {links.map(({ name, to }) => (
      <Fragment key={name + to}>
        {to ? <Link to={to}>{name}</Link> : <span>{name}</span>}
        {" / "}
      </Fragment>
    ))}
  </BreadcrumbArea>
)

Breadcrumb.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      to: PropTypes.string,
    })
  ).isRequired,
}

export default Breadcrumb

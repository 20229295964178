const createSettlementsTabs = (includeContractSettlements) => {
  const tabs = [
    { label: "Rozliczenia serwisowe", roles: [] },
    { label: "Rozliczenia RW", roles: ["ROLE_TMB"] },
  ]

  if (includeContractSettlements) {
    tabs.push({ label: "Rozliczenia umów", roles: [] })
  }

  return tabs
}

export const settlementsTabs = createSettlementsTabs(true)
export const newServiceSettlementsTabs = createSettlementsTabs(false)
export const clientsSettlementsTabs = createSettlementsTabs(false)

import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"

const TrashButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.35rem;
  cursor: pointer;
`

const TrashButton = ({ onClick, hidden }) => (
  <TrashButtonWrapper
    onClick={() => onClick()}
    className={`font-danger ${hidden && "d-none"}`}
  >
    <FontAwesomeIcon
      icon="trash"
      color={"danger"}
      transform="shrink-6 right-0.3 down-0.3"
    />
  </TrashButtonWrapper>
)

export default TrashButton

import { CardHeader } from "reactstrap"
import React from "react"

export const StyledCardHeader = ({ children }) => {
  return (
    <CardHeader
      className="border-0"
      style={{
        backgroundColor: "#F4F7FF",
      }}
    >
      {children}
    </CardHeader>
  )
}

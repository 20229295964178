import React from "react"
import * as S from "./Filters.styled"

import Select from "./Select/Select"
import Date from "./Date/Date"
import DateRangePicker from "./DateRagePicker/DateRangePicker"
import CheckboxGroup from "./CheckboxGroup/CheckboxGroup"

const Pages = ({ currentPage, totalPages }) => {
  if (currentPage && totalPages) {
    return (
      <span>
        {currentPage}/{totalPages}
      </span>
    )
  }

  return null
}

const Filters = ({ currentPage, totalPages, children, showFilters }) => {
  return showFilters ? (
    <S.Wrapper>
      <Pages currentPage={currentPage} totalPages={totalPages} />
      <S.CenteredLabel>{children}</S.CenteredLabel>
    </S.Wrapper>
  ) : null
}

Filters.Select = Select
Filters.Date = Date
Filters.DateRangePicker = DateRangePicker
Filters.CheckboxGroup = CheckboxGroup

export default Filters

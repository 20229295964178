import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { setReloadTrue } from "redux/actions/index"
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  FormGroup,
  Input,
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { GET, PATCH } from "helpers/axios"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"

const VisitReceptionModal = ({
  isOpen,
  handleCloseModal,
  departmentId,
  visitId,
  refetch,
}) => {
  const [form, setForm] = useState({
    tmbPerson: "",
    tmbPersonCustom: "",
    nextVisitDate: "",
  })
  const [isClientSelectDisabled, setIsClientSelectDisabled] = useState(false)
  const [clientPeople, setClientPeople] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  const history = useHistory()

  useEffect(() => {
    if (!departmentId) return

    GET(
      `client_people?Department=${departmentId}&pagination=false&activeUserID=null`
    ).then((res) => {
      let clientPeopleArr = []
      const loadedClientPeople = res.data["hydra:member"]
      loadedClientPeople
        .filter((el) => el.user)
        .map((el) =>
          clientPeopleArr.push({
            id: el.id,
            name: el.user ? `${el.user.name} ${el.user.surname}` : "",
          })
        )

      setClientPeople(clientPeopleArr)
    })
  }, [departmentId])

  const handleSubmit = (isAccepted) => {
    if (!form.tmbPersonCustom) return toast.error("Wybierz osobę przyjmującą")

    setIsSubmitting(true)

    PATCH(`/api/service_visits/${visitId}/accept-by-tmb-person`, {
      acceptancePerson: form.tmbPersonCustom,
      operation: isAccepted ? "to_client_accept" : "to_client_rejection",
    })
      .then((res) => {
        handleCloseModal()
        toast.success("Wizyta została przyjęta")
        refetch ? refetch() : history.go(0)
      })
      .catch((err) => {
        return toast.error("Wystąpił błąd")
      })
      .finally(() => setIsSubmitting(false))
  }

  return (
    <Modal
      isOpen={isOpen}
      modalClassName="modal-fixed-right modal-theme"
      className="modal-dialog-vertical"
      contentClassName="min-vh-100 w-500 border-0"
      toggle={handleCloseModal}
    >
      <div className="position-absolute t-0 r-0  z-index-1">
        <Button
          size="sm"
          className="close close-circle d-flex flex-center transition-base mt-3 mr-3"
          onClick={() => handleCloseModal()}
        >
          <FontAwesomeIcon
            icon="times"
            transform="shrink-6 right-0.3 down-0.3"
          />
        </Button>
      </div>
      <ModalHeader tag="div" className="modal-header-settings">
        <h5 className="mb-0">Akceptacja/Odrzucenie wizyty serwisowej</h5>
      </ModalHeader>
      <ModalBody className="px-card pb-card pt-3 fs--1">
        <FormGroup>
          <Label for="clientPeople">Wybierz osobę</Label>
          <Input
            type="select"
            name="clientPeople"
            id="clientPeople"
            disabled={isClientSelectDisabled}
            onChange={(e) =>
              setForm({
                ...form,
                tmbPerson: e.target.value,
                tmbPersonCustom: e.target.value
                  ? clientPeople.find((person) => person.id == e.target.value)
                      ?.name
                  : "",
              })
            }
            value={form.tmbPerson}
          >
            <option value="">Nieprzypisany</option>
            {clientPeople.map((el) =>
              el.name.length > 0 ? (
                <option key={el.id} value={el.id}>
                  {el.name}
                </option>
              ) : null
            )}
          </Input>
        </FormGroup>
        <FormGroup>
          <Input
            type="text"
            placeholder="Osoba"
            value={form.tmbPersonCustom}
            onChange={(e) => {
              setForm({ ...form, tmbPersonCustom: e.target.value })
              return e.target.value
                ? setIsClientSelectDisabled(true)
                : setIsClientSelectDisabled(false)
            }}
          />
        </FormGroup>
        <Button
          color="success"
          onClick={() => handleSubmit(true)}
          disabled={isSubmitting}
        >
          Akceptuj
        </Button>
        <Button
          color="danger"
          onClick={() => handleSubmit(false)}
          disabled={isSubmitting}
        >
          Odmów
        </Button>
      </ModalBody>
    </Modal>
  )
}

export default VisitReceptionModal

import useGetData from "./useGetData"

const URL = "ticket_settlements/statuses"
const useGetStatusList = () => {
  const { data, error, loading, fetchData } = useGetData(URL)

  const statusList = Object.keys(data || {}).map((key) => ({
    value: key,
    label: data[key],
  }))

  return { statusList, error, loading, refetch: fetchData }
}

export default useGetStatusList

import styled from "styled-components"

export const CenteredLabel = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px 0;
  flex-wrap: wrap;
  color: #b6c2d2;
`

export const Wrapper = styled.div`
  justify-content: flex-end;
  align-items: center;
  padding: 15px 20px;
  background-color: #f4f7ff;
  border-top: 1px solid #d8e2ee;
  border-bottom: 1px solid #d8e2ee;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const pageReducer = (state = null, action) => {
  switch (action.type) {
    case "SET_REDIRECT_PAGE":
      return {
        ...state,
        ...action.payload,
      }
    case "REMOVE_REDIRECT_PAGE":
      return null
    default:
      return state
  }
}

export default pageReducer

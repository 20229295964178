import { useCallback, useEffect, useState } from "react"
import { GET } from "../helpers/axios"

const useGetData = (url) => {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  const fetchData = useCallback(async () => {
    setLoading(true)
    try {
      const response = await GET(url)
      setData(response.data)
    } catch (err) {
      setError(err)
      if (err.response.status === 404) {
        return (window.location.href = "/404")
      }
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [])

  return { data, error, loading, refetch: fetchData }
}

export default useGetData

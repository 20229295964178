import React from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap"
import PropTypes from "prop-types"

const RemoveModal = ({ title, content, isOpen, cancel, confirm }) => (
  <>
    <Modal isOpen={isOpen}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>{content}</ModalBody>
      <ModalFooter>
        <Button onClick={() => cancel()}>Anuluj</Button>
        <Button color="danger" onClick={() => confirm()}>
          Usuń
        </Button>
      </ModalFooter>
    </Modal>
  </>
)

RemoveModal.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  cancel: PropTypes.func.isRequired,
}

RemoveModal.defaultProps = {
  isOpen: false,
}

export default RemoveModal

import React, { useState, useEffect } from "react"
import { BrowserView, MobileView } from "react-device-detect"
import { connect } from "react-redux"
import { setGlobalLoading } from "redux/actions"
import { setReloadTrue } from "redux/actions/index"
import { Modal, ModalHeader } from "reactstrap"
import { Button, Form, FormGroup, Input, Label } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { GET, POST } from "helpers/axios"
import { toast } from "react-toastify"
import styled from "styled-components"
import Loader from "components/common/Loader"
import { formatCurrency, reduceId } from "helpers/utils"
import ProductSelectorValidator from "../common/ProductSelectorValidator"
import ActivitiesSelector from "../common/ActivitiesSelector"
import { Link } from "react-router-dom"

const ModalDual = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`

const ShowOnMobile = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`

const HideOnMobile = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`

const PlanServiceModal = ({
  ticketId,
  isOpen,
  handleCloseModal,
  deviceId,
  setReloadTrue,
  setGlobalLoading,
  refetch,
  isDelivery,
  description,
}) => {
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [productTypes, setProductTypes] = useState([])
  const [familyTypes, setFamilyTypes] = useState([])
  const [manufacturers, setManufacturers] = useState([])

  useEffect(() => {
    GET(`product_types`).then(({ data }) => {
      const filteredData = data["hydra:member"].filter((item) => item.name)

      setProductTypes(filteredData)
    })
  }, [])

  useEffect(() => {
    GET(`family_types`).then(({ data }) => {
      const filteredData = data["hydra:member"].filter((item) => item.name)

      setFamilyTypes(filteredData)
    })
  }, [])

  useEffect(() => {
    GET(`manufacturers`).then(({ data }) => {
      const filteredData = data["hydra:member"].filter((item) => item.name)

      setManufacturers(filteredData)
    })
  }, [])

  const [form, setForm] = useState({
    description: description,
    isCostOverrun: false,
    courierId: "",
    waybillNumber: "",
  })
  const [filters, setFilters] = useState({
    manufacturer: "",
    productType: "",
    familyType: "",
    model: "",
  })

  const [tmbPeople, setTmbPeople] = useState([])
  useEffect(() => {
    GET(
      `tmb_people?roles[]=ROLE_TMB_SERVICEMAN&roles[]=ROLE_TMB_SALESMAN&pagination=false${
        isDelivery ? "&roles[]=ROLE_TMB_CUSTOMER_SERVICE" : ""
      }&activeUserID=null`
    ).then((res) => {
      let tmbPeopleArr = []
      const loadedTmbPeople = res.data["hydra:member"]
      loadedTmbPeople.map((el) =>
        tmbPeopleArr.push({
          id: el.id,
          name: el.user ? `${el.user.name} ${el.user.surname}` : "",
        })
      )
      setTmbPeople(tmbPeopleArr)
    })
  }, [])

  const [couriers, setCouriers] = useState([])
  useEffect(() => {
    GET(`couriers`)
      .then((res) => {
        const loadedCouriers = res.data["hydra:member"]
        setCouriers(loadedCouriers)
      })
      .catch((err) =>
        toast.error("Nie udało się załadować kurierów, spróbuj ponownie.")
      )
  }, [])

  const [submitting, setSubmitting] = useState(false)
  const [agreementProducts, setAgreementProducts] = useState([])
  const [selected, setSelected] = useState([])
  const [offerType] = useState("0")
  const [selectedActivities, setSelectedActivities] = useState([])
  const [totalPrice, setTotalPrice] = useState(0)
  const [activeAgreement, setActiveAgreement] = useState(null)

  useEffect(() => {
    GET(`devices/${deviceId}/active-agreement`)
      .then((res) => {
        console.log(res.data)
        setActiveAgreement(res.data)
      })
      .catch((err) =>
        toast.error("Nie udało się załadować aktywnej umowy, spróbuj ponownie.")
      )
  }, [])

  useEffect(() => {
    let total = 0
    selected.map(
      (product) => (total += parseFloat(product.price * product.quantity))
    )
    setTotalPrice(total)
  }, [selected])

  const wrongActivities = selected.find(
    (el) =>
      typeof el.quantity !== "number" || el.quantity === "" || el.price === ""
  )

  const handleAddServiceVisit = () => {
    if (!isDelivery && !(selectedActivities.length > 0)) {
      return toast.error("Wybierz przynajmniej jedną czynność serwisową")
    }

    if (isDelivery && (!form.courierId || !form.waybillNumber)) {
      return toast.error("Uzupełnij dane kuriera")
    }

    if (wrongActivities) {
      return toast.error("Sprawdź poprawność aktywności serwisowych")
    }

    const activitiesArr = selected.map((el) => {
      const { id, model, quantity, price, leadTime } = el
      return {
        id,
        model,
        quantity,
        price: parseFloat(price).toFixed(2),
        leadTime,
      }
    })
    const model = {
      description: form.description,
      ticket: ticketId,
      tmbPerson: form.tmbPerson,
      activities: [...activitiesArr],
      serviceOperations: [...selectedActivities],
      courier: isDelivery ? `/api/couriers/${form.courierId}` : undefined,
      courierDeliveryNumber: isDelivery ? form.waybillNumber : undefined,
    }

    setSubmitting(true)
    POST(isDelivery ? "service_visits_by_delivery" : "service_visits", model)
      .then((res) => {
        setReloadTrue()
        handleCloseModal()
        refetch()
      })
      .catch((err) => {
        toast.error("Wystąpił błąd")
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        modalClassName="modal-fixed-right-wide modal-theme"
        className="modal-dialog-vertical"
        contentClassName="min-vh-100 w-500 border-0"
        toggle={handleCloseModal}
      >
        <div className="position-absolute t-0 r-0  z-index-1">
          <Button
            size="sm"
            className="close close-circle d-flex flex-center transition-base mt-3 mr-3"
            onClick={() => handleCloseModal()}
          >
            <FontAwesomeIcon
              icon="times"
              transform="shrink-6 right-0.3 down-0.3"
            />
          </Button>
        </div>
        <ModalHeader tag="div" className="modal-header-settings">
          <div className="py-1 flex-grow-1">
            <h5>{isDelivery ? "Obsługa kurierem" : "Planowanie serwisu"}</h5>
          </div>
        </ModalHeader>
        <>
          <Form className="p-4">
            <ModalDual>
              {/* LEFT SIDE */}
              <div>
                <FormGroup>
                  <Label for="clientPerson">Umowa:</Label>
                  {activeAgreement ? (
                    <p>
                      <Link
                        to={`/agreements/${activeAgreement.id}`}
                        className="font-weight-semi-bold text-link-blue"
                      >
                        {activeAgreement.name}
                      </Link>
                    </p>
                  ) : (
                    <p>Brak aktywnej umowy</p>
                  )}
                </FormGroup>
                {activeAgreement?.warehouseProductCopy?.length > 0 && (
                  <FormGroup>
                    <Label>Pozycje z umowy:</Label>
                    {activeAgreement?.warehouseProductCopy?.map((activity) => (
                      <div key={activity.id}>
                        {activity.model}
                        <Button
                          size="sm"
                          className="ml-2 px-2 py-1"
                          color="falcon-default"
                          onClick={() =>
                            setFilters({ ...filters, model: activity.model })
                          }
                        >
                          <FontAwesomeIcon icon="search" size="sm" />
                        </Button>
                      </div>
                    ))}
                  </FormGroup>
                )}
                <FormGroup>
                  <Label for="filters">Wybierz produkt</Label>
                  {offerType === "0" && (
                    <>
                      <Input
                        type="select"
                        className="mb-2"
                        onChange={(e) =>
                          setFilters({
                            ...filters,
                            productType: e.target.value,
                          })
                        }
                      >
                        <option value="">Typ</option>
                        {productTypes.map((el) => (
                          <option key={el.id} value={el.id}>
                            {el.name}
                          </option>
                        ))}
                      </Input>
                      <Input
                        type="select"
                        className="mb-2"
                        onChange={(e) =>
                          setFilters({
                            ...filters,
                            familyType: e.target.value,
                          })
                        }
                      >
                        <option value="">Rodzaj</option>
                        {familyTypes.map((el) => (
                          <option key={el.id} value={el.id}>
                            {el.name}
                          </option>
                        ))}
                      </Input>
                    </>
                  )}
                  <Input
                    type="select"
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        manufacturer: e.target.value,
                      })
                    }
                  >
                    <option value="">Producent</option>
                    {manufacturers.map((el) => (
                      <option key={el.id} value={el.id}>
                        {el.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Input
                    placeholder="Wyszukaj"
                    value={filters.model}
                    onChange={(e) =>
                      setFilters({ ...filters, model: e.target.value })
                    }
                  />
                </FormGroup>
                <ShowOnMobile>
                  {window.matchMedia("(max-width: 768px)").matches && (
                    <ProductSelectorValidator
                      filters={filters}
                      selected={selected}
                      setSelected={setSelected}
                      agreementProducts={agreementProducts}
                      btnDisabled={btnDisabled}
                      setBtnDisabled={setBtnDisabled}
                      deviceId={deviceId}
                    />
                  )}
                </ShowOnMobile>
                {isDelivery && (
                  <FormGroup>
                    <Label for="courier">Kurier</Label>
                    <Input
                      type="select"
                      onChange={(e) =>
                        setForm({
                          ...form,
                          courierId: e.target.value,
                        })
                      }
                    >
                      <option value="">Kurier</option>
                      {couriers.map((el) => (
                        <option key={el.id} value={el.id}>
                          {el.name}
                        </option>
                      ))}
                    </Input>
                    <Input
                      type="text"
                      className="mt-2"
                      placeholder="Numer listu przewozowego"
                      name="waybillNumber"
                      value={form.waybillNumber}
                      onChange={({ target: { value } }) =>
                        setForm({ ...form, waybillNumber: value })
                      }
                    />
                  </FormGroup>
                )}
                <FormGroup>
                  <ActivitiesSelector
                    selectedActivities={selectedActivities}
                    setSelectedActivities={setSelectedActivities}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="startDate">Przypisz pracownika</Label>
                  <Input
                    type="select"
                    name="tmbPeople"
                    id="tmbPeople"
                    onChange={(e) =>
                      setForm({
                        ...form,
                        tmbPerson: `/api/tmb_people/${e.target.value}`,
                      })
                    }
                  >
                    <option value={null}>Nieprzypisany</option>
                    {tmbPeople.map((el) =>
                      el.name.length > 0 ? (
                        <option value={el.id}>{el.name}</option>
                      ) : null
                    )}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label className="mt-3" for="note">
                    Notatka (Informacja niewidoczna dla klienta)
                  </Label>
                  <Input
                    type="textarea"
                    rows={5}
                    name="description"
                    id="description"
                    value={form.description}
                    onChange={(e) =>
                      setForm({ ...form, description: e.target.value })
                    }
                  />
                </FormGroup>
                <p>
                  <b>Suma: </b>
                  {formatCurrency(totalPrice)}
                </p>
              </div>

              {/* RIGHT SIDE */}
              <div>
                <HideOnMobile>
                  {window.matchMedia("(min-width: 768px)").matches && (
                    <ProductSelectorValidator
                      filters={filters}
                      selected={selected}
                      setSelected={setSelected}
                      agreementProducts={agreementProducts}
                      btnDisabled={btnDisabled}
                      setBtnDisabled={setBtnDisabled}
                      deviceId={deviceId}
                    />
                  )}
                </HideOnMobile>
              </div>
            </ModalDual>

            <Button
              className="mt-3"
              color="success"
              onClick={() => handleAddServiceVisit()}
              disabled={submitting || !!wrongActivities}
            >
              Zapisz
            </Button>
          </Form>
        </>
      </Modal>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setReloadTrue: () => dispatch(setReloadTrue()),
    setGlobalLoading: (component, value) =>
      dispatch(setGlobalLoading({ [component]: value })),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanServiceModal)

import React from "react"
import { CheckIcon } from "../../icons/icons"
import { DoubleData } from "./SettlementDetails"

const FEE_TYPES = {
  MONO: "mono",
  COLOR: "color",
  SCAN: "scan",
}

export const feeData = (type) => {
  const commonFees = [
    { key: "packageFee", label: "Opłata za paket stron" },
    { key: "packageMonoFee", label: "Opłata za strony mono ponad umowę" },
    { key: "packageColorFee", label: "Opłata za strony kolor ponad umowę" },
    { key: "scanFee", label: "Opłata za skany" },
  ]

  if (type === 1) {
    return [
      { key: "subscriptionFee", label: "Opłata za wynajem" },
      ...commonFees,
    ]
  } else if (type === 2) {
    return commonFees
  } else {
    return []
  }
}

const calculateFee = (type, data) => {
  const startCounter = data[`start_counter_${type}`]
  const endCounter = data[`end_counter_${type}`]
  const agreement = data.agreement || {}
  let pack, cost

  switch (type) {
    case FEE_TYPES.MONO:
      pack = agreement.bwPagePack
      cost = agreement.costPageBw
      break
    case FEE_TYPES.COLOR:
      pack = agreement.colorPagePack
      cost = agreement.costPageColor
      break
    case FEE_TYPES.SCAN:
      pack = agreement.scanPagePack
      cost = agreement.costPageScan
      break
    default:
      return 0
  }

  if (
    startCounter === undefined ||
    endCounter === undefined ||
    pack === undefined
  ) {
    return 0
  }

  const counterToBeSettled = endCounter - startCounter

  const fee = counterToBeSettled > pack ? (counterToBeSettled - pack) * cost : 0

  return parseFloat(fee.toFixed(2))
}

const getFeeValue = (key, data) => {
  const agreement = data.agreement || {}
  const agreementType = agreement.type

  let subscriptionFee, packageFee

  if (agreementType === 1) {
    subscriptionFee = agreement.deviceFee
    packageFee = agreement.subscriptionFee
  } else if (agreementType === 2) {
    packageFee = agreement.subscriptionFee
    subscriptionFee = 0
  }

  switch (key) {
    case "subscriptionFee":
      return subscriptionFee
    case "packageFee":
      return packageFee
    case "packageMonoFee":
      return calculateFee(FEE_TYPES.MONO, data)
    case "packageColorFee":
      return calculateFee(FEE_TYPES.COLOR, data)
    case "scanFee":
      return calculateFee(FEE_TYPES.SCAN, data)
    default:
      return 0
  }
}

export const calcTotalAgreementSum = (data) => {
  return feeData(data?.agreement?.type).reduce((acc, { key }) => {
    const sum = acc + getFeeValue(key, data)
    return sum
  }, 0)
}

const Fee = ({ data }) => {
  return (
    <>
      {feeData(data.agreement.type).map(({ key, label }) => {
        return (
          <DoubleData key={key}>
            <span className="mr-3">
              <span className="p-2">
                <CheckIcon />
              </span>
              <span>
                <b>{label}:</b>
              </span>
            </span>
            <span>{getFeeValue(key, data)} zł</span>
          </DoubleData>
        )
      })}
    </>
  )
}

export default Fee

import React, { useState, useEffect, useRef } from "react"
import { connect } from "react-redux"
import { Card, CardBody, Form, FormGroup, Label, Input } from "reactstrap"
import FalconCardHeader from "components/common/FalconCardHeader"
import ButtonIcon from "components/common/ButtonIcon"
import Loader from "components/common/Loader"
import { TabContent } from "reactstrap"
import Scrollbar from "react-scrollbars-custom"
import Message from "./Message"
import PropTypes from "prop-types"
import { toast } from "react-toastify"
import { POST_ACTIVITY, POST_SINGLE_ATTACHMENT } from "helpers/axios"
import { formatMessageDate, validateFileFormat, getId } from "helpers/utils"
import { useAuthContext } from "context/AuthContext"

const TicketChat = ({ ticketId, loading, data, refetch, deviceId }) => {
  const [form, setForm] = useState({
    text: "",
    file: "",
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [sendingFile, setSendingFile] = useState(false)
  const { user } = useAuthContext()
  let lastDate = null

  const messages = React.useMemo(
    () =>
      data
        ?.filter((log) => log.note || log.serviceVisit || log.serviceOffer)
        .map((activityLog) => {
          return {
            senderUserId: activityLog.clientPerson ? 1 : 3,
            senderName: activityLog.clientPerson?.user
              ? `${activityLog.clientPerson.user.name} ${activityLog.clientPerson.user.surname}`
              : activityLog.tmbPerson?.user
              ? `${activityLog.tmbPerson.user.name} ${activityLog.tmbPerson.user.surname}`
              : "",
            senderAvatar: activityLog.clientPerson
              ? activityLog.clientPerson.avatar
              : activityLog.tmbPerson
              ? activityLog.tmbPerson.avatar
              : "",
            message: activityLog.note?.description || "",
            time: formatMessageDate(activityLog.createdAt),
            links: activityLog.note?.links || [],
            attachments: activityLog.note?.attachments || [],
            agreement: activityLog.agreement?.id,
            visitName: activityLog.serviceVisit?.name || null,
            visitStatus: activityLog.serviceVisit?.status || null,
            visitId: activityLog.serviceVisit?.id || null,
            visitType: activityLog.serviceVisit?.type || null,

            offerName: activityLog.serviceOffer?.name || null,
            offerStatus: activityLog.serviceOffer?.status || null,
            offerId: activityLog.serviceOffer?.id || null,
            offerType: activityLog.serviceOffer?.type || null,
            offerLabel: activityLog.serviceOffer?.label || null,
            emailSent: activityLog.serviceOffer?.emailSent,
            isRemoved: activityLog.isRemoved,
            activityId: activityLog.id,
          }
        }),
    [data]
  )

  // useEffect(() => {
  //   let messages = [];
  //   if (data && data.ticket.activityLog) {
  //     const ticket = data.ticket;
  //     data.ticket.activityLog.map((el) => {
  //       if (
  //         el.note ||
  //         (el.agreement &&
  //           el.agreement.status !== 'after_service_visit' &&
  //           !el.agreement.isFromServiceVisit)
  //       ) {
  //         messages.push({
  //           senderUserId: el.clientPerson ? 1 : 3,
  //           senderName:
  //             el.clientPerson && el.clientPerson.user
  //               ? `${el.clientPerson.user.name} ${el.clientPerson.user.surname}`
  //               : el.tmbPerson && el.tmbPerson.user
  //                 ? `${el.tmbPerson.user.name} ${el.tmbPerson.user.surname}`
  //                 : '',
  //           senderAvatar:
  //             el.clientPerson && el.clientPerson.user
  //               ? el.clientPerson.user.avatar
  //               : el.tmbPerson && el.tmbPerson.user
  //                 ? el.tmbPerson.user.avatar
  //                 : '',
  //           message: el.note ? el.note.description : '',
  //           attachments: el.note && el.note.attachments ? el.note.attachments : null,
  //           links: el.note && el.note.links ? el.note.links : null,
  //           time: formatMessageDate(el.createdAt),
  //           agreement: el.agreement ? el.agreement.id : null
  //         });
  //       } else if (!el.note && !el.agreement && !el.attachment && !el.link && el.serviceVisitId) {
  //         messages.push({
  //           senderUserId: el.clientPerson ? 1 : 3,
  //           senderName:
  //             el.clientPerson && el.clientPerson.user
  //               ? `${el.clientPerson.user.name} ${el.clientPerson.user.surname}`
  //               : el.tmbPerson && el.tmbPerson.user
  //                 ? `${el.tmbPerson.user.name} ${el.tmbPerson.user.surname}`
  //                 : '',
  //           senderAvatar:
  //             el.clientPerson && el.clientPerson.user
  //               ? el.clientPerson.user.avatar
  //               : el.tmbPerson && el.tmbPerson.user
  //                 ? el.tmbPerson.user.avatar
  //                 : '',
  //           time: formatMessageDate(el.createdAt),
  //           visits: ticket?.serviceVisits ? ticket.serviceVisits : null,
  //           visitId: el.serviceVisitId ? el.serviceVisitId : null
  //         });
  //       }
  //       return null;
  //     });
  //     setMessagesData(messages);
  //   }
  // }, [data]);

  const handleSendMessage = () => {
    if ((form.text || form.file) && !sendingFile) {
      const activityModel = {
        ticket_id: ticketId.replace("/api/tickets/", ""),
        device_id: null,
        note: {
          description: form.text,
          attachment: form.file
            ? [
                {
                  title: "",
                  file: form.file,
                },
              ]
            : null,
          link: null,
        },
        link: null,
        attachment: null,
        tmbPerson: user.tmbPerson ? user.tmbPerson.id.toString() : null,
        clientPerson: user.clientPerson
          ? user.clientPerson.id.toString()
          : null,
      }

      setIsSubmitting(true)

      POST_ACTIVITY(activityModel)
        .then(() => {
          setForm({ text: "", file: "" })
          refetch()
        })
        .catch((err) => {
          toast.error("Wystąpił błąd")
        })
        .finally(() => setIsSubmitting(false))
    } else if (sendingFile) toast.err("Załącznik nie został jeszcze wysłany")
  }

  const hiddenFileInput = useRef(null)
  const addMsgFile = () => {
    hiddenFileInput.current.click()
  }

  const handleSelectFile = (event) => {
    if (!event.target.files[0]) return

    if (event.target.files[0].size > 20971520) {
      event.target.value = null
      return toast.error("Rozmiar pliku jest za duży")
    }

    if (!validateFileFormat(event.target.files[0])) {
      event.target.value = null
      return toast.error("Nieobsługiwany format pliku")
    }

    setSendingFile(true)
    POST_SINGLE_ATTACHMENT(event.target.files[0], "false")
      .then((res) => {
        setForm({ ...form, file: res.data[0] })
      })
      .catch((err) => {
        toast.error("Błąd przesyłania pliku")
      })
      .finally(() => {
        setSendingFile(false)
      })
  }

  const [scrollHeight, setScrollHeight] = useState(0)
  useEffect(() => {
    setTimeout(() => {
      setScrollHeight(
        document.getElementsByClassName("chat-content-scroll-area")[0]
          ? document.getElementsByClassName("chat-content-scroll-area")[0]
              .scrollHeight
          : 0
      )
    }, 500)
  }, [])

  return (
    <Card className="mb-3 card-chat">
      <FalconCardHeader title="Historia zlecenia" />
      <CardBody className="fs--1 h-100 p-0">
        <TabContent className="card-chat-content fs--1 position-relative">
          {loading ? (
            <Loader />
          ) : (
            <div
              className="chat-content-body h-100"
              style={{ display: "inherit" }}
            >
              <Scrollbar
                style={{
                  height: "100%",
                  minWidth: "75px",
                  display: "block",
                }}
                scrollTop={scrollHeight}
                noScrollX
                trackYProps={{
                  renderer(props) {
                    const { elementRef, ...restProps } = props
                    return (
                      <span
                        {...restProps}
                        ref={elementRef}
                        className="TrackY"
                      />
                    )
                  },
                }}
              >
                <div className="chat-content-scroll-area">
                  {messages?.map((props, index) => {
                    return (
                      <div key={index}>
                        {lastDate !== props.time.date && (
                          <div className="text-center fs--2 text-500">{`${props.time.date}, ${props.time.hour}`}</div>
                        )}
                        {(() => {
                          lastDate = props.time.date
                        })()}
                        <Message {...props} />
                      </div>
                    )
                  })}
                </div>
              </Scrollbar>
            </div>
          )}
        </TabContent>
      </CardBody>
      <Card>
        <CardBody>
          <Form>
            <FormGroup>
              <Label for="message">Dodaj odpowiedź</Label>
              <Input
                style={{ minHeight: "84px", maxHeight: "84px" }}
                type="textarea"
                rows={3}
                name="message"
                id="message"
                value={form.text}
                onChange={({ target: { value } }) =>
                  setForm({ ...form, text: value })
                }
                disabled={isSubmitting}
              />
            </FormGroup>
            <div className="d-flex justify-content-between">
              <div>
                {form.file && (
                  <p
                    onClick={() => setForm({ ...form, file: "" })}
                    style={{ cursor: "default" }}
                  >
                    {form.file}
                  </p>
                )}
              </div>
              <div className="d-flex justify-content-end">
                <ButtonIcon
                  icon="paperclip"
                  type="button"
                  size="sm"
                  color="secondary"
                  outline
                  onClick={() => addMsgFile()}
                  disabled={isSubmitting || sendingFile}
                >
                  Dodaj załącznik
                </ButtonIcon>
                <input
                  style={{ display: "none" }}
                  type="file"
                  name="msgFile"
                  id="msgFile"
                  ref={hiddenFileInput}
                  onChange={(e) => handleSelectFile(e)}
                />
                <ButtonIcon
                  icon={["far", "paper-plane"]}
                  type="button"
                  size="sm"
                  color="success"
                  onClick={() => handleSendMessage()}
                  disabled={isSubmitting || sendingFile}
                >
                  Wyślij
                </ButtonIcon>
              </div>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Card>
  )
}

TicketChat.propTypes = {
  ticketId: PropTypes.string.isRequired,
}

export default TicketChat

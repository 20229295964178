import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Media } from "reactstrap"
import Flex from "./Flex"
import classNames from "classnames"
import { DELETE, GET_FILE } from "helpers/axios"
import defaultProfile from "assets/img/default_user.png"
import Avatar from "./Avatar"
import { publicUploads } from "helpers/endpoints"
import Offer from "./Offer"
import MessageVisit from "./MessageVisit"
import MessageOffer from "./MessageOffer"
import { useAuthContext } from "context/AuthContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"

const Message = ({
  message,
  senderUserId,
  senderName,
  senderAvatar,
  time,
  attachments,
  links,
  agreement,
  user,
  ticketId,
  visitName,
  visitStatus,
  visitId,
  refetch,
  visitType,
  offerName,
  offerStatus,
  offerId,
  offerType,
  offerLabel,
  emailSent,
  activityId,
  isRemoved = false,
}) => {
  const { user: userContext } = useAuthContext()
  const isTmbUser =
    userContext.roles.includes("ROLE_TMB_CUSTOMER_SERVICE") ||
    userContext.roles.includes("ROLE_TMB_SERVICE_MANAGER") ||
    userContext.roles.includes("ROLE_TMB_ADMIN")
  console.log(userContext)
  const locationsHistory = useHistory()
  const isLeft =
    user.clientPerson === null ? senderUserId !== 3 : senderUserId === 3
  const isVisitMessage =
    attachments.length === 0 && links.length === 0 && !message && visitId
  const isOfferMessage =
    attachments.length === 0 && links.length === 0 && !message && offerId
  const { user: authUser } = useAuthContext()
  const isTrashVisible = isTmbUser && !visitId && !offerId

  if (isOfferMessage && offerType == 2 && !authUser.roles.includes("ROLE_TMB"))
    return null

  const handleDeleteMessage = () => {
    DELETE(`/api/activity_logs/${activityId}/delete`)
      .then((res) => {
        locationsHistory.go(0)
      })
      .catch(() => toast.error("Wystąpił błąd"))
  }

  return (
    <>
      <Media className={classNames("p-3", { "d-block": !isLeft })}>
        <Media
          body
          className={classNames({ "d-flex justify-content-end": !isLeft })}
        >
          <div
            className={classNames("w-xxl-75", {
              "w-100": !isLeft,
            })}
          >
            <Flex
              align="center"
              className={classNames("hover-actions-trigger", {
                "justify-content-end": !isLeft,
              })}
            >
              <>
                {isLeft && (
                  <Avatar
                    src={
                      senderAvatar
                        ? `${publicUploads}/${senderAvatar}`
                        : defaultProfile
                    }
                    className="mr-2"
                  />
                )}
                {!isLeft && !isRemoved && isTrashVisible && (
                  <FontAwesomeIcon
                    style={{ alignSelf: "flex-start", fontSize: "18px" }}
                    onClick={handleDeleteMessage}
                    icon="trash"
                    transform="shrink-4 down-1"
                    className="mr-2 cursor-pointer"
                  />
                )}
                <div
                  className={classNames("p-2 rounded-soft chat-message", {
                    "chat-box-user": !isLeft,
                    border: isVisitMessage,
                  })}
                  style={{
                    backgroundColor:
                      emailSent === false && offerType === 1
                        ? "rgba(255,0,0,0.15)"
                        : "#EDF2F9",
                  }}
                >
                  {isRemoved ? (
                    "Wiadomość usunięta"
                  ) : (
                    <>
                      {!!message && (
                        <p
                          className="mb-0"
                          dangerouslySetInnerHTML={{
                            __html: message,
                          }}
                        />
                      )}
                      {attachments?.map((attachment) => (
                        <span
                          onClick={() => GET_FILE(attachment.file, true)}
                          key={attachment.file}
                          className={`mb-0 ${"text-link-blue-underline"}`}
                          dangerouslySetInnerHTML={{
                            __html: attachment.title || attachment.file,
                          }}
                        />
                      ))}
                      {links?.map((attachment, index) => (
                        <a
                          key={index + attachment.linkAddress}
                          href={
                            attachment.linkAddress.includes("://")
                              ? attachment.linkAddress
                              : `https://${attachment.linkAddress}`
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className={`mb-0 ${"text-link-blue-underline"}`}
                        >
                          {attachment.title || attachment.linkAddress}
                        </a>
                      ))}
                      {isVisitMessage && (
                        <MessageVisit
                          name={visitName}
                          status={visitStatus}
                          visitId={visitId}
                          refetch={refetch}
                          visitType={visitType}
                        />
                      )}
                      {isOfferMessage && (
                        <MessageOffer
                          name={offerName}
                          status={offerStatus}
                          offerId={offerId}
                          offerType={offerType}
                          label={offerLabel}
                          emailSent={emailSent}
                        />
                      )}
                      {agreement && (
                        <Offer ticketId={ticketId} agreementId={agreement} />
                      )}
                    </>
                  )}
                </div>
                {isLeft && !isRemoved && isTrashVisible && (
                  <FontAwesomeIcon
                    style={{ alignSelf: "flex-start", fontSize: "18px" }}
                    onClick={handleDeleteMessage}
                    icon="trash"
                    transform="shrink-4 down-1"
                    className="ml-2 cursor-pointer"
                  />
                )}
                {!isLeft && (
                  <Avatar
                    src={
                      senderAvatar
                        ? `${publicUploads}/${senderAvatar}`
                        : defaultProfile
                    }
                    className="ml-2"
                  />
                )}
              </>
            </Flex>
            <div
              className={classNames("text-400 fs--2 mt-1", {
                "text-right": !isLeft,
              })}
            >
              {senderName} {time.hour}
            </div>
          </div>
        </Media>
      </Media>
    </>
  )
}

Message.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  senderUserId: PropTypes.number.isRequired,
  time: PropTypes.object.isRequired,
}

Message.defaultProps = { status: "" }

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(Message)

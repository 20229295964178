import React from "react"

import { FormGroup, Input, Label } from "reactstrap"

const Date = ({ label, onChange, isDisabled, placeholder }) => {
  return (
    <>
      <Label className="m-0 mr-2">{label}</Label>
      <FormGroup className="m-0 mr-2">
        <Input
          bsSize="sm"
          type="date"
          disabled={isDisabled}
          placeholder={placeholder}
          onChange={onChange}
        />
        e
      </FormGroup>
    </>
  )
}

export default Date

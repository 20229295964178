import { DoubleData } from "./SettlementDetails/SettlementDetails"
import React from "react"

const DoubleDataComponent = ({ label, value }) => {
  return (
    <DoubleData>
      <span>
        <b>{label}:</b>
      </span>
      <span>{value}</span>
    </DoubleData>
  )
}

export default DoubleDataComponent

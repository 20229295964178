import React from "react"
import { connect } from "react-redux"
import { setReloadTrue } from "redux/actions/index"
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"
import styled from "styled-components"
import { settings } from "config"
import { visitStatusFormatter } from "helpers/utils"

const SingleData = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 200px minmax(155px, auto);
  font-size: 0.9rem;
  margin-bottom: 10px;

  @media (max-width: 1539px) {
    grid-template-columns: 150px 1fr;
  }
  @media (max-width: 1199px) {
    grid-template-columns: 200px 1fr;
  }
`

const ServiceDetailsModal = ({
  isOpen,
  handleCloseModal,
  serviceData = { name: "" },
}) => {
  return (
    <Modal
      isOpen={isOpen}
      modalClassName="modal-fixed-right modal-theme"
      className="modal-dialog-vertical"
      contentClassName="min-vh-100 w-500 border-0"
      toggle={handleCloseModal}
    >
      <div className="position-absolute t-0 r-0  z-index-1">
        <Button
          size="sm"
          className="close close-circle d-flex flex-center transition-base mt-3 mr-3"
          onClick={() => handleCloseModal()}
        >
          <FontAwesomeIcon
            icon="times"
            transform="shrink-6 right-0.3 down-0.3"
          />
        </Button>
      </div>
      <ModalHeader tag="div" className="modal-header-settings">
        <h5 className="mb-0">Szczegóły wizyty:</h5>
      </ModalHeader>
      <ModalBody className="px-card pb-card pt-3 fs--1">
        <SingleData>
          <span>
            <b>Opis:</b>
          </span>
          <span>{serviceData?.description}</span>
        </SingleData>
        <SingleData>
          <span>
            <b>Firma:</b>
          </span>
          <span>{serviceData?.name}</span>
        </SingleData>
        <SingleData>
          <span>
            <b>Status:</b>
          </span>
          <span>{visitStatusFormatter(serviceData?.status)}</span>
        </SingleData>

        <SingleData>
          <span>
            <b>Data Rozpoczęcia:</b>
          </span>
          <span>
            {serviceData?.datetimeStart
              ? moment(serviceData.datetimeStart).format(settings.dateFormat)
              : "brak daty"}
          </span>
        </SingleData>
        <SingleData>
          <span>
            <b>ID:</b>
          </span>
          <span>#{serviceData._id}</span>
        </SingleData>
      </ModalBody>
    </Modal>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setReloadTrue: () => dispatch(setReloadTrue()),
  }
}

export default connect(null, mapDispatchToProps)(ServiceDetailsModal)

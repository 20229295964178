import React from "react"
import styled from "styled-components"
import Loader from "components/common/Loader"
import { GET_FILE } from "helpers/axios"
import { Link } from "react-router-dom"
import moment from "moment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Card, CardBody } from "reactstrap"
import ButtonIcon from "../../common/ButtonIcon"

import { calcTotalSum } from "../../../helpers/settlements/calcTotalSum"
import Name from "./Name"
import Status from "./Status"
import { StyledCardHeader } from "../StyledCardHeader"
import DoubleDataComponent from "../DoubleDataComponent"
import useServiceChangeStatus from "../../../hooks/useServiceChangeStatus"
import { useAuthContext } from "../../../context/AuthContext"

export const DetailsLayout = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  font-size: 0.8rem;

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const DoubleData = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
`

const VisitWrapper = styled.div`
  margin-bottom: 15px;
`

const VisitHeading = styled.h5`
  display: flex;
  gap: 5px;
`

const OfferProductRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`

const ModelCheckboxRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`

const SettlementDetails = ({
  isDownloadVisible,
  settlementData,
  refetch,
  isLoading,
}) => {
  const { changeStatus, data: statusData } = useServiceChangeStatus(
    settlementData?.id
  )

  const { user } = useAuthContext()

  const roles = user.roles

  const isTMBPerson = roles.includes("ROLE_TMB")

  if (isLoading) {
    return <Loader />
  }

  const totalSum = calcTotalSum(
    settlementData?.additionalWarehouseProducts,
    settlementData?.ticketSettlementDetails
  )

  return (
    <DetailsLayout>
      <Card className="mb-3">
        <StyledCardHeader>
          <div
            style={{
              padding: "0.5rem 1rem",
            }}
          >
            <h5 className="mb-0">Szczegóły rozliczenia</h5>
          </div>
        </StyledCardHeader>
        <CardBody className="p-3 pb-3">
          <Column>
            <Name name={settlementData?.name} />
            {settlementData?.type !== 2 && (
              <Status
                label={settlementData?.label}
                status={settlementData?.status}
                refetchData={refetch}
                data={settlementData}
                statusData={statusData}
                changeStatus={changeStatus}
                type={"ticket"}
              />
            )}
            <DoubleData>
              <span className="mr-4">
                <b>Zlecenie:</b>
              </span>
              <span>
                {" "}
                <Link
                  to={`/services/${settlementData?.ticket?.id}/details`}
                  className="font-weight-semi-bold text-link-blue"
                >
                  {settlementData?.ticket?.name ?? "--"}
                </Link>
              </span>
            </DoubleData>
            <DoubleData>
              <span className="mr-4">
                <b>Urządzenie:</b>
              </span>
              <span>
                <Link
                  to={`/devices/${settlementData?.ticket?.device?.id}/details`}
                  className="font-weight-semi-bold text-link-blue"
                >
                  {settlementData?.ticket?.device?.serialNumber ?? "--"},{" "}
                  {settlementData?.ticket?.device?.warehausProduct?.model ??
                    "--"}
                </Link>
              </span>
            </DoubleData>
            <DoubleData>
              <span className="mr-4">
                <b>Kontrahent:</b>
              </span>
              <span>
                <Link
                  to={`/clients/${settlementData?.ticket?.organization?.id}/details`}
                  className="font-weight-semi-bold text-link-blue"
                >
                  {settlementData?.ticket?.organization?.name ?? "--"}
                </Link>
              </span>
            </DoubleData>
            <DoubleData>
              <span className="mr-4">
                <b>Data rozliczenia:</b>
              </span>
              <span>
                {settlementData?.createdAt
                  ? moment(settlementData?.createdAt).format("DD.MM.YYYY")
                  : "--"}
              </span>
            </DoubleData>
            {settlementData?.type !== 2 && (
              <DoubleData>
                <span className="mr-4">
                  <b>Kwota:</b>
                </span>
                <span>{totalSum} zł</span>
              </DoubleData>
            )}
            <DoubleData>
              <span className="mr-4">
                <b>Uwagi:</b>
              </span>
              <span>{settlementData?.description ?? "--"}</span>
            </DoubleData>

            <DoubleData>
              <span className="mr-4">
                <b>Email:</b>
              </span>
              <span>
                {settlementData?.emailRecipient
                  ? settlementData.emailRecipient.map(
                      (email, index) =>
                        `${email}${
                          !(index === settlementData.emailRecipient.length - 1)
                            ? ", "
                            : ""
                        }`
                    )
                  : "--"}
              </span>
            </DoubleData>
            <DoubleData>
              <span className="mr-4">
                <b>Email wysłany:</b>
              </span>
              <span>
                <FontAwesomeIcon
                  icon={settlementData?.emailSent ? "check" : "times"}
                />
              </span>
            </DoubleData>
          </Column>
        </CardBody>
      </Card>
      <Card className="mb-3">
        <StyledCardHeader>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h5 className="mb-0">
              {settlementData?.type === 2
                ? "Szczegóły RW"
                : "Szczegóły faktury"}
            </h5>
            {isDownloadVisible && (
              <ButtonIcon
                icon="download"
                transform="shrink-3 down-2"
                color="falcon-default"
                size="sm"
                onClick={async () => {
                  if (settlementData) {
                    await GET_FILE(settlementData.attachment).then(
                      async (res) => {
                        if (!isTMBPerson) {
                          await changeStatus()
                        }
                      }
                    )
                  }
                }}
              >
                Pobierz
              </ButtonIcon>
            )}
          </div>
        </StyledCardHeader>
        <CardBody className="p-3 pb-3">
          <DoubleDataComponent
            value={settlementData?.attachmentNameSubiekt}
            label={settlementData?.type === 2 ? "Numer RW" : "Numer faktury"}
          />
          <div
            style={{
              marginTop: "20px",
            }}
          >
            {settlementData?.ticketSettlementDetails.map(
              ({ serviceVisit, warehouseProducts }, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Link
                    to={`/services/${serviceVisit.id}/details`}
                    className={"font-weight-semi-bold text-link-blue"}
                  >
                    <h5 className={"text-link-blue"}>{serviceVisit.name}</h5>
                  </Link>

                  {serviceVisit.warehouseProductCopy.length > 0
                    ? serviceVisit.warehouseProductCopy.map((activity) => (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={
                              warehouseProducts.find(
                                (prod) => prod.id === activity.id
                              )
                                ? "check"
                                : "times"
                            }
                          />
                          <DoubleDataComponent
                            label={`${activity.model} - ${activity.quantity}szt`}
                            value={`${activity.price} zł`}
                          />
                        </div>
                      ))
                    : "Brak czynności"}
                </div>
              )
            )}
            {settlementData?.additionalWarehouseProducts?.length > 0 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  marginTop: "20px",
                }}
              >
                <h5>Dodatkowe rozliczone produkty:</h5>
                {settlementData?.additionalWarehouseProducts?.map(
                  (activity) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={
                          settlementData?.additionalWarehouseProducts.find(
                            (prod) => prod.id === activity.id
                          )
                            ? "check"
                            : "times"
                        }
                      />
                      <DoubleDataComponent
                        label={`${activity.model} - ${activity.quantity}szt`}
                        value={`${activity.price} zł`}
                      />
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    </DetailsLayout>
  )
}

export default SettlementDetails

import Badge, { getStatusColor } from "../../common/Badge"
import React, { Fragment, useEffect } from "react"
import { DoubleData } from "./SettlementDetails"
import { useAuthContext } from "../../../context/AuthContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Status = ({ label, data, statusData, changeStatus }) => {
  const { color, bgColor, icon } = getStatusColor(
    statusData?.status || data?.status
  )

  const { user } = useAuthContext()
  const roles = user.roles
  const isTMBPerson = roles.includes("ROLE_TMB")

  const Icon = icon ? icon : FontAwesomeIcon
  const StatusLink = () => {
    if (isTMBPerson) {
      return (
        <a
          className="font-weight-semi-bold text-link-blue"
          onClick={async () => await handleChangeStatus()}
        >
          {statusData?.button_name}
        </a>
      )
    } else {
      return null
    }
  }

  const handleChangeStatus = async () => {
    await changeStatus()
  }
  return (
    <DoubleData>
      <span className="mr-4">
        <b>Status:</b>
      </span>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "start",
          gap: "10px",
        }}
      >
        <Badge bgColor={bgColor} color={color}>
          <Icon color={color} />
          <span className="ml-1">{statusData?.statusLabel || label}</span>
        </Badge>
        <StatusLink />
      </div>
    </DoubleData>
  )
}

export default Status

import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { LOAD_AGREEMENT } from "GraphQL/Queries"
import { useQuery } from "@apollo/client"
import {
  POST,
  GET_FILE,
  ACCEPT_REJECT_OFFER,
  ACCEPT_REJECT,
} from "helpers/axios"
import { Button } from "reactstrap"
import { toast } from "react-toastify"
import { reduceId } from "helpers/utils"
import { connect } from "react-redux"
import AcceptRejectVisitModal from "../actionModals/AcceptRejectVisitModal"

const Offer = ({ agreementId, user, ticketId }) => {
  const history = useHistory()
  const { data, refetch } = useQuery(LOAD_AGREEMENT, {
    variables: { id: agreementId },
  })
  const [agreement, setAgreement] = useState({
    label: "",
    attachment: "",
  })
  const [statuses, setStatuses] = useState([])
  const [acceptOpened, setAcceptOpened] = useState(false)
  const [rejectOpened, setRejectOpened] = useState(false)

  useEffect(() => {
    if (data) setAgreement(data.agreement)
  }, [data])

  useEffect(() => {
    if (data && !acceptOpened)
      refetch().then(() => setAgreement(data.agreement))
    if (data && !rejectOpened)
      refetch().then(() => setAgreement(data.agreement))
  }, [acceptOpened, rejectOpened, data, refetch, agreementId])

  useEffect(() => {
    POST("workflow/get-agreement-status", {
      agreement_id: reduceId(agreementId),
    })
      .then((res) => {
        setStatuses(res.data)
      })
      .catch(() => toast.error("Błąd pobierania danych"))
  }, [agreement, agreementId])

  useEffect(() => {
    POST("workflow/get-agreement-status", {
      agreement_id: reduceId(agreementId),
    })
      .then((res) => setStatuses(res.data))
      .catch(() => toast.error("Błąd pobierania danych"))
  }, [agreementId, acceptOpened, rejectOpened])

  const handleAcceptOffer = () => {
    ACCEPT_REJECT_OFFER(
      reduceId(agreementId),
      reduceId(user.userId),
      `${user.fullName}`,
      true
    )
      .then((res) => {
        if (res?.data?.status === "success") {
          toast.success(res.data.message)
          POST("workflow/get-agreement-status", {
            agreement_id: reduceId(agreementId),
          })
            .then((res) => setStatuses(res.data))
            .catch(() => toast.error("Błąd pobierania danych"))
        }
        if (res?.data?.status === "error") toast.error(res.data.message)
        refetch().then(setAgreement(data.agreement))
      })
      .catch((err) => {
        toast.error("Wystąpił błąd")
      })
  }
  const handleRejectOffer = () => {
    ACCEPT_REJECT_OFFER(
      reduceId(agreementId),
      reduceId(user.userId),
      `${user.fullName}`,
      false
    )
      .then((res) => {
        if (res?.data?.status === "success") {
          toast.success(res.data.message)
          POST("workflow/get-agreement-status", {
            agreement_id: reduceId(agreementId),
          })
            .then((res) => setStatuses(res.data))
            .catch(() => toast.error("Błąd pobierania danych"))
        }
        if (res?.data?.status === "error") toast.error(res.data.message)
        refetch().then(() => {
          setAgreement(data.agreement)
          POST("workflow/get-agreement-status", {
            agreement_id: reduceId(agreementId),
          })
        })
      })
      .catch((err) => {
        toast.error("Wystąpił błąd")
      })
  }
  const handleAcceptReject = (btn_action_name) => {
    //some endpoint
    ACCEPT_REJECT(reduceId(agreementId), btn_action_name)
      .then((res) => {
        toast.success("Zmiana statusu")
        POST("workflow/get-agreement-status", {
          agreement_id: reduceId(agreementId),
        })
          .then((res) => setStatuses(res.data))
          .catch(() => toast.error("Błąd pobierania danych"))
        refetch().then(() => {
          setAgreement(data.agreement)
          POST("workflow/get-agreement-status", {
            agreement_id: reduceId(agreementId),
          })
        })
      })
      .catch(() => toast.error("Błąd pobierania danych"))
  }

  return (
    <>
      {acceptOpened && (
        <AcceptRejectVisitModal
          isOpen={acceptOpened}
          handleCloseModal={setAcceptOpened}
          accept={true}
          visitId={reduceId(agreementId)}
          isOffer={true}
          offerId={agreementId}
          ticketId={ticketId}
        />
      )}
      {rejectOpened && (
        <AcceptRejectVisitModal
          isOpen={rejectOpened}
          handleCloseModal={setRejectOpened}
          accept={false}
          visitId={reduceId(agreementId)}
          isOffer={true}
          offerId={agreementId}
          ticketId={ticketId}
        />
      )}
      {agreement.status && (
        <p
          className="mb-0"
          dangerouslySetInnerHTML={{
            __html:
              agreement.status === "after_service_visit"
                ? "<b>Wizyta serwisowa</b>"
                : "<b>Oferta serwisowa</b>",
          }}
        />
      )}
      <p
        className="mb-2"
        dangerouslySetInnerHTML={{
          __html: `<b>Status:</b> ${agreement.label}`,
        }}
      />
      {agreement.attachment && (
        <Button
          size={"sm"}
          color="falcon-default"
          className="mr-1"
          onClick={() => GET_FILE(agreement.attachment)}
        >
          Pobierz
        </Button>
      )}
      {statuses.map((el) => (
        <Button
          size={"sm"}
          color="falcon-default"
          className="mr-1"
          onClick={() => {
            if (user.clientPerson) {
              if (el.action_name === "accept_by_client") handleAcceptOffer()
              else if (el.action_name === "reject_by_client")
                handleRejectOffer()
              else handleAcceptReject(el.action_name)
            } else {
              if (el.action_name === "accept_by_client") setAcceptOpened(true)
              else if (el.action_name === "reject_by_client")
                setRejectOpened(true)
              else handleAcceptReject(el.action_name)
            }
          }}
        >
          {el.button_name}
        </Button>
      ))}
      <Button
        size={"sm"}
        color="falcon-default"
        className="mr-1"
        onClick={() => {
          history.push(`/offers/${reduceId(agreementId)}`)
        }}
      >
        Szczegóły
      </Button>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(Offer)

export const calcTotalSum = (
  additionalWarehouseProducts,
  ticketSettlementDetails
) => {
  let totalSum = 0.0

  if (additionalWarehouseProducts) {
    additionalWarehouseProducts.forEach((product) => {
      totalSum += product.quantity * parseFloat(product.price)
    })
  }

  if (ticketSettlementDetails) {
    ticketSettlementDetails.forEach((detail) => {
      if (detail.warehouseProducts) {
        detail.warehouseProducts.forEach((product) => {
          totalSum += product.quantity * parseFloat(product.price)
        })
      }
    })
  }

  return totalSum.toFixed(2)
}

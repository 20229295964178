import React from "react"

import { Card, CardBody, Col, Input, Row } from "reactstrap"
import FalconCardHeader from "components/common/FalconCardHeader"
import styled from "styled-components"
import TicketChat from "components/common/TicketChat"
import { GET_FILE } from "helpers/axios"
import TrashButton from "components/common/TrashButton"

import Loader from "components/common/Loader"
import { useAuthContext } from "context/AuthContext"
import ServiceData from "./ServiceData"

const ServiceDetails = ({
  ticket,
  attachments,
  links,
  handleRemoveActivity,
  isLoading,
  activityLogs,
  serviceId,
  refetch,
  setBtnAnimation,
}) => {
  const { user } = useAuthContext()
  return (
    <Row noGutters>
      <Col lg={7} xl={8} className="mb-3 pr-lg-2 mb-3 mt-3">
        <TicketChat
          ticketId={`/api/tickets/${serviceId}`}
          // loading={loading}
          data={activityLogs}
          refetch={refetch}
          deviceId={ticket?.deviceId}
        />
      </Col>

      <Col lg={5} xl={4} className="mb-3 pl-lg-2">
        <Card className="mb-3 mt-3">
          <FalconCardHeader title="Szczegóły zlecenia" />
          <CardBody className="fs--1 p-0">
            {isLoading ? (
              <Loader />
            ) : (
              <ServiceData
                ticket={ticket}
                serviceId={serviceId}
                setBtnAnimation={setBtnAnimation}
                refetch={refetch}
              />
            )}
          </CardBody>
        </Card>

        {ticket?.status === "service_visit_planned" && (
          <Card className="mb-3">
            <CardBody className="fs--1 py-4">
              <PlannedHeading>Serwis w trakcie planowania</PlannedHeading>
            </CardBody>
          </Card>
        )}

        <Card className="mb-3">
          <FalconCardHeader title="Lista załączników" />
          <CardBody className="fs--1 p-0">
            <AttachmentsLayout>
              {attachments.map((attachment, index) => (
                <div
                  key={index + attachment.file}
                  className="d-flex justify-content-between"
                >
                  <span
                    onClick={() => GET_FILE(attachment.file)}
                    className="text-link-blue-600-underline-hover"
                  >
                    {attachment.title || attachment.file}
                  </span>
                  {user.roles.includes("ROLE_TMB") && (
                    <TrashButton
                      // hidden={user.clientPerson}
                      onClick={() =>
                        handleRemoveActivity(attachment.activityId)
                      }
                    />
                  )}
                </div>
              ))}
            </AttachmentsLayout>
          </CardBody>
        </Card>

        <Card className="mb-3">
          <FalconCardHeader title="Lista linków" />
          <CardBody className="fs--1 p-0">
            <AttachmentsLayout>
              {links.map((link, index) => (
                <div
                  key={index + link.file}
                  className="d-flex justify-content-between"
                >
                  <a
                    key={index + link.linkAddress}
                    href={
                      link.linkAddress.includes("://")
                        ? link.linkAddress
                        : `https://${link.linkAddress}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-link-blue-600"
                  >
                    {link.title || link.linkAddress}
                  </a>
                  {user.roles.includes("ROLE_TMB") && (
                    <TrashButton
                      // hidden={user.clientPerson}
                      onClick={() => handleRemoveActivity(link.activityId)}
                    />
                  )}
                </div>
              ))}
            </AttachmentsLayout>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default ServiceDetails

const AttachmentsLayout = styled.div`
  padding: 20px 20px;
  display: flex;
  flex-flow: column;
`

const PlannedHeading = styled.p`
  text-align: center;
  margin-bottom: 0;
  font-size: 1.2rem;
  font-weight: 600;
  color: #00864e;
`

import React from "react"
import { Nav } from "reactstrap"
import ProfileDropdown from "./ProfileDropdown"
import ButtonIcon from "components/common/ButtonIcon"
import { GET_CUSTOM_ROUTE } from "helpers/axios"
import { toast } from "react-toastify"
import { useAuthContext } from "context/AuthContext"
import NotificationsManager from "components/navbar/NotificationsManager"

const TopNavRightSideNavItem = () => {
  const [isSubiektSyncing, setIsSubiektSyncing] = React.useState(false)
  const { user } = useAuthContext()

  const handleSync = async () => {
    setIsSubiektSyncing(true)

    GET_CUSTOM_ROUTE(`updateBase?token=${process.env.REACT_APP_SUBIEKT_TOKEN}`)
      .then((res) => toast.success("Synchronizacja zakończona pomyślnie"))
      .catch((err) => toast.error("Wystąpił błąd podczas synchronizacji"))
      .finally(() => setIsSubiektSyncing(false))
  }

  return (
    <Nav
      navbar
      className="navbar-nav-icons ml-auto flex-row align-items-center"
    >
      {(user.roles.includes("ROLE_TMB_ADMIN") ||
        user.roles.includes("ROLE_TMB_CUSTOMER_SERVICE")) && (
        <ButtonIcon
          icon="sync"
          color="falcon-default"
          size="sm"
          className="mr-2"
          onClick={handleSync}
          disabled={isSubiektSyncing}
        >
          Pobierz zmiany z subiekta
        </ButtonIcon>
      )}
      <NotificationsManager />
      <ProfileDropdown />
    </Nav>
  )
}

export default TopNavRightSideNavItem

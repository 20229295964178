import React, { Fragment } from "react"
import { Link } from "react-router-dom"
import moment from "moment"
import { GET_FILE } from "../../../../helpers/axios"
import Badge, { getStatusColor } from "../../../common/Badge"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { calcTotalSum } from "../../../../helpers/settlements/calcTotalSum"
import { calcTotalAgreementSum } from "../../../detailsCards/SettlementDetails/FeeComponent"

const name = {
  dataField: "name",
  text: "Rozliczenie",
  classes: "border-0 align-middle p-3",
  headerClasses: "border-0",
  formatter: (name, row) => {
    return (
      <Link
        to={`/settlements/${row.id}`}
        className="font-weight-semi-bold text-link-blue"
      >
        {name}
      </Link>
    )
  },
}

const settlementAgreementName = {
  dataField: "agreement.name",
  text: "Numer rozliczenia",
  classes: "border-0 align-middle",
  headerClasses: "border-0",
  formatter: (name, row) => {
    return (
      <Link
        to={`/agreements/settlements/${row?.id}`}
        className="font-weight-semi-bold text-link-blue"
      >
        {name}/{row?.id}
      </Link>
    )
  },
}

const agreementName = {
  dataField: "agreement.name",
  text: "Umowa",
  classes: "border-0 align-middle",
  headerClasses: "border-0",
  formatter: (name, row) => {
    return (
      <Link
        to={`/agreements/${row.agreement?.id}`}
        className="font-weight-semi-bold text-link-blue"
      >
        {name}
      </Link>
    )
  },
}

const agreementOrganization = {
  dataField: "agreement.organization.shortName",
  text: "Kontrahent",
  classes: "border-0 align-middle",
  headerClasses: "border-0",
  formatter: (name, row) => {
    return (
      <Link
        to={`/clients/${row.agreement?.organization?.id}/details`}
        className="font-weight-semi-bold text-link-blue"
      >
        {name}
      </Link>
    )
  },
}

const ticketName = {
  dataField: "ticket.name",
  text: "Zlecenie",
  classes: "border-1 align-middle",
  headerClasses: "border-0",
  formatter: (name, row) => {
    return (
      <span>
        <Link
          to={`/services/${row.ticket?.id}/details`}
          className="font-weight-semi-bold text-link-blue"
        >
          {name}
        </Link>
      </span>
    )
  },
}

const status = {
  dataField: "status",
  text: "Status",
  classes: "border-0 align-middle p-3",
  headerClasses: "border-0",
  formatter: (status, row) => {
    const { bgColor, color, icon } = getStatusColor(status)
    const Icon = icon ? icon : Fragment
    return (
      <Badge bgColor={bgColor} color={color}>
        <Icon color={color} />
        <span className="ml-1">{row.label}</span>
      </Badge>
    )
  },
}

const device = {
  dataField: "ticket.device.serialNumber",
  text: "Urządzenie",
  classes: "border-0 align-middle",
  headerClasses: "border-0",
  formatter: (serialNumber, row) => {
    return (
      <Link
        to={`/devices/${row.ticket?.device?.id}/details`}
        className="font-weight-semi-bold text-link-blue"
      >
        {serialNumber}, {row.ticket?.device?.warehausProduct?.model}
      </Link>
    )
  },
}

const organization = {
  dataField: "ticket.organization.name",
  text: "Kontrahent",
  classes: "border-0 align-middle",
  headerClasses: "border-0",
  formatter: (name, row) => {
    return (
      <Link
        to={`/clients/${row.ticket?.organization?.id}/details`}
        className="font-weight-semi-bold text-link-blue"
      >
        {name}
      </Link>
    )
  },
}

const date = {
  dataField: "createdAt",
  text: "Data rozliczenia",
  classes: "border-0 align-middle",
  headerClasses: "border-0",
  formatter: (date) => (date ? moment(date).format("DD.MM.YYYY") : "--"),
}

const attachment = {
  dataField: "attachmentNameSubiekt",
  text: "Faktura",
  classes: "border-0 align-middle",
  headerClasses: "border-0",
  formatter: (attachmentNameSubiekt, row) => {
    return (
      <div>
        <FontAwesomeIcon
          icon="download"
          transform="shrink-4 down-1"
          className="mr-1"
          style={{ cursor: "pointer" }}
        />
        <a
          className="font-weight-semi-bold text-link-blue"
          onClick={() => {
            if (row?.attachment) {
              GET_FILE(row.attachment)
            }
          }}
        >
          {attachmentNameSubiekt || row?.attachment || "-"}
        </a>
      </div>
    )
  },
}

const rwAttachment = {
  dataField: "attachmentNameSubiekt",
  text: "RW",
  classes: "border-0 align-middle",
  headerClasses: "border-0",
  formatter: (attachmentNameSubiekt, row) => {
    return (
      <div>
        <FontAwesomeIcon
          icon="download"
          transform="shrink-4 down-1"
          className="mr-1"
          style={{ cursor: "pointer" }}
        />
        <a
          className="font-weight-semi-bold text-link-blue"
          onClick={() => {
            if (row?.attachment) {
              GET_FILE(row.attachment)
            }
          }}
        >
          {attachmentNameSubiekt || row?.attachment || "-"}
        </a>
      </div>
    )
  },
}

const sum = {
  dataField: "sum",
  text: "Kwota",
  classes: "border-0 align-middle",
  headerClasses: "border-0",
  formatter: (_, row) => {
    const { additionalWarehouseProducts, ticketSettlementDetails } = row

    const totalSum = calcTotalSum(
      additionalWarehouseProducts,
      ticketSettlementDetails
    )
    return <span>{totalSum} zł</span>
  },
}

const agreementSum = {
  dataField: "sum",
  text: "Kwota",
  classes: "border-0 align-middle",
  headerClasses: "border-0",
  formatter: (_, row) => {
    const total = calcTotalAgreementSum(row)

    return <span>{total} zł</span>
  },
}

export const columnsConfig = [
  name,
  ticketName,
  device,
  organization,
  date,
  attachment,
]

export const columnsConfigSettlementsService = [
  name,
  status,
  ticketName,
  device,
  organization,
  date,
  sum,
  attachment,
]

export const columnsConfigSettlementsRW = [
  name,
  ticketName,
  device,
  organization,
  date,
  rwAttachment,
]

export const columnsConfigAgreementsSettlements = [
  settlementAgreementName,
  status,
  agreementName,
  agreementOrganization,
  date,
  agreementSum,
  attachment,
]

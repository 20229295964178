import React, { useState } from "react"
import { Card, CardBody } from "reactstrap"
import FalconCardHeader from "components/common/FalconCardHeader"
import loadable from "@loadable/component"
import ButtonIcon from "components/common/ButtonIcon"
import TmbUserModal from "components/actionModals/TmbUserModal"
import ClientUserModal from "components/actionModals/ClientUserModal"
import { useAuthContext } from "context/AuthContext"

const UserDetails = loadable(() =>
  import("components/detailsCards/UserDetails")
)
const EditTmbUserModal = loadable(() =>
  import("components/actionModals/EditTmbUserModal")
)

const Profile = () => {
  const [showEditTmbUserModal, setEditTmbUserModal] = useState(false)
  const [showEditClientModal, setEditClientModal] = useState(false)
  const { user } = useAuthContext()

  return (
    <>
      {showEditTmbUserModal && (
        <TmbUserModal
          isOpen={showEditTmbUserModal}
          handleCloseModal={() => setEditTmbUserModal(false)}
          type="edit"
          personId={user.tmbPerson.id}
          disableRolesEdit={!user.roles.includes("ROLE_TMB_ADMIN")}
        />
      )}
      {showEditClientModal && (
        <ClientUserModal
          isOpen={showEditClientModal}
          handleCloseModal={() => setEditClientModal(false)}
          type="edit"
          personId={user.clientPerson.id}
          disableOrganizationEdit
        />
      )}
      <Card className="mb-3">
        <FalconCardHeader light={false} className="pt-0">
          {user.roles.includes("ROLE_TMB") && (
            <ButtonIcon
              icon="edit"
              transform="shrink-3 down-2"
              color="falcon-default"
              size="sm"
              className="mx-2 mt-4"
              onClick={() =>
                user.roles.includes("ROLE_TMB")
                  ? setEditTmbUserModal(true)
                  : setEditClientModal(true)
              }
            >
              Edytuj
            </ButtonIcon>
          )}
        </FalconCardHeader>
        <CardBody className="p-0">
          <UserDetails
            id={
              user.roles.includes("ROLE_TMB")
                ? user.tmbPerson.id
                : user.clientPerson.id
            }
            isTmb={user.roles.includes("ROLE_TMB")}
            isClient={!user.roles.includes("ROLE_TMB")}
          />
        </CardBody>
      </Card>
    </>
  )
}

export default Profile

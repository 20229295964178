import React, { createContext, useContext, useEffect, useState } from "react"
import { JWT_LOGIN_URL } from "helpers/endpoints"
import axios from "axios"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"
import { GET } from "helpers/axios"
import { AUTH_TOKEN_NAME, USER_ID_NAME } from "./auth-context.const"

const initialState = {
  isLoading: false,
  user: null,
  isAuth: false,
}

const AuthContext = createContext(initialState)

const AuthContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isAuth, setIsAuth] = useState(true)
  const [user, setUser] = useState(null)
  const history = useHistory()

  useEffect(() => {
    const userId = localStorage.getItem(USER_ID_NAME)
    const token = localStorage.getItem(AUTH_TOKEN_NAME)

    if (userId && token) {
      setIsAuth(true)
    } else {
      setIsAuth(false)
    }

    if (userId && token) {
      setIsLoading(true)

      GET(`users/${userId}`)
        .then(({ data }) => {
          setUser({
            id: data.id,
            avatar: data.avatar,
            clientPerson: data.clientPerson,
            email: data.email,
            mobile: data.mobile,
            name: data.name,
            phone: data.phone,
            roles: data.roles,
            surname: data.surname,
            tmbPerson: data.tmbPerson,
          })
        })
        .catch(({ response }) => {
          localStorage.removeItem(AUTH_TOKEN_NAME)
          localStorage.removeItem(USER_ID_NAME)
          setIsAuth(false)
          const err = response
            ? response.data.message
            : "Wystąpił nieznany błąd"
          toast.error(err)
          return history.push("/auth")
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [isAuth])

  const signIn = (email, password) => {
    return axios
      .post(JWT_LOGIN_URL, { email, password })
      .then(({ data: { data, token } }) => {
        // if (!data.roles.includes("ROLE_TMB")) {
        //   return toast.error("Nie została dodana jeszcze funkcjonalność dla osoby klienta")
        // }

        localStorage.setItem(AUTH_TOKEN_NAME, token)
        localStorage.setItem(USER_ID_NAME, data.id)
        setIsAuth(true)

        return history.push("/")
      })
      .catch(({ response }) => {
        localStorage.removeItem(AUTH_TOKEN_NAME)
        localStorage.removeItem(USER_ID_NAME)
        setIsAuth(false)
        const err = response ? response.data.message : "Wystąpił nieznany błąd"
        toast.error(err)

        return new Error(err)
      })
  }

  const signOut = () => {
    localStorage.removeItem(AUTH_TOKEN_NAME)
    localStorage.removeItem(USER_ID_NAME)
    setUser(null)
    setIsAuth(false)
  }

  return (
    <AuthContext.Provider value={{ isLoading, user, isAuth, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider

export const useAuthContext = () => useContext(AuthContext)

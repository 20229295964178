import React, { useEffect } from "react"
import { connect } from "react-redux"
import { setReloadFalse, signIn } from "redux/actions"
import { client } from "helpers/apolloClient"
import Layout from "layouts/Layout"
import PropTypes from "prop-types"
import { Route, Switch } from "react-router-dom"
import PrivateRoute from "hoc/PrivateRoute"
import Login from "components/auth/card/Login"
import Dashboard from "views/Dashboard"
import ErrorLayout from "layouts/ErrorLayout"
import "react-toastify/dist/ReactToastify.min.css"
import "react-datetime/css/react-datetime.css"
import "react-image-lightbox/style.css"

const App = ({ setReloadFalse, reloadData }) => {
  useEffect(() => {
    if (reloadData) {
      client.resetStore()
      setReloadFalse()
    }
  }, [reloadData, setReloadFalse])

  return (
    <Layout>
      <Switch>
        <Route path="/errors">
          <ErrorLayout />
        </Route>
        <Route path="/auth" exact>
          <Login />
        </Route>
        <PrivateRoute path="/">
          <Dashboard />
        </PrivateRoute>
      </Switch>
    </Layout>
  )
}

App.propTypes = {
  signIn: PropTypes.func.isRequired,
  setReloadFalse: PropTypes.func.isRequired,
  reloadData: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => {
  return {
    reloadData: state.reloadData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (user) => dispatch(signIn(user)),
    setReloadFalse: () => dispatch(setReloadFalse()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)

import useGetStatusList from "./useGetStatusList"
import usePostServicePaidButton from "./usePostServicePaidButton"
import usePostChangeServiceSettlementStatus from "./usePostChangeServiceSettlementStatus"
import { useEffect, useState } from "react"

const useServiceChangeStatus = (id) => {
  const { statusList } = useGetStatusList()

  const {
    postServicePaid,
    data: buttonData,
    loading: isButtonDataLoading,
  } = usePostServicePaidButton()

  const {
    data: changeStatusData,
    error,
    loading,
    postChangeStatus,
  } = usePostChangeServiceSettlementStatus()

  const [data, setData] = useState(null)

  useEffect(() => {
    if (id) postServicePaid(id)
  }, [id])

  useEffect(() => {
    if (!isButtonDataLoading)
      setData((prev) => ({
        ...prev,
        ...buttonData,
        ...changeStatusData,
        statusLabel: statusList.find((item) => {
          return item.value === changeStatusData?.status
        })?.label,
      }))
  }, [isButtonDataLoading, loading])

  const changeStatus = async () => {
    await postChangeStatus(id, data.action_name)
    await postServicePaid(id)
  }

  return { changeStatus, data }
}

export default useServiceChangeStatus

import React, { useEffect } from "react"
import { connect } from "react-redux"
import { setGlobalLoading } from "redux/actions"
import { usePaginationFetch } from "hooks/usePaginationFetch"
import { useDebounce } from "hooks/useDebounce"
import { columns, statusesOptions, typesOptions } from "./VisitsTable.config"
import Table from "components/Table"
import Filters from "components/common/Filters/Filters"
import { useAuthContext } from "context/AuthContext"

const VisitsTable = ({
  setGlobalLoading,
  searchValue,
  showFilters,
  deviceId,
  serviceId,
  columnsHiddenConditions,
}) => {
  const [sortSettings, setSortSettings] = React.useState(
    "warehausProduct.model:asc"
  )
  const [sortingField, order] = sortSettings.split(":")
  const debouncedSort = useDebounce(sortSettings, 1000)
  const debouncedSearchValue = useDebounce(searchValue, 1000)
  const [selectedStatus, setSelectedStatus] = React.useState("")
  const [selectedType, setSelectedType] = React.useState("")
  const { user } = useAuthContext()

  const {
    data,
    isLoading,
    totalPages,
    currentPage,
    incrementPage,
    decrementPage,
    setPage,
  } = usePaginationFetch("service_visits", {
    params: {
      status: selectedStatus,
      type: selectedType,
      search: searchValue,
      device: deviceId,
      ticket: serviceId,
    },
    refetchDeps: [selectedStatus, selectedType, debouncedSearchValue],
    enable404Redirect: true,
  })

  useEffect(() => {
    setGlobalLoading("visitsTable", !!isLoading)
    return () => setGlobalLoading("visitsTable", false)
  }, [isLoading, setGlobalLoading])

  return (
    <>
      <Filters
        currentPage={currentPage}
        totalPages={totalPages}
        showFilters={showFilters}
      >
        <Filters.Select
          label="Status:"
          options={statusesOptions}
          onChange={(e) => setSelectedStatus(e.target.value)}
          currentValue={selectedStatus}
          isDisabled={isLoading}
        />
        <Filters.Select
          label="Typ:"
          options={typesOptions}
          onChange={(e) => setSelectedType(e.target.value)}
          currentValue={selectedType}
          isDisabled={isLoading}
        />
      </Filters>
      <Table
        columns={columns(
          columnsHiddenConditions,
          !user.roles.includes("ROLE_TMB")
        )}
        data={data}
        currentPage={currentPage}
        totalPages={totalPages}
        incrementPage={incrementPage}
        decrementPage={decrementPage}
        setPage={setPage}
        isLoading={isLoading}
        sortingField={sortingField}
        order={order}
        setSortSettings={setSortSettings}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setGlobalLoading: (component, value) =>
      dispatch(setGlobalLoading({ [component]: value })),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitsTable)

import React from "react"
import styled from "styled-components"
import { Nav, NavItem, NavLink } from "reactstrap"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"

export const Navbar = styled.div`
  background-color: #f4f7ff;
  border-top: 1px solid #d8e2ee;
  border-bottom: 1px solid #d8e2ee;

  .active {
    border-bottom: 2px solid #2f80ed;
  }

  a {
    text-decoration: none;
  }
`

const OffersNavigation = ({ location }) => {
  return (
    <Navbar className="text-link-blue">
      <Nav>
        <NavItem>
          <NavLink
            tag={Link}
            to={`service`}
            className={
              location === `/offers/service`
                ? "active text-link-blue"
                : "text-link-blue"
            }
          >
            Serwisowe
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            tag={Link}
            to={`commercial`}
            className={
              location === `/offers/commercial` ? 'active text-link-blue' : 'text-link-blue'
            }
          >
            Handlowe
          </NavLink>
        </NavItem> */}
      </Nav>
    </Navbar>
  )
}

OffersNavigation.propTypes = {
  location: PropTypes.string.isRequired,
}

export default OffersNavigation

import { monthOptions } from "./selectOptions.config"

export const filters = [
  {
    label: "",
    name: "month",
    type: "dateRange",
    options: monthOptions,
  },
  {
    label: "Status:",
    name: "status",
    type: "select",
    options: [],
  },
  {
    label: "Kontrahent:",
    name: "organization",
    type: "select",
    options: [],
  },
]

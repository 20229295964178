import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Button } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ButtonIcon = ({
  icon,
  iconAlign,
  iconClassName,
  animation = false,
  animationInfinite = false,
  transform,
  children,
  disabled = false,
  iconComponent = null,
  ...rest
}) => {
  const classes = `d-flex align-items-center mr-2 ml-2 my-1 ${
    animation ? "btn-animation" : ""
  }`
  return (
    <Button
      {...rest}
      disabled={disabled}
      className={classes}
      style={{
        animationIterationCount: animationInfinite ? "infinite" : "initial",
        display: "flex",
        direction: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: "0.5rem",
      }}
    >
      {iconAlign === "right" && children}
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          className={classNames(iconClassName, {
            "mr-1": children && iconAlign === "left",
            "ml-1": children && iconAlign === "right",
          })}
          transform={transform}
        />
      )}
      {iconAlign === "left" && children}
    </Button>
  )
}

ButtonIcon.propTypes = {
  ...Button.propTypes,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  children: PropTypes.any,
  iconAlign: PropTypes.oneOf(["left", "right"]),
  iconClassName: PropTypes.string,
  animation: PropTypes.bool,
  transform: PropTypes.string,
}

ButtonIcon.defaultProps = { iconAlign: "left" }

export default ButtonIcon

import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { toast, ToastContainer } from "react-toastify"
import { CloseButton, Fade } from "components/common/Toast"
import styled from "styled-components"
import Notifications from "components/common/Notifications"
import GlobalLoader from "components/common/GlobalLoader"

const StyledLayout = styled.div`
  position: relative;
`

const Layout = ({ user: userOld, globalLoading, children }) => {
  const [isGlobalLoading, setGlobalLoading] = useState(false)

  useEffect(() => {
    if (Object.values(globalLoading).includes(true)) {
      setGlobalLoading(true)
    } else {
      setGlobalLoading(false)
    }
  }, [globalLoading])

  return (
    <>
      {children}
      <StyledLayout>
        <Notifications />
        <ToastContainer
          transition={Fade}
          closeButton={<CloseButton />}
          position={toast.POSITION.TOP_CENTER}
        />
        <GlobalLoader loading={isGlobalLoading} />
      </StyledLayout>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    globalLoading: state.globalLoading,
  }
}

export default connect(mapStateToProps)(Layout)

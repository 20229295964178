import React from "react"
import { Link } from "react-router-dom"
import moment from "moment"

export const showAgreementType = (type) => {
  switch (type) {
    case 1:
      return "Umowa wynajmu"
    case 2:
      return "Umowa obsługi"
    case 3:
      return "Umowa obsługi serwisowej"
    case 4:
      return "Umowa sprzedaży urządzenia"
  }
}

export const showAgreementStatus = (status) => {
  switch (status) {
    case "active":
      return "Aktywna"
    case "inactive":
      return "Nieaktywna"
    case "project":
      return "Projekt"
    default:
      return "--"
  }
}

const mapSettlementStatus = (status) => {
  switch (status) {
    case 0:
      return "Nierozliczona"
    case 1:
      return "Rozliczona"
    case 2:
      return "Zaległa"
    default:
      return "--"
  }
}

export const statusesOptions = [
  { value: "active", label: "Aktywna" },
  { value: "inactive", label: "Nieaktywna" },
  { value: "project", label: "Projekt" },
]

export const settleStatusesOptions = [
  { value: null, label: "Wszystkie" },
  { value: "0", label: "Do rozliczenia" },
  { value: "1", label: "Do rozliczenia w ciągu dni:" },
  { value: "2", label: "Zaległe" },
]

export const columns = (hideOrganization) => [
  {
    dataField: "name",
    text: "Numer umowy",
    classes: "border-0 align-middle",
    headerClasses: "border-0",
    formatter: (name, row) => (
      <Link
        to={`/agreements/${row.id}`}
        className="font-weight-semi-bold text-link-blue"
      >
        {name}
      </Link>
    ),
  },
  {
    dataField: "organization.name",
    text: "Kontrahent",
    classes: "border-0 align-middle",
    headerClasses: "border-0",
    formatter: (name, row) => {
      return (
        <Link
          to={`/clients/${row.organization?.id}/details`}
          className="font-weight-semi-bold text-link-blue"
        >
          {name}
        </Link>
      )
    },
    hidden: hideOrganization,
  },
  {
    dataField: "department.name",
    text: "Lokalizacja",
    classes: "border-0 align-middle",
    headerClasses: "border-0",
    formatter: (name, row) => {
      return (
        <Link
          to={`/clients/${row.organization?.id}/department/${row.department?.id}/details`}
          className="font-weight-semi-bold text-link-blue"
        >
          {name}
        </Link>
      )
    },
  },
  {
    dataField: "salesman.user.name",
    text: "Opiekun",
    classes: "border-0 align-middle",
    headerClasses: "border-0",
    formatter: (salesman, row) => (
      <span>
        {row.salesman?.user?.name || ""} {row.salesman?.user?.surname || ""}
      </span>
    ),
  },
  {
    dataField: "organization.recName",
    text: "Odbiorca usługi",
    classes: "border-0 align-middle",
    headerClasses: "border-0",
  },
  {
    dataField: "device.warehausProduct.model",
    text: "Model urządzenia",
    classes: "border-0 align-middle",
    headerClasses: "border-0",
  },
  {
    dataField: "device.serialNumber",
    text: "Numer seryjny",
    classes: "border-0 align-middle",
    headerClasses: "border-0",
    formatter: (serialNumber, row) => {
      return (
        <Link
          to={`/devices/${row.device?.id}/details`}
          className="font-weight-semi-bold text-link-blue"
        >
          {serialNumber ?? "--"}
        </Link>
      )
    },
  },
  {
    dataField: "type",
    text: "Rodzaj umowy",
    classes: "border-0 align-middle",
    headerClasses: "border-0",
    formatter: (type) => showAgreementType(type),
  },
  {
    dataField: "AgreementStartDate",
    text: "Start umowy",
    classes: "border-0 align-middle",
    headerClasses: "border-0",
    formatter: (AgreementStartDate) =>
      AgreementStartDate
        ? moment(AgreementStartDate).format("DD.MM.YYYY")
        : "--",
  },
  {
    dataField: "AgreementExpirationDate",
    text: "Koniec umowy",
    classes: "border-0 align-middle",
    headerClasses: "border-0",
    formatter: (AgreementExpirationDate) =>
      AgreementExpirationDate
        ? moment(AgreementExpirationDate).format("DD.MM.YYYY")
        : "--",
  },
  {
    dataField: "status",
    text: "Status umowy",
    classes: "border-0 align-middle",
    headerClasses: "border-0",
    formatter: showAgreementStatus,
  },
  {
    dataField: "settlementStatus",
    text: "Status rozliczenia",
    classes: "border-0 align-middle",
    headerClasses: "border-0",
    formatter: mapSettlementStatus,
  },
  {
    dataField: "settlementDate",
    text: "Data rozliczenia",
    classes: "border-0 align-middle",
    headerClasses: "border-0",
    formatter: (settlementDate) =>
      settlementDate ? moment(settlementDate).format("DD.MM.YYYY") : "--",
  },
]

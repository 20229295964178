import React from "react"
import {
  Pagination as BootstrapPagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap"
import styled from "styled-components"

const getSurroundingPages = (currentPage, totalPages) => {
  const surroundingPages = []
  for (let i = currentPage - 2; i <= currentPage + 2; i++) {
    if (i >= 1 && i <= totalPages) {
      surroundingPages.push(i)
    }
  }
  return surroundingPages
}

const Pagination = ({
  currentPage,
  totalPages,
  incrementPage,
  decrementPage,
  setPage,
}) => {
  if (totalPages === 1) return null

  return (
    <PaginationWrapper>
      <BootstrapPagination size="md">
        {currentPage > 1 && (
          <>
            <PaginationItem>
              <PaginationLink tag="button" onClick={() => setPage(1)}>
                {"<<"}
              </PaginationLink>
            </PaginationItem>
            <PaginationItem>
              <PaginationLink tag="button" onClick={decrementPage}>
                {"<"}
              </PaginationLink>
            </PaginationItem>
          </>
        )}
        {getSurroundingPages(currentPage, totalPages).map((page) => (
          <PaginationItem active={currentPage === page} key={page}>
            <PaginationLink tag="button" onClick={() => setPage(page)}>
              {page}
            </PaginationLink>
          </PaginationItem>
        ))}
        {currentPage < totalPages && (
          <>
            <PaginationItem>
              <PaginationLink tag="button" onClick={incrementPage}>
                {">"}
              </PaginationLink>
            </PaginationItem>
            <PaginationItem>
              <PaginationLink tag="button" onClick={() => setPage(totalPages)}>
                {">>"}
              </PaginationLink>
            </PaginationItem>
          </>
        )}
      </BootstrapPagination>
    </PaginationWrapper>
  )
}

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`

export default Pagination

import React from "react"
import { GET, POST, PATCH, POST_SINGLE_IMAGE } from "helpers/axios"
import {
  Modal,
  ModalHeader,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
} from "reactstrap"
import { toast } from "react-toastify"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { registerLocale } from "react-datepicker"
import pl from "date-fns/locale/pl"
import { connect } from "react-redux"
import { setReloadTrue } from "redux/actions"
import { setGlobalLoading } from "redux/actions"
import "react-datepicker/dist/react-datepicker.css"
import Checkbox from "components/common/Form/Checkbox"
import CheckboxLabel from "components/common/Form/Label"
import InlineGroup from "components/common/Form/InlineGroup"
import { useHistory } from "react-router-dom"
import Avatar from "components/common/Avatar"
import { publicUploads } from "helpers/endpoints"
import defaultProfile from "assets/img/default_user.png"
import ChangeImageButton from "components/common/ChangeImageButton"
import PlannerColorPicker from "components/common/PlannerColorPicker"
import { plannerColorClasses } from "config"
import { validateImageFormat } from "helpers/utils"

registerLocale("pl", pl)

const emptyData = {
  name: "",
  surname: "",
  phone: "",
  mobile: "",
  email: "",
  password: "",
  repPassword: "",
  colour: "",
  avatar: "",
  ROLE_TMB_SALESMAN: false,
  ROLE_TMB_SERVICEMAN: false,
  ROLE_TMB_CUSTOMER_SERVICE: false,
  ROLE_TMB_SERVICE_MANAGER: false,
  ROLE_TMB_ADMIN: false,
  isActive: true,
}

export const roles = [
  {
    role: "ROLE_TMB_SALESMAN",
    label: "Handlowiec",
  },
  {
    role: "ROLE_TMB_SERVICEMAN",
    label: "Serwisant",
  },
  {
    role: "ROLE_TMB_CUSTOMER_SERVICE",
    label: "BOK",
  },
  {
    role: "ROLE_TMB_SERVICE_MANAGER",
    label: "Kierownik serwisu",
  },
  {
    role: "ROLE_TMB_ADMIN",
    label: "Administrator",
  },
]

const TmbUserModal = ({
  isOpen,
  handleCloseModal,
  type = "create",
  personId,
  setGlobalLoading,
  setReloadTrue,
  disableRolesEdit = false,
}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [form, setForm] = React.useState(emptyData)
  const [showErrors, setShowErrors] = React.useState(false)

  const [sendingFile, setSendingFile] = React.useState(false)
  const [receivedFilename, setReceivedFilename] = React.useState("")

  const history = useHistory()

  const hiddenFileInput = React.useRef(null)
  const addFile = () => {
    hiddenFileInput.current.click()
  }

  React.useEffect(() => {
    if (type !== "edit") return

    setIsLoading(true)

    GET(`tmb_people/${personId}`)
      .then((res) => {
        const { data } = res

        const formData = {
          name: data.user?.name || "",
          surname: data.user?.surname || "",
          phone: data.user?.phone || "",
          mobile: data.user?.mobile || "",
          email: data.user?.email || "",
          colour: data.colour || "",
          avatar: data.user?.avatar || "",
          ROLE_TMB_SALESMAN: data.user?.roles.includes("ROLE_TMB_SALESMAN"),
          ROLE_TMB_SERVICEMAN: data.user?.roles.includes("ROLE_TMB_SERVICEMAN"),
          ROLE_TMB_CUSTOMER_SERVICE: data.user?.roles.includes(
            "ROLE_TMB_CUSTOMER_SERVICE"
          ),
          ROLE_TMB_SERVICE_MANAGER: data.user?.roles.includes(
            "ROLE_TMB_SERVICE_MANAGER"
          ),
          ROLE_TMB_ADMIN: data.user?.roles.includes("ROLE_TMB_ADMIN"),
          password: "",
          repPassword: "",
          isActive: data.user?.isActive,
        }

        setForm(formData)
        setReceivedFilename(data.user?.avatar)
      })
      .catch((err) => {
        toast.error("Wystąpił błąd pobierania danych.")
      })
      .finally(() => setIsLoading(false))
  }, [type])

  const cleanForm = () => setForm(emptyData)

  const handleValidate = () => {
    return form.name && form.surname && form.email && form.phone
  }

  const checkPasswords = () => {
    return form.password && form.password === form.repPassword
  }

  const checkRoles = () => {
    let checkedRoles = 0

    roles.forEach(({ role }) => {
      if (form[role]) checkedRoles++
    })

    return checkedRoles > 0
  }

  const handleAddTmbPerson = () => {
    if (!checkPasswords()) {
      setShowErrors(true)
      toast.error("Hasła nie są takie same")
      return setIsLoading(false)
    }

    const user = {
      colour: form.colour,
      email: form.email || null,
      roles: ["ROLE_TMB", "ROLE_CLIENT"],
      password: form.password,
      clientPerson: null,
      name: form.name,
      surname: form.surname,
      avatar: receivedFilename || null,
      phone: form.phone,
      mobile: form.mobile,
    }

    roles.forEach(({ role }) => {
      if (form[role]) user.roles.push(role)
    })

    POST("tmb_people/custom", user)
      .then((res) => {
        user.tmbPerson = res.data["@id"]
        cleanForm()
        handleCloseModal()
        history.go(0)
        toast.success("Dodano nowego użytkownika")
      })
      .catch(() => {
        setIsLoading(false)
        toast.error("Nie udało się dodać użytkownika")
      })
      .finally(() => setIsLoading(false))
  }

  const handleUpdateTmbPerson = () => {
    const user = {
      colour: form.colour,
      email: form.email || null,
      roles: ["ROLE_TMB", "ROLE_CLIENT"],
      password: form.password ? form.password : undefined,
      name: form.name,
      surname: form.surname,
      avatar: receivedFilename || null,
      phone: form.phone,
      mobile: form.mobile,
      isActive: form.isActive,
    }

    if (form.password !== form.repPassword) {
      setShowErrors(true)
      toast.error("Hasła nie są takie same")
      return setIsLoading(false)
    }

    roles.forEach(({ role }) => {
      if (form[role]) user.roles.push(role)
    })

    PATCH(`/api/tmb_people/${personId}/custom`, user)
      .then((res) => {
        cleanForm()
        handleCloseModal()
        history.go(0)
        toast.success("Użytkownik został zaktualizowany")
      })
      .catch((res) => {
        setIsLoading(false)
        toast.error("Nie udało się zapisać zmian")
      })
      .finally(() => setIsLoading(false))
  }

  const handleSelectFile = (event) => {
    if (!event.target.files[0]) return

    if (event.target.files[0].size > 5242880) {
      event.target.value = null
      return toast.error("Rozmiar pliku jest za duży")
    }

    if (!validateImageFormat(event.target.files[0])) {
      event.target.value = null
      return toast.error("Nieobsługiwany format pliku")
    }

    setSendingFile(true)
    setGlobalLoading("TmbUserModal", true)
    POST_SINGLE_IMAGE(event.target.files[0], "true")
      .then((res) => {
        setReceivedFilename(res.data[0])
        setForm({ ...form, avatar: res.data[0] })
      })
      .catch((err) => {
        toast.error("Błąd przesyłania pliku")
      })
      .finally(() => {
        setSendingFile(false)
        setGlobalLoading("TmbUserModal", false)
      })
  }

  const phoneRegex = new RegExp(
    /^(\+(\d){2})?(?:\(?\?)?(?:[-\(\)\s]*(\d)){9}\)?$/
  )

  const handleSubmit = (e) => {
    e.preventDefault()
    if (sendingFile) {
      return toast.error("Poczekaj na przesłanie pliku")
    }

    if (!handleValidate()) {
      setShowErrors(true)
      return toast.error("Sprawdź poprawność danych")
    }
    if (!checkRoles()) {
      setShowErrors(true)
      return toast.error("Wybierz przynajmniej jedną rolę")
    }

    if (form.phone && phoneRegex.test(form.phone) === false) {
      setShowErrors(true)
      return toast.error("Numer telefonu jest niepoprawny")
    }

    if (form.mobile && phoneRegex.test(form.mobile) === false) {
      setShowErrors(true)
      return toast.error("Numer telefonu jest niepoprawny")
    }

    setShowErrors(false)
    setIsLoading(true)

    return type === "edit" ? handleUpdateTmbPerson() : handleAddTmbPerson()
  }

  return (
    <Modal
      isOpen={isOpen}
      modalClassName="modal-fixed-right modal-theme"
      className="modal-dialog-vertical"
      contentClassName="min-vh-100 w-500 border-0"
      toggle={handleCloseModal}
    >
      <div className="position-absolute t-0 r-0 z-index-1">
        <Button
          size="sm"
          className="close close-circle d-flex flex-center transition-base mt-3 mr-3"
          onClick={handleCloseModal}
        >
          <FontAwesomeIcon
            icon="times"
            transform="shrink-6 right-0.3 down-0.3"
          />
        </Button>
      </div>
      <ModalHeader tag="div" className="modal-header-settings">
        <div className="py-1 flex-grow-1">
          <h5>
            {type === "edit"
              ? "Edycja pracownika TMB"
              : "Dodawanie pracownika TMB"}
          </h5>
        </div>
      </ModalHeader>
      <Form className="p-4 overflow-auto">
        <div className="d-flex flex-column align-items-center mb-2">
          <Avatar
            size="4xl"
            src={
              form.avatar ? `${publicUploads}/${form.avatar}` : defaultProfile
            }
          />
          <ChangeImageButton text="Zmień zdjęcie" event={() => addFile()} />
        </div>
        <input
          style={{ display: "none" }}
          type="file"
          name="file"
          id="file"
          ref={hiddenFileInput}
          onChange={(e) => handleSelectFile(e)}
        />
        <FormGroup>
          <Label for="name">Imię</Label>
          <Input
            type="text"
            name="name"
            id="name"
            invalid={showErrors && !form.name}
            disabled={isLoading}
            value={form.name}
            onChange={({ target: { value } }) =>
              setForm((prev) => ({ ...prev, name: value }))
            }
          />
        </FormGroup>
        <FormGroup>
          <Label for="surname">Nazwisko</Label>
          <Input
            type="text"
            name="surname"
            id="surname"
            value={form.surname}
            invalid={showErrors && !form.surname}
            disabled={isLoading}
            onChange={({ target: { value } }) =>
              setForm((prev) => ({ ...prev, surname: value }))
            }
          />
        </FormGroup>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="phone">Telefon</Label>
              <Input
                type="text"
                name="phone"
                id="phone"
                invalid={
                  (showErrors && !form.phone) ||
                  (showErrors &&
                    form.phone &&
                    phoneRegex.test(form.phone) === false)
                }
                disabled={isLoading}
                value={form.phone}
                onChange={({ target: { value } }) =>
                  setForm((prev) => ({ ...prev, phone: value }))
                }
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="mobile">Telefon komórkowy</Label>
              <Input
                type="text"
                name="mobile"
                id="mobile"
                invalid={
                  showErrors &&
                  form.mobile &&
                  phoneRegex.test(form.mobile) === false
                }
                disabled={isLoading}
                value={form.mobile}
                onChange={({ target: { value } }) =>
                  setForm((prev) => ({ ...prev, mobile: value }))
                }
              />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup style={{ position: "relative" }}>
          <Label for="email">Email</Label>
          <Input
            type="email"
            name="email"
            id="email"
            disabled={isLoading}
            value={form.email}
            invalid={showErrors && !form.email}
            onChange={({ target: { value } }) =>
              setForm((prev) => ({ ...prev, email: value }))
            }
          />
        </FormGroup>
        <FormGroup style={{ position: "relative" }}>
          <Label for="password">Hasło</Label>
          <Input
            type="password"
            name="password"
            id="password"
            disabled={isLoading}
            invalid={showErrors && type === "create" && !form.password}
            value={form.password}
            onChange={({ target: { value } }) =>
              setForm((prev) => ({ ...prev, password: value }))
            }
          />
        </FormGroup>
        <FormGroup>
          <Label for="repeat-password">Powtórz hasło</Label>
          <Input
            type="password"
            name="repeat-password"
            id="repeat-password"
            disabled={isLoading}
            invalid={showErrors && type === "create" && !form.repPassword}
            value={form.repPassword}
            onChange={({ target: { value } }) =>
              setForm((prev) => ({ ...prev, repPassword: value }))
            }
          />
        </FormGroup>
        {type === "edit" && (
          <InlineGroup>
            <CheckboxLabel for="isActive">Aktywny użytkownik</CheckboxLabel>
            <Checkbox
              id="isActive"
              disabled={isLoading}
              checked={form.isActive}
              onChange={() =>
                setForm((prev) => ({ ...prev, isActive: !prev.isActive }))
              }
            />
          </InlineGroup>
        )}
        <FormGroup>
          <Label for="colour">Kolor</Label>
          <PlannerColorPicker
            values={plannerColorClasses}
            onSelect={(value) => setForm({ ...form, colour: value })}
            selectedValue={form.colour}
          />
        </FormGroup>
        {disableRolesEdit
          ? null
          : roles.map(({ role, label }) => (
              <InlineGroup key={role}>
                <CheckboxLabel for={role}>{label}</CheckboxLabel>
                <Checkbox
                  id={role}
                  disabled={isLoading}
                  checked={form[role]}
                  onChange={() =>
                    setForm((prev) => ({ ...prev, [role]: !prev[role] }))
                  }
                />
              </InlineGroup>
            ))}
        <Button
          className="mt-3 mb-5"
          color="success"
          onClick={handleSubmit}
          disabled={isLoading || sendingFile}
        >
          Zapisz
        </Button>
      </Form>
    </Modal>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setReloadTrue: () => dispatch(setReloadTrue()),
    setGlobalLoading: (component, value) =>
      dispatch(setGlobalLoading({ [component]: value })),
  }
}

export default connect(null, mapDispatchToProps)(TmbUserModal)
